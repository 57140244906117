import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { LoadingButton, MobileDatePicker, MobileDateTimePicker } from '@material-ui/lab';
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Box,
  Button,
  DialogActions,
  Checkbox,
  MenuItem
} from '@material-ui/core';

import { UploadAvatar, UploadMultiFile, UploadSingleFile } from 'src/components/upload';
import useAuth from 'src/hooks/useAuth';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import axios from 'axios';
import moment from 'moment';

//


// ----------------------------------------------------------------------


const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------
const VariationType=[
    {value:0,label:"Milligram"},
    {value:1,label:"Gram"},
    {value:2,label:"OZ"},
    {value:3,label:"Unit"},
]
const getData = (source, ids) => {
    console.log("source",source);
    console.log("ids",ids);
    if (!ids) return [];
    return ids.split(',').map(id => source.find(item => item.id == id)).filter(item => item !== undefined);
  };

export default function OrderInvoiceEdit({
    setOpenInvoiceEdit,
    order_id,
    invoiceOrderData,
    
}) {
    const {enqueueSnackbar} =useSnackbar()
  const location = useLocation()
  const { user } = useAuth(); 


 
  const NewProductSchema = Yup.object().shape({
//   variation_name: Yup.string().required('Name is required'),
//   quantity: Yup.number().required('Quantity is required').typeError('Quantity must be a number'),
//   variation_type: Yup.object().required('Select  Type'),
//   sub_category_id: Yup.object().required('Select sub category'),
//   variation_price: Yup.number().required('Price is required').typeError('Price must be a number'),
//   variation_value: Yup.number().required('Price is required').typeError('Price must be a number'),
  });
  let invoice_date = moment(invoiceOrderData?.timestamp*1000);
  let due_date = invoiceOrderData?.due_date!=null ? moment(invoiceOrderData?.due_date*1000):moment(invoiceOrderData?.timestamp*1000).add(14,'days');
 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        invoice_date:invoice_date||'',
        due_date:due_date||'',     
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
          const unixValues = {
            ...values,
            invoice_date: moment(values.invoice_date, 'YYYY-MM-DD').unix(),
            due_date: moment(values.due_date, 'YYYY-MM-DD').unix(),
          };
  
          const res = await axios.post(`${REST_API_END_POINT}point-of-sale/edit-invoice-post`, {
            order_id,
            facility_id: user.facility_id,
            user_id: user.id,
            values: unixValues,
          });
  
          if (res.data.status === 1) {
            enqueueSnackbar(res.data.message, { variant: 'success' });
            setOpenInvoiceEdit(false);
          } else {
            enqueueSnackbar(res.data.message, { variant: 'error' });
            setOpenInvoiceEdit(false);
          }
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          setErrors(error);
        }
      },
    });
  
    const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
  
    console.log(values, '------------%%%');
  
    return (
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Stack direction={{ xs: 'column', sm: 'row' }} sx={{mr:1}}>
            <MobileDatePicker
              orientation="portrait"
              label="Choose Invoice Date"
              value={values.invoice_date}
              onChange={(newValue) => {
                setFieldValue('invoice_date', moment(newValue).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{mr:1}} {...params} margin="normal" />
              )}
            />
            <MobileDatePicker
              orientation="portrait"
              label="Choose Due Date"
              value={values.due_date}
              onChange={(newValue) => {
                setFieldValue('due_date', moment(newValue).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{ml:1}} {...params} margin="normal" />
              )}
            />
          </Stack>
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingIndicator="Loading...">
              Save Changes
            </LoadingButton>
            <Button type="button" variant="outlined" color="inherit" onClick={() => { setOpenInvoiceEdit(false) }}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
    </FormikProvider>
  );
}