import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// Material UI components
import { LoadingButton } from '@material-ui/lab'
import {
  Stack,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core'
// Utility function
import fakeRequest from 'src/utils/fakeRequest'
import Label from 'src/components/Label'
import Scrollbar from 'src/components/Scrollbar'
import Scroll from 'src/pages/components-overview/extra/animate/scroll'

// Coupon data array
const couponData = [
  { code: 'FLASH25', label: '25% Off', description: '25% off your purchase' },
  {
    code: 'FREESHIP',
    label: 'Free Shipping',
    description: 'Enjoy free shipping',
  },
  {
    code: 'BUY2GET1',
    label: 'Buy 2, Get 1 Free',
    description: 'Buy two items and get one free',
  },
]

export default function GiftCouponCardModal({
  setOpenCouponCode,
  pricePoints,
  setPaymentData,
  calculatecartTotal,
  setAddRedeem,
}) {
  const [filteredCoupons, setFilteredCoupons] = useState(pricePoints || [])
  const { enqueueSnackbar } = useSnackbar()
  const NewContactSchema = Yup.object().shape({ })

  const formik = useFormik({
    initialValues: {
      couponCode: '',
    },
    validationSchema: NewContactSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await fakeRequest(500)
        setPaymentData((prev) => ({
          ...prev,
          points: values?.couponCode.price_points,
          point_discount: values?.couponCode.discount,
        }))
        calculatecartTotal(values?.couponCode)
        setAddRedeem(true)
        setOpenCouponCode(false)
        setSubmitting(false)
        enqueueSnackbar('Coupon applied successfully', { variant: 'success' })
      } catch (error) {
        console.error(error)
        setSubmitting(false)
      }
    },
  })

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik

  const handleApplyCoupon = (code) => {
    setFieldValue('couponCode', code)
    setPaymentData((prev) => ({
      ...prev,
      points: code.price_points,
      point_discount: code.discount,
    }))
    calculatecartTotal(code)
    setAddRedeem(true)
    setOpenCouponCode(false)
  }
  const handleCouponChange = (event) => {
    const inputPoints = event.target.value
    if(inputPoints !=''){
      const filtered = pricePoints?.filter(
        (coupon) => coupon.price_points <= parseInt(inputPoints, 10)
      )
      setFilteredCoupons(filtered)
    }else{
      setFilteredCoupons(pricePoints)
    }
   
  }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Search Gifts"
          onChange={handleCouponChange}
          error={Boolean(touched.couponCode && errors.couponCode)}
          helperText={touched.couponCode && errors.couponCode}
        />
        {/* <Stack direction={'column'} spacing={0.5} p={0.5} mb={2}>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Instructions:
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            • Copy your coupon code from the email, promotional material or
            choose from a list below.
            <br />• Paste it here and click 'Apply' to redeem your discount.
          </Typography>
        </Stack> */}
        <Stack spacing={1}>
          <Scrollbar sx={{ height: '310px', overflow: 'auto' }}>
            <Card sx={{ boxShadow: 'none', borderRadius: '0px' }}>
              {filteredCoupons?.map((coupon, index) => (
                <div key={coupon.code}>
                  <CardContent
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Label variant="body2" sx={{ mb: 1 }}>
                        {coupon?.discount} Discount
                      </Label>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {coupon?.price_points} Price points
                      </Typography>
                    </div>
                    <Button variant='text' size='small'
                      onClick={() => handleApplyCoupon(coupon)}
                      sx={{ alignSelf: 'center',color: 'info.main' }}
                    >
                      Apply
                    </Button>
                  </CardContent>
                  {index < couponData.length - 1 && <Divider />}
                </div>
              ))}
            </Card>
          </Scrollbar>
          {/* <Stack direction="row" justifyContent="flex-start" sx={{ mt: 1 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Apply Coupon
            </LoadingButton>
            <Button
              type="button"
              color="error"
              variant="outlined"
              onClick={() => setOpenCouponCode(false)}
              sx={{ ml: 1.5 }}
            >
              Close
            </Button>
          </Stack> */}
        </Stack>
      </Form>
    </FormikProvider>
  )
}
