import { Typography, Card } from '@material-ui/core'
import DialogAnimateNew from './DialogAnimateNew'

export default function Modal({ open, handleClose, modalTitle,width, children }) {
  return (
    <DialogAnimateNew open={open} onClose={handleClose}>
      <Card sx={{ p: 3, maxWidth:width? width: 768, mx: 'auto' }}>
        <Typography variant="h6" sx={{ marginBottom: 3, textAlign: 'center' }}>
          {modalTitle}
        </Typography>
        {children}
      </Card>
    </DialogAnimateNew>   
  )
}