import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Card, Button, InputAdornment, Input, Grid, Tooltip, IconButton, Typography, Divider, CardMedia, CardHeader, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper, Stack, Avatar, alpha, Autocomplete, TextField, PaginationItem, Badge, Switch, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Add, CardGiftcard, Category, Delete, Remove, Search, ShoppingCart } from '@material-ui/icons';
import styled from '@emotion/styled';
import Modal from '../../ProjectModel';
import { useLocation, useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import axios from 'axios';
import { LBS_CONSTANT, REST_API_END_POINT } from 'src/constants/DefaultValues';
import useAuth from 'src/hooks/useAuth';
import Page from '../../../../../components/Page';
import Scrollbar from 'src/components/Scrollbar';
import Pagination from '@material-ui/lab/Pagination';
import { useSnackbar } from 'notistack';
import { empty } from 'src/constants/Helper';
import { AppTopRelated } from 'src/components/_dashboard/general-app';
import { MIconButton } from 'src/components/@material-extend';
import CheckoutConfirmation from 'src/components/_dashboard/pop-ups/CheckoutConfirmation';
import { makeStyles } from '@material-ui/core/styles';
import Label from 'src/components/Label';
import Logo from '../../../../../logo/image.png'
import EmptyContent from 'src/components/EmptyContent';
import moment from 'moment';
import GiftCouponCardModal from '../../GiftCouponCardModal';

const CardMediaStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'left',
    paddingTop: 'calc(100% * 9 / 16)',
    '&:before': {
        top: 0,
        zIndex: 9,
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundImage: 'url("https://bagempire-api.gettridant.com/uploads/products/Candy_Runts7.jpg")',
        backgroundSize: 'cover'
    }
}));
const CardMediaStyle1 = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    paddingTop: 'calc(100% * 9 / 10)',
    '&:before': {
        top: 0,
        zIndex: 9,
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
    }
}));
const CoverImgStyle = styled('img')({
    top: 0,
    zIndex: 8,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    padding: '3px',

});

const useStyles = makeStyles((theme) => ({
    active: {
        backgroundColor: '#ccc !important',
      color: '#fff', // White text color for the active page
    },
    root: {
      '& .MuiPaginationItem-root': {
        color: '#000', // Default color for pagination items
      },
    },
  }));

const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '220px',
    boxShadow: '4px 4px 4px -2px #332d2dbd',
    '&:hover': {
        boxShadow: '6px 6px 20px -2px #0000009e',
    },
}));

export const getCookie = (key, type = 'object') => {
    const data = window.localStorage.getItem(key);
    if (!data) return null;

    if (type === 'object') {
        try {
            return JSON.parse(data);
        } catch (e) {
            console.error(`Error parsing JSON from localStorage for key: ${key}`, e);
            return null;
        }
    }

    return data;
};
const getData = (source, ids) => {
    if (!ids) return [];
    return ids.split(',').map(id => source.find(item => item.package_id == id)).filter(item => item !== undefined);
};
const Coupons = [
    { label: 'Sample' },
    { label: 'New' },
    { label: 'Test' },
];

const getGradientStyles = (customerType) => {
  switch (customerType) {
    case 1: // Gold
      return {
        backgroundImage:
          'linear-gradient(12deg, #FFD700 20%, #FFB800 40%, #FF8C00 60%, #FFA500 100%)', // Gold-like gradient
      };
    case 2: // Silver
      return {
        backgroundImage:
          'linear-gradient(12deg, #C0C0C0 20%, #B0B0B0 40%, #A9A9A9 60%, #808080 100%)', // Silver-like gradient
      };
    case 3: // Rose Gold (Platinum)
      return {
        backgroundImage:
          'linear-gradient(12deg, #B76E79 20%, #D29EAB 40%, #FFD1DC 60%, #FFE4E1 100%)', // Rose gold gradient
      };
    case 4: // Emerald
      return {
        backgroundImage:
          'linear-gradient(12deg, #50C878 20%, #46A76A 40%, #3D915E 60%, #347A53 100%)', // Emerald-like gradient
      };
    default: // Default gradient (Black and gray)
      return {
        backgroundImage:
          'linear-gradient(12deg, #000000 20%, #2B2B2B 40%, #434343 60%, #000 100%)', // Default gradient for Stack
        backgroundColor: '#fff', // Default background color for fallback
      };
  }
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 42, 
  height: 24, 
  padding: 0,
  marginRight:'9px',
  marginBottom:'9px',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 22,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(13px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3, 
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'primary.main' : 'primary.main',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 3px 6px 0 rgb(0 35 11 / 20%)', 
    width: 18, 
    height: 18, 
    borderRadius: 9, 
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function NewShopCardForEdit({
    products,
    pricePoints,
    loadingproducts,
    setLoadingproducts,
    autoApplyCoupons,
    setAutoApplyCoupons,
    couponInfo,
    setCouponInfo,
    orders,
    deals,
    isPickUp,
    categories,
    customer_id,
    customerData,
    readyToPlaceOrder,
    order_id,
    facility_id,
    shippingCharges,
    setShippingCharges

}) {
    const [addedRedeem,setAddRedeem] = useState(false)
    const [addRedeemCoupon,setAddRedeemCoupon] = useState(false)
    const [orderId, setOrderId] = useState(null);
    const { user } = useAuth();
    const [selectedProduct, setProductSelected] = useState({});
    const [finishedProduct, setFinishedProducts] = useState([]);
    const [activeFirstTab, setActiveFirstTab] = useState(0);
    const [allPackages, setAllPackages] = useState([]);
    // const [selectedCategory_id, setSelectedCategory_id] = useState(categories[0]?.category_id);
    const [selectedCategory_id, setSelectedCategory_id] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [cartItem, setCartItem] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemCount, setSelectedItemCount] = useState(1);
    const [allPackagesQuantities, setAllPackagesQuantities] = useState({});
    const [finishedProductQuantities, setFinishedProductQuantities] = useState({});
    const [dailyLimitExceed, setDailyLimitExceed] = useState(false);
    const [editOrderLoading, setEditOrderLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation()
    const [orderItems, setOrderItems] = useState([]);
    const [thcCategories, setThcCategories] = useState({});
    const [customer, setCustomer] = useState({});
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [cartTotals, setCartTotals] = useState({
      subtotal: 0,
      discount:0,
      tax: 0,
      qty: 0,
      total: 0,
      thc_medical: 0,
      thc_edible: 0,
      thc_non_edible: 0,
      auto_apply_discount: 0,
      total_due: 0
  })
  const [currencyRate, setCurrencyRate] = useState(1)
  const OrderData = allPackages?.find(d => d.package_id == cartItem?.package_id)
  const qty_types = {
      '0': 'mg',
      '1': 'g',
      '2': 'oz',
      '3': 'units',
      '4': 'mg',
      '5': 'mg',
      '6': 'mg',
      '7': 'mg',
      '8': '',
      '9': 'units'
  };
    const [paymentData, setPaymentData] = useState({
        '1_s': 0,
        '2_s': 0,
        '5_s': 0,
        '10_s': 0,
        '20_s': 0,
        '50_s': 0,
        '100_s': 0,
        'cents': 0,
        'nickles': 0,
        'dimes': 0,
        'quarters': 0,
        '50_cents': 0,
        '1_d': 0,
        'cash': 0,
        'card_name': "",
        'card_number': "",
        'debit': 0,
        'discount': 0,
        'discount_value': 0,
        's_discount_type': 1,
        'discount_reason': "",
        'discount_product': "",
        'coupon_code': "",
        'coupon_discount': 0,
        points: 0,
        point_discount: 0,
    })
    const [coupon, setCoupon] = useState('');
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [orderData,setOrderData] = useState({})
    const [appliedCouponInfos, setAppliedCouponInfos] = useState([]);
    const [hypurTransactionId, setHypurTransactionId] = useState('');
    const [changeData, setChangeData] = useState(null);
    const [blocking, setBlocking] = useState(false);
    const [blockingOrder, setBlockingOrder] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [fullscreen, setFullscreen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const classes = useStyles();
    const inputRef = useRef(null);
    const [totalQty, setTotalQty] = useState(0);
    const [openCouponCode, setOpenCouponCode] = useState(false);
    const [isShipping, setIsShipping] = useState(true);
    const [shippingRate, setShippingRate] = useState(0);
    const [shipmentType, setShipmentType] = useState(3);

    const handleOpenCouponModal = () => {
      setOpenCouponCode(true);
    }
    const handleCloseCouponModal = () => {
      setOpenCouponCode(false);
    }
    useEffect(() => {
      const total = cartItem.reduce((acc, item) => acc + item.qty, 0);
      setTotalQty(total);
    }, [cartItem]);

  useEffect(() => {
    if (!blockingOrder && inputRef.current) {
      inputRef.current.focus();
    }
  }, [blockingOrder]);

    useEffect(()=>{
      // if(customerData){
      //   fetchAllDataForUpdateOrder()
      // }
      if (customerData && Object.keys(customerData).length > 0) {
        fetchAllDataForUpdateOrder();
      }
  },[customerData])

  const handleCouponApply = async (coupondata = null) => {
    try {
      let addedCoupon = coupondata ? coupondata : coupon
      // const applyCouponResult = couponInfo?.filter((item)=>item.auto_apply==0)?.find(cp=>cp.code===coupon);
      const today = moment();
      const applyCouponResult = couponInfo
        ?.filter(item => item.auto_apply === 0)
        ?.find(cp => {
          const startDate = moment(cp.start_date);
          const endDate = moment(cp.end_date);
          return cp.code === addedCoupon && today.isBetween(startDate, endDate, 'day', '[]');
        });
      if(applyCouponResult){
        let newDiscount = 0;
        let newCouponCodes = [];
        if (applyCouponResult.is_product === 1 && applyCouponResult.type === 1) {
          newDiscount += parseFloat(cartTotals.total) * parseFloat(applyCouponResult.amount) / 100;
          newCouponCodes.push(applyCouponResult.code);
        }else if (applyCouponResult.is_product === 1 && applyCouponResult.type === 0) {
          newDiscount += parseFloat(applyCouponResult.amount);
          newCouponCodes.push(applyCouponResult.code);
        }
        if(coupondata){
          await recalculateTotals(cartItem, false, addedCoupon);
        }else{
          await recalculateTotals(cartItem, true, addedCoupon);
        }
      }else if(!coupondata){
        console.log('Entered coupon is invalid or not applicable now');
        enqueueSnackbar("Coupon code is invalid or not applicable now", { variant: 'warning' });
        setCoupon('');
      }
    } catch (e) {
      console.log("handleCouponApply, Error", e);
    }
  }
  
  useEffect(()=>{
    if(paymentData.coupon_discount > 0 || cartTotals.auto_apply_discount > 0 ){
      setAddRedeemCoupon(true);
    }else{
      setAddRedeemCoupon(false);
    }
  },[cartTotals, paymentData])

// const fetchAllDataForUpdateOrder =async()=>{
//   setCartItem([])
//       localStorage.setItem('cartItem', JSON.stringify([]));

//  const response1 = await axios.post(`${REST_API_END_POINT}point-of-sale/edit-order-data`,{
//   order_id,facility_id,user_id:user.id,user_time:moment().format('HH:mm:00')
//  })
//  if(response1.data.status===1){
//   const cartData = response1.data.cart
//   console.log("response-123-----------",response1.data.cart);
//   setCartItem(cartData)
//   // recalculateTotals(cartData);  
//   recalculateTotalsssss(cartData)


//  }else{
//   console.log("not getting data");
  
//  }
//   }

const fetchAllDataForUpdateOrder = async () => {
  try{
    setEditOrderLoading(true);
    setCartItem([]);
    // localStorage.setItem('cartItem', JSON.stringify([]));

    const response1 = await axios.post(`${REST_API_END_POINT}point-of-sale/edit-order-data`, {
      order_id,
      facility_id,
      user_id: user.id,
      user_time: moment().format('HH:mm:00')
    });

    if (response1.data.status === 1) {
      if(response1.data.whole_order_data.point_discount > 0  ){
        const couponCode = response1.data.whole_order_data.coupon_code?.split(',')
        handleCouponApply(couponCode);

        setPaymentData((prev) => ({
          ...prev,
          points:response1.data.whole_order_data.points_used,
          point_discount: response1.data.whole_order_data.point_discount,
          coupon_discount: parseFloat(response1.data.whole_order_data.coupon_discount.toFixed(2)),
          coupon_code:response1.data.whole_order_data.coupon_code
        }))
        setOrderData(response1.data.whole_order_data)
        setAddRedeem(true)
      }
      const cartData = response1.data.cart;
      const shipping = response1.data.whole_order_data?.isShipping == 1 ? true : false
      const shippingType = response1.data.whole_order_data?.shipmentType || 3;
      const shippingRt = response1.data.whole_order_data?.shippingRate || 0
      console.log('shippingRate--',shippingRt)
      // setIsShipping(shipping)
      setShipmentType(shippingType)
      setShippingRate(shippingRt)
      setShippingCharges(shippingRt)
      // Transform cartData to match the structure expected by recalculateTotalsssss
      const transformedCartData = cartData.map(item => {
        let variationData = item?.variations?.find((varItem) => customerData?.customer_type == varItem?.customer_type);
        // let variationData = item?.variations?.find((varItem) => item.qty >= varItem?.min_value && item.qty <= varItem?.max_value);

        // if (!variationData) {
        //     variationData = item?.variations?.reduce((prev, current) => {
        //       return current.max_value > prev.max_value ? current : prev;
        //     }, { max_value: -Infinity });
        // }
        console.log('variationData-variationData----',variationData)
        console.log('variationData-item----',item)
        
        return{
        id: item.id,
        name: item.name,
        qty: item.qty,
        initial_qty: item.initial_qty,
        variation_id: variationData?.variation_id,
        unit_price: item.product_unit_price || item.unit_price || 0,
        price: item.product_price || item.price || 0,
        price_add:item.price_add,
        grade:empty(item.price_add) ? item.grade : 0,
        price_group:empty(item.price_add) ? item.price_group : null,
        sales_tax:((item.product_unit_price || item.unit_price || 0) * Number(item.tax_rate) / 100).toFixed(2),
        unit_sales_tax: item.price_add ==false ? item.product_sales_tax : ((item.product_unit_price || item.unit_price || 0) * Number(item.tax_rate) / 100).toFixed(2),
        product_type: item.product_type,
        tax_rate: item.sales_tax || 0,
        variation_weight: item.variation_weight || 0,
        real_quantity: item.real_quantity || 0,
        variations: item.variations || [],
        shipping_cost: item.shipping_cost || [],
        discount: item.discount || 0,
        totalOff: item.totalOff || 0,
        // unit_sales_tax: item.unit_sales_tax || 0,
        exise_tax: item.exise_tax || 0,
        coupon_discount: item.coupon_discount || 0,
        flag: 0, // Default value for flag (you can adjust based on your needs)
        // Add any additional fields that are needed for recalculateTotalsssss
        package_id: item.package_id,
        package_total_unit_cost: item.package_total_unit_cost || 0,
        is_finished: item.is_finished,
        max_quantity: item.max_quantity || 0,
        options: item.options || {},
        daily_limit_amount: item.daily_limit || 0,
        }
      });
    let orderPoints={
      points:response1.data.whole_order_data.points_used,
      point_discount:response1.data.whole_order_data.point_discount
    }
    let couponData={
      couponDiscount:response1.data.whole_order_data.coupon_discount,
      couponCode:response1.data.whole_order_data.coupon_code
    }
      // Now pass the transformed data to recalculateTotalsssss
      setCartItem(transformedCartData);
      // recalculateTotalsssss(transformedCartData,orderPoints, couponData);
      recalculateTotalsssss(transformedCartData, shippingType, shippingRt, orderPoints, couponData);

    } else {
      console.log("not getting data");
    }
  }catch(error){
    console.log('Update order error : ',error);
  }finally{
    setEditOrderLoading(false);
  }
};


    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullscreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
    };

    const handleIncrementSelectedItemCountFirstTab = (index) => {
        setAllPackagesQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: (prevQuantities[index] || 1) + 1,
        }));
    };
    const handleDecrementSelectedItemCountFirstTab = (index) => {
        setAllPackagesQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: Math.max((prevQuantities[index] || 1) - 1, 1),
        }));
    };

    const handleIncrementSelectedItemCount = (index) => {
        setFinishedProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: (prevQuantities[index] || 1) + 1,
        }));
    };

    const handleDecrementSelectedItemCount = (index) => {
        setFinishedProductQuantities((prevQuantities) => ({
            ...prevQuantities,
            [index]: Math.max((prevQuantities[index] || 1) - 1, 1),
        }));
    };

    // const handleSearchChange = (event) => {
    //     setSearchTerm(event.target.value);
    // };

    const handleSearchChange = (e) => {
      const scannedValue = e.target.value;
      setSearchTerm(scannedValue);
      const matchedProduct = products?.find(
        // (product) => product.name.toLowerCase().includes(scannedValue.toLowerCase())
        // (product) => product.name.trim().toLowerCase() === scannedValue
        (product) => product.name === scannedValue
      );
      if (matchedProduct) {
        handleClickOpenSearch(matchedProduct);
        setSearchTerm('');
      }
    };

    const handleCategoryFilter = (category_id) => {
      if (category_id === null) {
        // Show all categories logic
        setSelectedCategory_id(null);
      } else {
        // Filter categories by category_id logic
        setSelectedCategory_id(category_id);
      }
    };    

    const filteredItems = products?.filter((item) => {
        const itemName = String(item.name).toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();
        const categoryMatch = !selectedCategory_id || item.category_id === selectedCategory_id;
        const isStockAvailable = item.product_stock > 0;
        return itemName.includes(searchTermLower) && categoryMatch && isStockAvailable;
    });

    const paginatedItems = filteredItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // const paginatedItems = filteredItems

    const handlePageChange = (event, value) => {
        // setCurrentPage(value);
        const maxPage = Math.ceil(filteredItems.length / itemsPerPage); 

        if (value >= 1 && value <= maxPage) {
            setCurrentPage(value);
        } else {
            console.log('Invalid page number');
        }
    };


    // const handleRemoveFromOrder = async (itemName) => {
    //     setOrderItems(prevOrderItems => {
    //         const updatedOrderItems = prevOrderItems.filter(item => item.name !== itemName);
    //         return updatedOrderItems;
    //     });
    //     await recalculateTotals(newData);
    // };

    const handleRemoveFromOrder = async (itemName) => {
        setCartItem(prevCart => {
            const updatedCart = prevCart
              ?.filter(item => item.package_id !== itemName)
              ?.map((item) => {
                const shippingCosts = Array.isArray(item.shipping_cost) ? item.shipping_cost : [];
                const shippingTypeCost = shippingCosts.find((cost) => cost.type === shipmentType);
        
                // Update the item with per-unit and total shipping prices
                item.shipping_price = shippingTypeCost ? shippingTypeCost.cost : 0;
                item.total_shipping_price = shippingTypeCost ? shippingTypeCost.cost * (item.qty || 1) : 0;
        
                return item;
              });
      
            const totalShippingCharges = updatedCart.reduce((sum, item) => sum + item.total_shipping_price, 0);
      
            // const updatedCart = prevCart.filter(item => item.package_id !== itemName);
            
            // // Create a map of unique items by id
            // const uniqueItems = new Map();
            // updatedCart.forEach(item => {
            //   if (!uniqueItems.has(item.id)) {
            //     uniqueItems.set(item.id, item);
            //   }
            // });

            // // Calculate total shipping charges based on selected shipment type
            // const totalShippingCharges = Array.from(uniqueItems.values()).reduce((sum, item) => {
            //   if (item.shipping_cost && Array.isArray(item.shipping_cost)) {
            //     // Find the shipping cost that matches the selected type
            //     const shippingTypeCost = item.shipping_cost.find(
            //       cost => cost.type === shipmentType // Match the selected shipment type
            //     );
            //     return sum + (shippingTypeCost ? shippingTypeCost.cost : 0); // Add cost if type is found
            //   }
            //   return sum;
            // }, 0);

            setShippingCharges(totalShippingCharges);
            setShippingRate(totalShippingCharges);
            recalculateTotals(updatedCart);
            recalculateTotalsssss(updatedCart, shipmentType, totalShippingCharges );
            return updatedCart;
        });
    };

    const numberFormat = (number, decimals = 2, decPoint = '.', thousandsSep = ',') => {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        const n = !isFinite(+number) ? 0 : +number;
        const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
        let s = '';

        const toFixedFix = (n, prec) => {
            const k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };

        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
        }

        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }

        return s.join(decPoint);
    };

    // const recalculateTotals = async (inputCart = null, showWarning = false) => {
    //     const currentCart = Array.isArray(inputCart) ? inputCart : Array.isArray(cartItem) ? cartItem : [];
    //     const idTotals = {};
    //     // if (Array.isArray(currentCart)) {
    //     currentCart?.forEach(item => {
    //         const id = item.id;
    //         let adjustTotalQuantity = item?.is_finished === 0 ? item.qty : item.qty * item.variation_weight;

    //         if (!idTotals[id]) {
    //             idTotals[id] = 0;
    //         }

    //         idTotals[id] += adjustTotalQuantity;
    //     });
    //     // }

    //     let coupons = [];
    //     if (coupon !== '') {
    //         coupons = coupon.split(',');
    //     }

    //     let couponDiscount = 0;
    //     let autoApplyDiscount = 0;
    //     const couponDatas = [];

    //     for (const coupon of coupons) {
    //         if (coupon !== '') {
    //             let couponData = appliedCouponInfos.find(r => r.code === coupon);

    //             if (!couponData) {
    //                 const userId = localStorage.getItem('user_id');
    //                 const customerId = customer.customer_id;
    //                 let facilityId = localStorage.getItem('facility_id');
    //                 // facilityId = dataCrypto.decrypt(facilityId);

    //                 const formData = new FormData();
    //                 formData.append('facility_id', facilityId);
    //                 formData.append('user_id', userId);
    //                 formData.append('customer_id', customerId);
    //                 formData.append('coupon', coupon);

    //                 try {
    //                     const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
    //                     if (res.data.status === 1) {
    //                         couponData = res.data.coupon_data;
    //                         setAppliedCouponInfos(prevState => [...prevState, couponData]);
    //                     }
    //                 } catch (e) {
    //                     console.log("Addition failed, Error", e);
    //                 }
    //             }

    //             if (couponData) {
    //                 if (couponData.is_product === '1' && couponData.type === '0') {
    //                     couponDiscount += parseFloat(couponData.amount);
    //                 }
    //                 couponDatas.push(couponData);
    //             }
    //         }
    //     }

    //     for (const couponData of autoApplyCoupons) {
    //         if (couponData.is_product === '1' && couponData.type === '0') {
    //             autoApplyDiscount += parseFloat(couponData.amount);
    //         }
    //     }

    //     const updatedCartTotals = {
    //         subtotal: 0,
    //         discount: 0,
    //         tax: 0,
    //         total: 0,
    //         thc_medical: 0,
    //         thc_edible: 0,
    //         thc_non_edible: 0,
    //         auto_apply_discount: 0,
    //         total_due: 0
    //     };

    //     const updatedCart = currentCart?.map(row => {
    //         row.thc = row.actual_weight;

    //         if (row?.is_finished !== 1) {
    //             const variationData = row?.variations?.find(r => row.options.variation_id === r.variation_id);
    //             if (variationData) {
    //                 row.max_quantity = parseFloat(row.real_quantity);
    //             }
    //         }

    //         if (autoApplyCoupons.length && row.deal_id === 0) {
    //             for (const couponData of autoApplyCoupons) {
    //                 const productIds = couponData.product_id.split(',');
    //                 const categoryIds = couponData.category_id.split(',');
    //                 let productDiscount = 0;

    //                 if (((productIds.includes(row.id.toString()) && couponData.is_product === '3') ||
    //                     (categoryIds.includes(row.category_id.toString()) && couponData.is_product === '4') ||
    //                     (categoryIds.includes(row.sub_category.toString()) && couponData.is_product === '4') ||
    //                     couponData.is_product === '2') && row.options.is_free === 0) {

    //                     productDiscount = couponData.type === '0' ?
    //                         parseFloat(couponData.amount) :
    //                         ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

    //                     if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
    //                         productDiscount = 0;
    //                     }
    //                 }

    //                 row.discount = parseFloat(productDiscount.toFixed(2));
    //             }
    //         } else {
    //             row.discount = 0;
    //         }

    //         if (row.coupon_code !== '') {
    //             let couponData = appliedCouponInfos?.find(r => r.code === row.coupon_code);

    //             if (!couponData) {
    //                 const userId = localStorage.getItem('user_id');
    //                 const customerId = customer.customer_id;
    //                 let facilityId = localStorage.getItem('facility_id');
    //                 // facilityId = dataCrypto.decrypt(facilityId);

    //                 const formData = new FormData();
    //                 formData.append('facility_id', facilityId);
    //                 formData.append('user_id', userId);
    //                 formData.append('customer_id', customerId);
    //                 formData.append('coupon', row.coupon_code);

    //                 // try {
    //                 //     const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
    //                 //     if (res.data.status === 1) {
    //                 //         couponData = res.data.coupon_data;
    //                 //         setAppliedCouponInfos(prevState => [...prevState, couponData]);
    //                 //     }
    //                 // } catch (e) {
    //                 //     console.log("Addition failed, Error", e);
    //                 // }
    //             }

    //             if (couponData) {
    //                 const productIds = couponData.is_product !== '0' ? couponData.product_id.split(',') : [];
    //                 const categoryIds = couponData.is_product !== '0' ? couponData.category_id.split(',') : [];
    //                 let productDiscount = 0;

    //                 if (((productIds.includes(row.id.toString()) && couponData.is_product === '3') ||
    //                     (categoryIds.includes(row.category_id.toString()) && couponData.is_product === '4') ||
    //                     (categoryIds.includes(row.sub_category.toString()) && couponData.is_product === '4') ||
    //                     couponData.is_product === '2' || couponData.is_product === '0') && row.options.is_free === 0) {

    //                     productDiscount = couponData.type === '0' ?
    //                         parseFloat(couponData.amount) :
    //                         ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

    //                     if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
    //                         productDiscount = 0;
    //                     }
    //                 }

    //                 if (showWarning && productDiscount === 0) {
    //                     enqueueSnackbar("Sorry, this coupon is not applicable now", { variant: 'warning' });
    //                     row.coupon_code = "";
    //                 }
    //                 row.coupon_discount = parseFloat(productDiscount.toFixed(2));
    //             }
    //         } else {
    //             row.coupon_discount = 0;
    //         }
    //         console.log('rowrowrowrowrowrowrowrow',row)
    //         const unitExiseTax = row.unit_exise_tax || 0;
    //         const unitSalesTax = row.unit_sales_tax || 0;
    //         // const unitExiseTax = JSON.parse(JSON.stringify(row.unit_exise_tax));
    //         // const unitSalesTax = JSON.parse(JSON.stringify(row.unit_sales_tax));

    //         if (row.unit_price && row.unit_price !== 0) {
    //             const totPrice = row.unit_price * row.qty - row.discount - row.coupon_discount;
    //             row.price = totPrice / row.qty + unitExiseTax + unitSalesTax;
    //         }

    //         if (row.deal_amount) {
    //             row.deal_amount = row.deal_amount;
    //             row.deal_type = row.deal_type;
    //         }

    //         if (idTotals.hasOwnProperty(row.id)) {
    //             if ((idTotals[row.id] + row.total_quantity) > row.daily_limit_amount) {
    //                 setDailyLimitExceed(true);
    //                 enqueueSnackbar(`Daily Limit Exceed, Daily limit is ${row.daily_limit_amount}g`, { variant: 'warning' });
    //                 row.flag = 1;
    //             } else {
    //                 setDailyLimitExceed(false);
    //                 row.flag = 0;
    //             }
    //         }

    //         row.adjust_total_quantity = row.is_finished === 0 ? row.qty : row.qty * row.variation_weight;

    //         const offPrice = row.deal_amount;
    //         let totalOff = 0;
    //         const formatedValue = numberFormat(parseFloat((row.unit_price * row.qty) * currencyRate).toFixed(2), 2, '.', '');
    //         if (row.deal_type === 0) {
    //             const formatedValueNew = numberFormat(parseFloat(formatedValue - offPrice).toFixed(2), 2, '.', '');
    //             if (formatedValueNew > 0) {
    //                 totalOff = offPrice;
    //             }
    //         } else {
    //             const formatedValueNew = numberFormat(parseFloat(formatedValue - (formatedValue * offPrice / 100)).toFixed(2), 2, '.', '');
    //             if (formatedValueNew > 0) {
    //                 totalOff = parseFloat(formatedValue * offPrice / 100).toFixed(2);
    //             }
    //         }
    //         row.totalOff = totalOff;

    //         row.exise_tax = parseFloat((row.qty * unitExiseTax).toFixed(2));
    //         row.sales_tax = parseFloat((row.qty * unitSalesTax).toFixed(2));
    //         row.tax = row.exise_tax + row.sales_tax;
    //         updatedCartTotals.tax += row.tax;

    //         updatedCartTotals.subtotal += parseFloat(((row.unit_price * row.qty)).toFixed(2));
    //         updatedCartTotals.discount += (
    //             parseFloat(row.discount) +
    //             parseFloat(row.coupon_discount) +
    //             parseFloat(row.totalOff)
    //         );

    //         updatedCartTotals.total += parseFloat((row.unit_price * row.qty - row.discount - row.coupon_discount + row.tax - totalOff)?.toFixed(2));

    //         if (thcCategories?.medical_marijuana?.includes(row.category_id) || thcCategories?.medical_marijuana?.includes(row?.sub_category)) {
    //             updatedCartTotals.thc_medical += parseFloat(row.thc);
    //         } else if (thcCategories?.edibles?.includes(row.category_id) || thcCategories?.edibles?.includes(row?.sub_category)) {
    //             updatedCartTotals.thc_edible += parseFloat(row.thc);
    //         } else if (thcCategories?.non_edibles?.includes(row.category_id) || thcCategories?.non_edibles?.includes(row?.sub_category)) {
    //             updatedCartTotals.thc_non_edible += parseFloat(row.thc);
    //         }

    //         return row;
    //     });

    //     // if (couponDatas.length) {
    //     //     for (const couponData of couponDatas) {
    //     //         if (couponData.is_product === '1' && couponData.type === '1') {
    //     //             couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
    //     //         }
    //     //     }
    //     // }

    //     if (updatedCartTotals.total < couponDiscount) {
    //         couponDiscount = 0;
    //     }

    //     // for (const couponData of autoApplyCoupons) {
    //     //     if (couponData.is_product === '1' && couponData.type === '1') {
    //     //         autoApplyDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
    //     //     }
    //     // }

    //     if (updatedCartTotals.total < autoApplyDiscount) {
    //         autoApplyDiscount = 0;
    //     }

    //     updatedCartTotals.discount += parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));
    //     updatedCartTotals.total -= parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));

    //     updatedCartTotals.auto_apply_discount = autoApplyDiscount;
    //     updatedCartTotals.total_due = updatedCartTotals.total * currencyRate - (paymentData.cash || 0) - (paymentData.debit || 0);

    //     setCartItem(updatedCart);
    //     setCartTotals(updatedCartTotals);
    //     // setAppliedCouponInfos(appliedCouponInfos);
    // };
    
    // const recalculateTotals = async (inputCart = null, showWarning = false) => {
    const recalculateTotals = async (inputCart = null, showWarning = false, enteredCouponData='') => {
        const currentCart = Array.isArray(inputCart) ? inputCart : Array.isArray(cartItem) ? cartItem : [];
        const idTotals = {};
        // if (Array.isArray(currentCart)) {
        currentCart?.forEach(item => {
            const id = item.id;
            let adjustTotalQuantity = item?.is_finished === 0 ? item.qty : item.qty * item.variation_weight;

            if (!idTotals[id]) {
                idTotals[id] = 0;
            }

            idTotals[id] += adjustTotalQuantity;
        });
        // }

        let coupons = [];
        if (enteredCouponData !== '') {
            coupons = enteredCouponData.split(',');
        }

        let couponDiscount = 0;
        let autoApplyDiscount = 0;
        let autoApplyCouponCodes = [];
        const couponDatas = [];

        if(coupons?.length > 0){
          for (const coupon of coupons) {
            if (coupon !== '') {
                let couponData = appliedCouponInfos.find(r => r.code === coupon);

                if (!couponData) {

                    const userId = user?.id;
                    const customerId = user?.customer_id;
                    let facilityId = user?.facility_id;

                    const formData = {
                      facility_id: facilityId,
                      user_id: userId,
                      customer_id: customerId,
                      coupon: coupon
                    }

                    try {
                        const res = await axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData);
                        if (res.data.status === 1) {
                            couponData = res.data.coupon_data;
                            setAppliedCouponInfos(prevState => [...prevState, couponData]);
                        }
                    } catch (e) {
                        console.log("Addition failed, Error", e);
                    }
                }

                if (couponData) {
                    if (couponData.is_product === '1' && couponData.type === '0') {
                        couponDiscount += parseFloat(couponData.amount);
                    }
                    if (couponData.is_product === '1' && couponData.type === '1') {
                      couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
                    }
                    couponDatas.push(couponData);
                }
            }
          }
        }else{
          for (const couponData of autoApplyCoupons) {
            if (couponData.is_product === '1' && couponData.type === '0') {
                autoApplyDiscount += parseFloat(couponData.amount);
                autoApplyCouponCodes.push(couponData.code);
            }
            if (couponData.is_product === '1' && couponData.type === '1') {
              couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
              autoApplyCouponCodes.push(couponData.code);
            }
          }
        }
        const updatedCartTotals = {
            subtotal: 0,
            discount: 0,
            tax: 0,
            total: 0,
            thc_medical: 0,
            thc_edible: 0,
            thc_non_edible: 0,
            auto_apply_discount: 0,
            total_due: 0
        };

        const updatedCart = currentCart?.map(row => {
          console.log('row---row----',row);
          row.thc = row.actual_weight;
          if (!row.price_add) {
            let variationData = row?.variations?.find((varItem) => customerData.customer_type == varItem?.customer_type);
            // let variationData = row?.variations?.find((varItem) => row.qty >= varItem?.min_value && row.qty <= varItem?.max_value);

            // if (!variationData) {
            //     variationData = row?.variations?.reduce((prev, current) => {
            //       return current.max_value > prev.max_value ? current : prev;
            //     }, { max_value: -Infinity });
            // }

            const packageTotalUnitCost = parseFloat(row?.package_total_unit_cost) || 0;
            const profit = parseFloat(variationData?.profit) || 0;
            const finalPrice = (packageTotalUnitCost + profit).toFixed(2);

            // row.price = parseFloat(finalPrice);
            // row.unit_price = parseFloat(finalPrice);

            // // row.price = variationData?.price || 0
            // // row.unit_price = variationData?.unit_price  || 0
            // row.unit_sales_tax = variationData?.tax || 0
            row.variation_id = variationData?.variation_id || 0

            if (row?.is_finished !== 1 && variationData) {
                const variationDatas = row?.variations?.find(r => row.options.variation_id === variationData?.variation_id);
                if (variationDatas) {
                    row.max_quantity = parseFloat(row.real_quantity);
                }
            }
          }
            
            // if (autoApplyCoupons.length && row.deal_id === 0) {
            if (autoApplyCoupons.length && row.deal_id === 0 && (coupons?.length <= 0)) {
                for (const couponData of autoApplyCoupons) {
                    const productIds = couponData.product_id.split(',');
                    const categoryIds = couponData.category_id.split(',');
                    let productDiscount = 0;

                    if (((productIds.includes(String(row.id)) && couponData.is_product === '3') ||
                        (categoryIds.includes(String(row.category_id)) && couponData.is_product === '4') ||
                        (categoryIds.includes(String(row.sub_category)) && couponData.is_product === '4') ||
                        couponData.is_product === '2') && row.options.is_free === 0) {

                        productDiscount = couponData.type === '0' ?
                            parseFloat(couponData.amount) :
                            ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

                        if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
                            productDiscount = 0;
                        }
                    }

                    row.discount = parseFloat(productDiscount.toFixed(2));
                }
            } else {
                row.discount = 0;
            }
           
            if (row.coupon_code !== '') {
                let couponData = appliedCouponInfos?.find(r => r.code === row.coupon_code);

                if (!couponData) {
                  const userId = user?.id;
                  const customerId = user?.customer_id;
                  let facilityId = user?.facility_id;
                  
                  const formData = {
                    facility_id: facilityId,
                    user_id: userId,
                    customer_id: customerId,
                    coupon: row.coupon_code
                  }

                  axios.post(`${REST_API_END_POINT}point-of-sale/get-applied-coupon-detail`, formData)
                  .then((res) => {
                      if (res.data.status === 1) {
                          const couponData = res.data.coupon_data;
                          setAppliedCouponInfos((prevState) => [...prevState, couponData]);
                      }
                  })
                  .catch((e) => {
                      console.log("Addition failed, Error", e);
                  });
                }

                if (couponData) {
                    const productIds = couponData.is_product !== '0' ? couponData.product_id.split(',') : [];
                    const categoryIds = couponData.is_product !== '0' ? couponData.category_id.split(',') : [];
                    let productDiscount = 0;

                    if (((productIds.includes(String(row.id)) && couponData.is_product === '3') ||
                        (categoryIds.includes(String(row.category_id)) && couponData.is_product === '4') ||
                        (categoryIds.includes(String(row.sub_category)) && couponData.is_product === '4') ||
                        couponData.is_product === '2' || couponData.is_product === '0') && row.options.is_free === 0) {

                        productDiscount = couponData.type === '0' ?
                            parseFloat(couponData.amount) :
                            ((row.unit_price + row.unit_sales_tax) * row.qty) * parseFloat(couponData.amount) / 100;

                        if (productDiscount > parseFloat(((row.unit_price + row.unit_sales_tax) * row.qty).toFixed(2))) {
                            productDiscount = 0;
                        }
                    }

                    if (showWarning && productDiscount === 0) {
                        enqueueSnackbar("Sorry, this coupon is not applicable now", { variant: 'warning' });
                        row.coupon_code = "";
                    }
                    row.coupon_discount = parseFloat(productDiscount.toFixed(2));
                    setPaymentData(prevState => ({
                      ...prevState,
                      coupon_discount: parseFloat(productDiscount.toFixed(2))
                    }));
                }
            } else {
                row.coupon_discount = 0;
                setPaymentData(prevState => ({
                  ...prevState,
                  coupon_discount: 0
                }));
            }
            const unitExiseTax = row.unit_exise_tax || 0;
            const unitSalesTax = row.unit_sales_tax || 0;
            // const unitExiseTax = JSON.parse(JSON.stringify(row.unit_exise_tax));
            // const unitSalesTax = JSON.parse(JSON.stringify(row.unit_sales_tax));

            if (row.unit_price && row.unit_price !== 0) {
                const totPrice = row.unit_price - row.discount - row.coupon_discount;
                row.price = totPrice + unitExiseTax + unitSalesTax;
            }

            if (row.deal_amount) {
                row.deal_amount = row.deal_amount;
                row.deal_type = row.deal_type;
            }

            // if (idTotals.hasOwnProperty(row.id)) {
            //     if ((idTotals[row.id] + row.total_quantity) > row.daily_limit_amount) {
            //         setDailyLimitExceed(true);
            //         enqueueSnackbar(`Daily Limit Exceed, Daily limit is ${row.daily_limit_amount}g`, { variant: 'warning' });
            //         row.flag = 1;
            //     } else {
            //         setDailyLimitExceed(false);
            //         row.flag = 0;
            //     }
            // }

            row.adjust_total_quantity = row.is_finished === 0 ? row.qty : row.qty;

            const offPrice = row.deal_amount;
            let totalOff = 0;
            const formatedValue = parseFloat((row.unit_price) * currencyRate)?.toFixed(2);
            if (row.deal_type === 0) {
                const formatedValueNew = parseFloat(formatedValue - offPrice)?.toFixed(2);
                if (formatedValueNew > 0) {
                    totalOff = offPrice;
                }
            } else {
                const formatedValueNew = parseFloat(formatedValue - (formatedValue * offPrice / 100))?.toFixed(2);
                if (formatedValueNew > 0) {
                    totalOff = parseFloat(formatedValue * offPrice / 100).toFixed(2);
                }
            }
            row.totalOff = totalOff;

            // row.exise_tax = parseFloat((unitExiseTax).toFixed(2));
            // row.sales_tax = parseFloat((unitSalesTax).toFixed(2));
            row.exise_tax = parseFloat((row.qty * unitExiseTax).toFixed(2));
            row.sales_tax = parseFloat((row.qty * unitSalesTax).toFixed(2));
            row.tax = row.exise_tax + row.sales_tax;
            updatedCartTotals.tax += Number(row.tax);
            updatedCartTotals.qty += Number(row.qty);

        
            

            updatedCartTotals.subtotal += parseFloat(((row.unit_price * row.qty)).toFixed(2));
            updatedCartTotals.discount += (
                parseFloat(row.discount) +
                parseFloat(row.coupon_discount) +
                parseFloat(row.totalOff)
            );
    //         updatedCartTotals.total += parseFloat((row.unit_price * row.qty - row.discount - row.coupon_discount + row.tax - totalOff)?.toFixed(2));

            updatedCartTotals.total += parseFloat((Number(row.unit_price) * Number(row.qty) - Number(row.discount) - Number(row.coupon_discount) + Number(row.tax) - Number(totalOff))?.toFixed(2));

            if (thcCategories?.medical_marijuana?.includes(row.category_id) || thcCategories?.medical_marijuana?.includes(row?.sub_category)) {
                updatedCartTotals.thc_medical += parseFloat(row.thc);
            } else if (thcCategories?.edibles?.includes(row.category_id) || thcCategories?.edibles?.includes(row?.sub_category)) {
                updatedCartTotals.thc_edible += parseFloat(row.thc);
            } else if (thcCategories?.non_edibles?.includes(row.category_id) || thcCategories?.non_edibles?.includes(row?.sub_category)) {
                updatedCartTotals.thc_non_edible += parseFloat(row.thc);
            }

            return row;
        });

        // if (couponDatas.length) {
        //     for (const couponData of couponDatas) {
        //         if (couponData.is_product === '1' && couponData.type === '1') {
        //             couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
        //         }
        //     }
        // }

        if (couponDatas?.length > 0) {
          for (const couponData of couponDatas) {
            if (couponData.is_product === 1 && couponData.type === 0) {
              couponDiscount += parseFloat(couponData.amount);
            }
            if (couponData.is_product === 1 && couponData.type === 1) {
              couponDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
            }
          }
        }else{
          for (const couponData of autoApplyCoupons) {
            if (couponData.is_product === 1 && couponData.type === 1) {
              autoApplyDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
              autoApplyCouponCodes.push(couponData.code);
            }else if (couponData.is_product === 1 && couponData.type === 0) {
              autoApplyDiscount += parseFloat(couponData.amount);
              autoApplyCouponCodes.push(couponData.code);
            }
          }
        }

        if (updatedCartTotals.total < couponDiscount) {
            couponDiscount = 0;
        }

        // for (const couponData of autoApplyCoupons) {
        //     if (couponData.is_product === '1' && couponData.type === '1') {
        //         autoApplyDiscount += updatedCartTotals.total * parseFloat(couponData.amount) / 100;
        //     }
        // }

        if (updatedCartTotals.total < autoApplyDiscount) {
            autoApplyDiscount = 0;
            autoApplyCouponCodes = [];
        }

        if(couponDiscount > 0){
          autoApplyDiscount = 0;
          autoApplyCouponCodes = [];
        }
        updatedCartTotals.discount += parseFloat(couponDiscount + autoApplyDiscount + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));
        updatedCartTotals.total -= parseFloat(Number(couponDiscount) + Number(autoApplyDiscount) + (((paymentData.discount_value || 0) + (paymentData.point_discount || 0)) / currencyRate));
        // updatedCartTotals.total += parseFloat(Number(shippingRate));
        updatedCartTotals.total += parseFloat(parseFloat(shippingRate).toFixed(2)) || 0;

        updatedCartTotals.auto_apply_discount = autoApplyDiscount;
        setPaymentData(prevState => ({
          ...prevState,
          coupon_discount: (couponDiscount && couponDiscount > 0) ? parseFloat(couponDiscount) : parseFloat(autoApplyDiscount.toFixed(2)),
          coupon_code:(couponDiscount && couponDiscount > 0) ? coupon : autoApplyCouponCodes?.join(',')
        }));
        setCoupon('');
        updatedCartTotals.total_due = updatedCartTotals.total * currencyRate - (paymentData.cash || 0) - (paymentData.debit || 0);

        setCartItem(updatedCart);
        setCartTotals(updatedCartTotals);
        // setAppliedCouponInfos(appliedCouponInfos);
    };

    useEffect(async () => {
        // const savedCartItem = localStorage.getItem('cartItem');
        // if (savedCartItem) {
        //     setCartItem(JSON.parse(savedCartItem));
        // }
        // await recalculateTotals(JSON.parse(savedCartItem));
        setOrderId(order_id)
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('cartItem', JSON.stringify(cartItem));
    // }, [cartItem]);

    const handleAddToOrder = (data,
      // quantity
    ) => {
      setBlockingOrder(true);
      console.log('=======data', data)
      // setCartItem(prevCartItems => {
      //     const updatedCart = [...prevCartItems, data];      
      //     return updatedCart;
      // });//for now 
      let cartData = {}
      var bogo_code = getCookie('bogo_code', 'string');
      let existingCart = getCookie('cart', 'object');
      try {
        axios.post(REST_API_END_POINT + 'point-of-sale/add-to-cart', {
          facility_id: user.facility_id,
          user_id: user.id,
          customer_id,
          bogo_code: bogo_code,
          free_product: 0,
          deal_id:0,
          parent_product: 0,
          product_id: data?.product_id,
          existing_cart: cartItem || [],
          product_price: data?.package_total_unit_cost || 0,
          product_unit_price: data?.package_total_unit_cost || 0,
          product_grade:data?.grade || 0,
          price_group:data?.price_group || '',
          tax_rate: data?.tax_rate || 0,
          exise_tax: 0,
          sales_tax: 0,
          quantity: 1,
          variation_id: data?.variation_id,
          package_id: data?.package_id,
          is_finished: data?.package_type == 1 ? 0 : 1,
        }).then((res) => {
          if (res.data.status === 1) {
            cartData = res.data.cart_item
            setCartItem(prevCartItems => {
              // const updatedCart = [...prevCartItems, cartData];
              // handleOrderUpdate(updatedCart, cartData);
              
              // // Create a map of unique items by id
              // const uniqueItems = new Map();
              // updatedCart.forEach(item => {
              //   if (!uniqueItems.has(item.id)) {
              //     uniqueItems.set(item.id, item);
              //   }
              // });

              // // Calculate total shipping charges based on selected shipment type
              // const totalShippingCharges = Array.from(uniqueItems.values()).reduce((sum, item) => {
              //   if (item.shipping_cost && Array.isArray(item.shipping_cost)) {
              //     // Find the shipping cost that matches the selected type
              //     const shippingTypeCost = item.shipping_cost.find(
              //       cost => cost.type === shipmentType // Match the selected shipment type
              //     );
              //     return sum + (shippingTypeCost ? shippingTypeCost.cost : 0); // Add cost if type is found
              //   }
              //   return sum;
              // }, 0);

              const updatedCart = [...prevCartItems, cartData]?.map((item) => {
                const shippingCosts = Array.isArray(item.shipping_cost) ? item.shipping_cost : [];
                const shippingTypeCost = shippingCosts.find((cost) => cost.type === shipmentType);
            
                item.shipping_price = shippingTypeCost ? shippingTypeCost.cost : 0;
                item.total_shipping_price = shippingTypeCost ? shippingTypeCost.cost * (item.qty || 1) : 0;
            
                return item;
              });
              const totalShippingCharges = updatedCart.reduce((sum, item) => sum + item.total_shipping_price, 0);

              setShippingCharges(totalShippingCharges);
              setShippingRate(totalShippingCharges);
              handleOrderUpdate(updatedCart, cartData);
              recalculateTotalsssss(updatedCart, shipmentType,totalShippingCharges)
              return updatedCart;
            });
            setSelectedItem(null)
          } else {
            enqueueSnackbar(res.data.message, { variant: 'warning' })
          }
        })
      } catch (error) {
        console.log('handleAddToOrder error : ', error);
      }finally{
        setTimeout(() => {
          setBlockingOrder(false);
        }, 500);
      }
    };
    const handleOrderUpdate = (updatedCart, cartData) => {
        const existingOrderItemIndex = updatedCart.findIndex(
            (item) => item.barcode === cartData.barcode
        );

        if (existingOrderItemIndex !== -1) {
            const updatedOrderItems = [...updatedCart];
            updatedOrderItems[existingOrderItemIndex].count += selectedItemCount;
            setOrderItems(updatedOrderItems);
            recalculateTotals(updatedOrderItems);
        } else {
            const updatedCartItem = {
                ...cartData,
                count: selectedItemCount,
            };
            const updatedOrderItems = [...updatedCart, updatedCartItem];
            setOrderItems(updatedOrderItems);
            recalculateTotals(updatedOrderItems);
        }
        setSelectedItem(null);
        setSelectedItemCount(1);
    };

    const handleClickOpenSearch = (item) => {
      setBlockingOrder(true);
      try{
        // setSelectedItem(item);
        // console.log(item,'---------------item')
        axios.post(`${REST_API_END_POINT}point-of-sale/get-packages`, {
            user_id: user.id, facility_id: user.facility_id,
            product_id: item.id,
            free_product: 0,
            free: 0,
            parent_product: 0,
            bogo_id: 0,
        })
        .then((res) => {
          if (res.data.status === 1) {
            const data = res.data;
            let product = data.product;
            let all_packages = data.all_packages;
            let finished_products = data.finished_products;

            all_packages = all_packages.map(row => {
                let qty_type = 'Units';
                let quantity = parseFloat(row.quantity).toFixed(2);

                if (product.qty_type === 1) {
                    qty_type = 'Grams';
                    quantity = row.quantity;
                } else if (product.qty_type === 2) {
                    qty_type = 'Lbs';
                    quantity = (parseFloat(row.quantity) / LBS_CONSTANT).toFixed(2);
                }

                row.available = `${quantity} ${qty_type} available in stock`;
                return row;
            });

            finished_products = finished_products?.filter((newFin)=>newFin?.quantity > 0)?.map(row => {
              let qty_type = qty_types[row.variation_type] || 'Units';
              let capacity = row.capacity;

              if (row.variation_type === 2) {
                  if (capacity === 0.125) {
                      capacity = '1/8';
                  } else if (capacity === 0.25) {
                      capacity = '1/4';
                  } else if (capacity === 0.5) {
                      capacity = '1/2';
                  } else {
                      capacity = parseFloat(capacity).toFixed(2);
                  }
              } else {
                  capacity = parseFloat(capacity).toFixed(2);
              }

              // row.available = `${row.variation_name} (${capacity} ${qty_type}) Only ${parseInt(row.quantity)} quantity left in stock`;
              row.available = `Only ${parseInt(row.quantity)} ${qty_type} left in stock`;
              return row;
            });


            setProductSelected(product);
            setAllPackages(all_packages);
            setFinishedProducts(finished_products);
            
            console.log('finished_products-item--',finished_products);
            console.log('finished_products-cartItem-item--',cartItem);

            if (finished_products && finished_products.length > 0) {
              // Find all cart items for the relevant product
              const relatedCartItems = cartItem?.filter((item) =>
                finished_products.some(
                  (product) =>
                    product.product_id === item.id && product.package_id === item.package_id
                )
              );
            
              let isQuantityUpdated = false;
            
              // Try to increment quantity for any valid package already in the cart
              for (const item of relatedCartItems) {
                const product = finished_products.find(
                  (prod) =>
                    prod.product_id === item.id && prod.package_id === item.package_id
                );
            
                if (product) {
                  const newQty = item.qty + 1;
            
                  if (newQty <= product.quantity) {
                    // Increment the quantity if within stock limits
                    handleIncrementItemCount(item.package_id, newQty);
                    setBlockingOrder(false);
                    isQuantityUpdated = true;
                    break; // Exit loop once quantity is updated
                  }
                }
              }
            
              // If no existing package could be incremented, find the next available package
              if (!isQuantityUpdated) {
                const nextPackage = finished_products.find(
                  (pkg) =>
                    pkg.quantity > 0 &&
                    !cartItem.some((item) => item.package_id === pkg.package_id)
                );
            
                if (nextPackage) {
                  // enqueueSnackbar(`Partial stock added. Switching to package ${nextPackage.package_id}.`, { variant: 'warning' });
                  console.log(`Partial stock added. Switching to package ${nextPackage.package_id}.`);
                  handleAddToOrder(nextPackage);
                  setBlockingOrder(false);
                } else {
                  enqueueSnackbar('No more stock available.', { variant: 'error' });
                  setBlockingOrder(false);
                }
              }
            } else {
              enqueueSnackbar('No finished products available', { variant: 'warning' });
              setBlockingOrder(false);
            }
          }
        })
        .catch((err) => {
            console.log(err);
            setBlockingOrder(false);
        });
      }catch(error){
        console.log('Handle click open error : ',error);
        setBlockingOrder(false);
      }
    };

    const handleClickOpen = (item) => {
      setBlockingOrder(true);
      try{
        // console.log(item,'---------------item')
        axios.post(`${REST_API_END_POINT}point-of-sale/get-packages`, {
            user_id: user.id, facility_id: user.facility_id,
            product_id: item.id,
            free_product: 0,
            free: 0,
            parent_product: 0,
            bogo_id: 0,
        })
        .then((res) => {
          if (res.data.status === 1) {
            const data = res.data;
            let product = data.product;
            let all_packages = data.all_packages;
            let finished_products = data.finished_products;

            all_packages = all_packages.map(row => {
                let qty_type = 'Units';
                let quantity = parseFloat(row.quantity).toFixed(2);

                if (product.qty_type === 1) {
                    qty_type = 'Grams';
                    quantity = row.quantity;
                } else if (product.qty_type === 2) {
                    qty_type = 'Lbs';
                    quantity = (parseFloat(row.quantity) / LBS_CONSTANT).toFixed(2);
                }

                row.available = `${quantity} ${qty_type} available in stock`;
                return row;
            });

            finished_products = finished_products?.map(row => {
              let qty_type = qty_types[row.variation_type] || 'Units';
              let capacity = row.capacity;

              if (row.variation_type === 2) {
                  if (capacity === 0.125) {
                      capacity = '1/8';
                  } else if (capacity === 0.25) {
                      capacity = '1/4';
                  } else if (capacity === 0.5) {
                      capacity = '1/2';
                  } else {
                      capacity = parseFloat(capacity).toFixed(2);
                  }
              } else {
                  capacity = parseFloat(capacity).toFixed(2);
              }

              // row.available = `${row.variation_name} (${capacity} ${qty_type}) Only ${parseInt(row.quantity)} quantity left in stock`;
              row.available = `Only ${parseInt(row.quantity)} ${qty_type} left in stock`;
              return row;
            });


            setProductSelected(product);
            setAllPackages(all_packages);
            setFinishedProducts(finished_products);
            
            console.log('finished_products-item--',finished_products[0]);
            console.log('finished_products-cartItem-item--',cartItem);
            if (finished_products?.length > 0) {
              setSelectedItem(item);
              setBlockingOrder(false);
            } else {
              enqueueSnackbar('No finished products available', { variant: 'warning' })
              setBlockingOrder(false);
            }                    
          }
        })
        .catch((err) => {
            console.log(err);
            setBlockingOrder(false);
        });
      }catch(error){
        console.log('Handle click open error : ',error);
        setBlockingOrder(false);
      }
    };

    const handleClose = () => {
        setSelectedItem(null);
    };

    // const handleIncrementItemCount = async (itemName,maximumStock) => {
    //     const newData = cartItem.map(item =>
    //         item.package_id === itemName ? { ...item, qty: item.qty + 1 } : item
    //     );
    //     setCartItem(newData);
    //     await recalculateTotals(newData);
    // };

    const handleIncrementItemCount =  (itemName, maximumStock) => {
        const newData = cartItem.map(item => {
          if (item.package_id === itemName) {
            const newQty = item.qty + 1;
            if (newQty <= maximumStock) {
              const shippingCosts = Array.isArray(item.shipping_cost) ? item.shipping_cost : [];
              const shippingTypeCost = shippingCosts.find((cost) => cost.type === shipmentType);
      
              return {
                ...item,
                qty: newQty,
                shipping_price: shippingTypeCost ? shippingTypeCost.cost : 0,
                total_shipping_price: shippingTypeCost ? shippingTypeCost.cost * newQty : 0,
              };
            } else {
              enqueueSnackbar("Cannot add more items, maximum stock reached.", { variant: 'warning' })
              return item;
            }
          }
          return item;
        });
    
        const calculatedShippingCharges = newData.reduce((sum, item) => {
          return sum + (item.total_shipping_price || 0);
        }, 0);
        setShippingCharges(calculatedShippingCharges);
        setShippingRate(calculatedShippingCharges);
    
        setCartItem(newData);
        // await recalculateTotals(newData);
        recalculateTotalsssss(newData,shipmentType, calculatedShippingCharges)
    };
    
    const handleDecrementItemCount =  (itemName) => {
      const newData = cartItem.map((item) => {
        if (item.package_id === itemName && item.qty > 1) {
          const newQty = item.qty - 1;
          const shippingCosts = Array.isArray(item.shipping_cost) ? item.shipping_cost : [];
          const shippingTypeCost = shippingCosts.find((cost) => cost.type === shipmentType);
    
          return {
            ...item,
            qty: newQty,
            shipping_price: shippingTypeCost ? shippingTypeCost.cost : 0,
            total_shipping_price: shippingTypeCost ? shippingTypeCost.cost * newQty : 0,
          };
        }
        return item;
      });
      const calculatedShippingCharges = newData.reduce((sum, item) => {
        return sum + (item.total_shipping_price || 0);
      }, 0);
      setShippingCharges(calculatedShippingCharges);
      setShippingRate(calculatedShippingCharges);
      setCartItem(newData);
      // await recalculateTotals(newData);
      // recalculateTotalsssss(newData)
      recalculateTotalsssss(newData,shipmentType, calculatedShippingCharges)
    };

        
    // const handlePriceChange = async (itemName, newPrice) => {
    //     const updatedPrice = parseFloat(newPrice) || 1;
    
    //     console.log("updatedPriceupdatedPriceupdatedPrice",updatedPrice)
    //     let taxAmount =(updatedPrice * 5/100).toFixed(2) || 0
    //     const newData = cartItem.map(item => {
    //         if (item.package_id === itemName) {
    //             return { ...item, unit_price: updatedPrice ,price_add: true ,unit_sales_tax:taxAmount};
    //         }
    //         return item;
    //     });
    //   console.log(newData,'-------------newData')
    //   setCartItem(newData);
    // //   await recalculateTotals(newData);
    //   recalculateTotalsssss(newData);
    // };

    const clearDiscounts = () => {
      setCartTotals((prevCartTotals) => ({
        ...prevCartTotals,
        auto_apply_discount: 0
      }));
    
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        coupon_discount: 0,
        coupon_code: ""
      }));
    };

    const handlePriceChange = (itemName, newPrice, tax_rate = 0) => {
      clearDiscounts();
      // Sanitize the price input
      let sanitizedPrice = newPrice.replace(/[^\d.]/g, ''); // Allow only digits and decimal points
      let updatedPrice = parseFloat(sanitizedPrice) || 0; // Convert to float or default to 0
      const taxAmount = (Number(updatedPrice) * Number(tax_rate) / 100).toFixed(2); //
      // const taxAmount = (updatedPrice * Number(tax_rate) / 100).toFixed(2); 
      // const taxAmount =tax_rate===0 ? 0 : (updatedPrice * 5 / 100).toFixed(2); 
      console.log('handlePriceChange-taxAmount--',taxAmount);
      console.log('handlePriceChange-tax_rate--',tax_rate);
      console.log('handlePriceChange-updatedPrice--',updatedPrice);
      const newData = cartItem.map(item => {
          if (item.package_id === itemName) {
              return {
                  ...item,
                  unit_price: updatedPrice, // Update price
                  price:parseFloat(updatedPrice)+parseFloat(taxAmount),
                  unit_sales_tax: taxAmount, // Update tax amount
                  sales_tax:taxAmount,
                  price_add: true, // Flag indicating price has been updated
              };
          }
          return item;
      });
  
      setCartItem(newData); // Set updated cart items
      recalculateTotalsssss(newData); // Recalculate totals
  };
  
  

    // const recalculateTotalsssss = (currentCart) => {
    //     const updatedCartTotals = {
    //         subtotal: 0,
    //         discount: 0,
    //         tax: 0,
    //         total: 0,
    //         auto_apply_discount: 0,
    //         total_due: 0
    //     };
    
    //     currentCart?.forEach((item) => {
    //         // Apply variations if `price_add` is false, otherwise use the changed `unit_price`
    //         if (!item.price_add) {
    //             let variationData = item?.variations?.find(
    //                 (varItem) => item.qty >= varItem?.min_value && item.qty <= varItem?.max_value
    //             );
    
    //             if (!variationData) {
    //                 variationData = item?.variations?.reduce((prev, current) => {
    //                     return current.max_value > prev.max_value ? current : prev;
    //                 }, { max_value: -Infinity });
    //             }
                
    
    //             // Use the variation price if price_add is false
    //             item.unit_price = variationData?.unit_price || 0;
    //             item.unit_sales_tax = variationData?.tax || 0;
                
    //         }
    
    //         // Calculate tax and other amounts
            
    //         // item.tax = item.exise_tax + item.unit_sales_tax;
    //         item.tax = (item.exise_tax + item.unit_sales_tax) * item.qty
    //         updatedCartTotals.tax += item.tax;
    
    //         updatedCartTotals.subtotal += parseFloat((item.unit_price * item.qty).toFixed(2));
    //         updatedCartTotals.discount += (
    //             parseFloat(item.discount) +
    //             parseFloat(item.coupon_discount) +
    //             parseFloat(item.totalOff)
    //         );
    
    //         updatedCartTotals.total += parseFloat((item.unit_price * item.qty - item.discount - item.coupon_discount + item.tax)?.toFixed(2));
    //     });
    
    //     // Update total_due based on currencyRate and set the new totals
    //     setCartTotals({
    //         ...updatedCartTotals,
    //         total_due: updatedCartTotals.total * currencyRate,
    //     });
    // };
    

    const recalculateTotalsssss = (currentCart, shippingType, shippingRte,orderPoints, couponData) => {
      // Initialize an object to store the totals
      console.log('couponData---',couponData)
      console.log('shippingRte---',shippingRte)
      // const shippingRt = shippingRte !== undefined ? shippingRte : shippingRate || 0;
      const shippingRt = shippingRte ?? (shippingRate || 0);
      const shipping = shippingType || shipmentType || 3;
      const updatedCartTotals = {
          subtotal: 0, // Total amount before discounts and taxes
          discount: 0,  // Total discounts applied
          tax: 0,      // Total tax amount
          total: 0,    // Final total after applying discounts and adding taxes
          auto_apply_discount: 0, // Total of any auto-applied discounts
          total_due: 0 // Total amount due after all calculations
      };
  
      // Iterate over each item in the current cart
      currentCart?.forEach((item) => {
          // If `price_add` is false, use variations to determine the price
          if (!item.price_add) {
            let variationData = item?.variations?.find((varItem) => customerData.customer_type == varItem?.customer_type);
            console.log('variationData2----',variationData)
              // let variationData = item?.variations?.find(
              //     (varItem) => item.qty >= varItem?.min_value && item.qty <= varItem?.max_value
              // );
  
              // // If no specific variation is found, get the maximum variation
              // if (!variationData) {
              //     variationData = item?.variations?.reduce((prev, current) => {
              //         return current.max_value > prev.max_value ? current : prev;
              //     }, { max_value: -Infinity });
              // }
  
              // Set the unit price and sales tax based on variation
              const packageTotalUnitCost = parseFloat(item?.package_total_unit_cost) || 0;
              const profit = parseFloat(variationData?.profit) || 0;
              const finalPrice = (packageTotalUnitCost + profit).toFixed(2);
  
              // item.unit_price = parseFloat(finalPrice);
              // // item.unit_price = variationData?.unit_price || 0;
              item.variation_id = variationData?.variation_id || 0;
              // // item.unit_sales_tax = variationData?.tax || 0;
            
          }
  
          // Calculate the tax for the item (total sales tax)
          item.tax = (item.unit_sales_tax || 0 * item.qty) || 0;
  
          // Add the item's tax to the updated total tax
          updatedCartTotals.tax += (Number(item.tax) * item.qty);
  
          // Update the subtotal
          updatedCartTotals.subtotal += parseFloat((item.unit_price * item.qty).toFixed(2));
  
          // Update the total discount based on item discounts
          updatedCartTotals.discount += (
              parseFloat(item.discount) +
              parseFloat(item.coupon_discount) +
              parseFloat(item.totalOff)
          );
  
          // Calculate the total for the current item (price - discount + tax)
          // updatedCartTotals.total += parseFloat((Number(item.unit_price) * Number(item.qty) - Number(item.discount) - Number(item.coupon_discount) + Number(item.tax) * Number(item.qty))?.toFixed(2));
          updatedCartTotals.total += parseFloat(
            (
              Number(item.unit_price) * Number(item.qty) - 
              Number(item.discount) - 
              Number(item.coupon_discount) + 
              Number(item.tax) * Number(item.qty)
            ).toFixed(2)
          );          
      });
      console.log('shippingRate--2', shippingRt)
      let initialDiscount = paymentData.point_discount > 0 ? parseFloat(paymentData.point_discount) : parseFloat(orderPoints?.point_discount) || 0;
      let initialCouponDiscount = paymentData.coupon_discount > 0 ? parseFloat(paymentData.coupon_discount) : parseFloat(couponData?.couponDiscount) || 0;
      if(couponData){
        setPaymentData((prev) => ({
          ...prev,
          coupon_discount: parseFloat(couponData?.couponDiscount)||0,
          coupon_code:couponData?.couponCode || ''
        }))
      }
      updatedCartTotals.discount += initialDiscount;
      updatedCartTotals.discount += initialCouponDiscount;
      
      // Subtract the total of both discounts from the total
      updatedCartTotals.total -= (initialDiscount + initialCouponDiscount);      
      updatedCartTotals.total += parseFloat(parseFloat(shippingRt).toFixed(2)) || 0;
      console.log('updatedCartTotals.total---',updatedCartTotals.total);
      console.log('updatedCartTotals.shippingRt---',shippingRt);
      // Calculate the total due based on currency rate and set the new totals
      setCartTotals({
          ...updatedCartTotals,
          total_due: updatedCartTotals.total * currencyRate, // Adjust the total due based on the currency rate
      });
  };
  

    const calculateSubtotal = () => {
        let subtotal = 0;
        orderItems.forEach((orderItem) => {
            subtotal += orderItem.price * orderItem.count;
        });
        return subtotal.toFixed(2);
    };

    const calculateTaxes = () => {
        const subtotal = calculateSubtotal();
        const taxRate = 0.06;
        const taxes = subtotal * taxRate;
        return taxes.toFixed(2);
    };

    const calculateGeneralfee = () => {
        const uniqueProducts = new Set();
        orderItems.forEach((orderItem) => {
            uniqueProducts.add(orderItem.name);
        });
        const productCount = uniqueProducts.size;
        const generalfee = productCount * 15;
        return generalfee.toFixed(2);
    };

    const calculateMetricFee = () => {
        const uniqueProducts = new Set();
        orderItems.forEach((orderItem) => {
            uniqueProducts.add(orderItem.name);
        });
        const productCount = uniqueProducts.size;
        const metricFee = productCount * 10;
        return metricFee.toFixed(2);
    };

    const handleTabChange = (event, newValue) => {
        setActiveFirstTab(newValue);
    };


    const cartItemIds = new Set(cartItem?.map(item => item?.package_id));
    const filteredFinishedProducts = finishedProduct?.filter(item => !cartItemIds?.has(item.package_id));
    const filteredAllPackages = allPackages?.filter(item => !cartItemIds?.has(item.package_id));

  //   const placeOrderFinal = () => {
  //     setBlocking(true); // Show loading state while processing the order
  
  //     // Prepare the data to send to the API
  //     const checkoutMode = 'Express'; // Adjust this based on your needs
  //     const bogoCode = getCookie('bogo_code', 'string'); // Example for coupons or promotions
  
  //     let updatedCart = cartItem.map((row) => {
  //         row.unit_price *= currencyRate;
  //         row.price *= currencyRate;
  //         row.discount *= currencyRate;
  //         row.exise_tax *= currencyRate;
  //         row.sales_tax *= currencyRate;
  //         row.tax *= currencyRate;
  //         row.free_discount *= currencyRate;
  //         row.coupon_discount *= currencyRate;
  //         return row;
  //     });
  
  //     const flag = updatedCart.some((row) => row.flag === 1); // Check if any item exceeds a limit
  
  //     const formData = new FormData();
  //     if (readyToPlaceOrder) {
  //         formData.append('order_id', order_id);
  //         formData.append('ready_for_order_sale', true);
  //         formData.append('is_pickup', 1);
  //     } else {
  //         formData.append('is_pickup', isPickUp ? 1 : 0);
  //     }
  
  //     // Append all necessary fields to `formData` 
  //     formData.append('order_id', order_id);
  //     formData.append('first_name', customerData.vendor_name);
  //     formData.append('email_id', customerData.email_id);
  //     formData.append('phone', customerData.phone);
  //     formData.append('bill_address', customerData.vendor_address);
  //     formData.append('ship_address', customerData.vendor_address);
  //     formData.append('facility_id', user.facility_id);
  //     formData.append('user_id', user.id);
  //     formData.append('customer_id', customer_id);
  //     formData.append('bogo_code', bogoCode);
  //     formData.append('currency_code', currencyCode);
  //     formData.append('currency_rate', currencyRate);
  //     formData.append('checkout_mode', checkoutMode);
  //     formData.append('delivery_date', deliveryDate);
  //     formData.append('payment_data', JSON.stringify(paymentData));
  //     formData.append('hypur_transaction_id', hypurTransactionId);
  //     formData.append('cart_products', JSON.stringify(updatedCart));
  //     formData.append('auto_apply_discount', cartTotals.auto_apply_discount * currencyRate);
  //     formData.append('total_tax', cartTotals.tax * currencyRate);
  //     formData.append('thc_medical', cartTotals.thc_medical);
  //     formData.append('thc_edible', cartTotals.thc_edible);
  //     formData.append('thc_non_edible', cartTotals.thc_non_edible);
  //     formData.append('total_thc', parseFloat((cartTotals.thc_medical + cartTotals.thc_edible + cartTotals.thc_non_edible).toFixed(2)));
  
  //     // Check if no limit is exceeded
  //     if (flag == false) {
  //         // Send the update order API request
  //         axios.post(`${REST_API_END_POINT}point-of-sale/update-order-data`, formData)
  //             .then((res) => {
  //                 const data = res.data;
  //                 if (data.status === 1) {
  //                     setCustomerId(null);
  //                     setPaymentData({});
  //                     setChangeData(null);
  //                     setBlocking(false);
  //                     setOrderId(data.order_id);
  
  //                     // Navigate or perform another action after a successful update
  //                     enqueueSnackbar('The Order has been updated successfully', { variant: 'success' });
  //                     navigate(PATH_DASHBOARD.pov.PaymentProcess, { state: { orderId: data?.order_id, cartTotals: cartTotals } });
  //                 } else {
  //                     enqueueSnackbar(data.msg, { variant: 'error' });
  //                     setBlocking(false);
  //                 }
  //             })
  //             .catch((e) => {
  //                 console.error("Update failed, Error ", e);
  //                 setBlocking(false);
  //             });
  //     } else {
  //         enqueueSnackbar("Daily limit exceed", { variant: 'error' });
  //         setBlocking(false);
  //     }
  // };


  const placeOrderFinal = () => {
    setBlocking(true); 

    
    const checkoutMode = 'Express'; 
    const bogoCode = getCookie('bogo_code', 'string'); 

    let updatedCart = cartItem.map((row) => {
        row.unit_price *= currencyRate;
        row.price *= currencyRate;
        row.discount *= currencyRate;
        row.exise_tax *= currencyRate;
        row.sales_tax *= currencyRate;
        row.tax *= currencyRate;
        row.free_discount *= currencyRate;
        row.coupon_discount *= currencyRate;
        return row;
    });

    // const flag = updatedCart.some((row) => row.flag === 1); // Check if any item exceeds a limit
      const flag = false
    // Create an object to send as the payload
    const formData = {
        order_id:order_id,
        ready_for_order_sale: readyToPlaceOrder ? true : undefined,
        is_pickup: readyToPlaceOrder ? 1 : (isPickUp ? 1 : 0),
        first_name: customerData.vendor_name,
        email_id: customerData.email_id,
        tg_number: customerData.tg_number,
        signal_number: customerData.signal_number,
        phone: customerData.phone,
        bill_address: customerData.vendor_address,
        ship_address: customerData.vendor_address,
        facility_id: user.facility_id,
        user_id: user.id,
        customer_id: customer_id,
        bogo_code: bogoCode,
        currency_code: currencyCode,
        currency_rate: currencyRate,
        checkout_mode: checkoutMode,
        isShipping:isShipping,
        shipmentType:shipmentType,
        shippingRate:shippingRate,
        delivery_date: deliveryDate,
        payment_data: paymentData, // Assuming this is already an object
        hypur_transaction_id: hypurTransactionId,
        cart_products: updatedCart, // Already an array or object
        auto_apply_discount: cartTotals.auto_apply_discount * currencyRate,
        total_tax: cartTotals.tax * currencyRate,
        thc_medical: cartTotals.thc_medical,
        thc_edible: cartTotals.thc_edible,
        thc_non_edible: cartTotals.thc_non_edible,
        total_thc: parseFloat((cartTotals.thc_medical + cartTotals.thc_edible + cartTotals.thc_non_edible).toFixed(2))
    };

    // Check if no limit is exceeded
    if (flag === false) {
        // Send the update order API request
        axios.post(`${REST_API_END_POINT}point-of-sale/update-order-data`, formData)
            .then((res) => {
                const data = res.data;
                if (data.status === 1) {
                    setCustomerId(null);
                    setPaymentData({});
                    setChangeData(null);
                    setBlocking(false);
                    setOrderId(data.order_id);

                    // Navigate or perform another action after a successful update
                    enqueueSnackbar('The Order has been updated successfully', { variant: 'success' });
                    navigate(PATH_DASHBOARD.pov.PaymentProcess, { state: { orderId: data?.order_id, cartTotals: cartTotals } });
                } else {
                    enqueueSnackbar(data.msg, { variant: 'error' });
                    setBlocking(false);
                }
            })
            .catch((e) => {
                console.error("Update failed, Error ", e);
                setBlocking(false);
            });
    } else {
        // enqueueSnackbar("Daily limit exceed", { variant: 'error' });
        setBlocking(false);
    }
};


const handleShipmentTypeChange = (event) => {
  const selectedType = Number(event.target.value);
  setShipmentType(selectedType);
  // Recalculate the shipping charges whenever the shipment type changes
  setCartItem(prevCartItems => {
    // const uniqueItems = new Map();
    // prevCartItems.forEach(item => {
    //   if (!uniqueItems.has(item.id)) {
    //     uniqueItems.set(item.id, item);
    //   }
    // });

    // const totalShippingCharges = Array.from(uniqueItems.values()).reduce((sum, item) => {
    //   if (item.shipping_cost && Array.isArray(item.shipping_cost)) {
    //     const shippingTypeCost = item.shipping_cost.find(
    //       cost => cost.type == selectedType
    //     );
    //     return sum + (shippingTypeCost ? shippingTypeCost.cost : 0);
    //   }
    //   return sum;
    // }, 0);

      const updatedCart = prevCartItems.map((item) => {
        const shippingCosts = Array.isArray(item.shipping_cost) ? item.shipping_cost : [];
        const shippingTypeCost = shippingCosts.find((cost) => cost.type === selectedType);
  
        item.shipping_price = shippingTypeCost ? shippingTypeCost.cost : 0;
        item.total_shipping_price = shippingTypeCost ? shippingTypeCost.cost * (item.qty || 1) : 0;
  
        return item;
      });
  
      // Calculate total shipping charges based on `total_shipping_price`
      const totalShippingCharges = updatedCart.reduce((sum, item) => sum + item.total_shipping_price, 0);

    setShippingCharges(totalShippingCharges);
    setShippingRate(totalShippingCharges);
    recalculateTotalsssss(prevCartItems, selectedType, totalShippingCharges );
    return prevCartItems;
  });
};

    const placeOrder = () => {
        if (cartItem.length) {
          const itemWithZero = cartItem?.some((newItem) => newItem.unit_price <= 0)
          console.log('cartItemcartItem-itemWithZero--',itemWithZero);
          if(itemWithZero){
            enqueueSnackbar('Order cannot be placed with items that have no price', { variant: 'error' })
            return;
          }
            let proceed = true;
            for (let row of cartItem) {
                if (row.max_quantity < row.qty) {
                    proceed = false;
                    break;
                }
            }
            if (proceed) {
                let facilityId = user.facility_id;
                // facilityId = dataCrypto.decrypt(facilityId);
                if (facilityId === '10') {
                    window.location = "/app/point-of-sale/place-order";
                } else {
                    placeOrderFinal();
                }
            }
            else {
                enqueueSnackbar("Please check the quantity in cart", { variant: 'warning' })
            }
        } else {
            // enqueueSnackbar("There are no products in the cart",{variant:'error'})
            setConfirmDialogOpen(true);
            // .then((willDelete)=>{
            //     if(willDelete){
            //         axios.post(`${REST_API_END_POINT}point-of-sale/checkout_customer`, {customer_id})
            //         .then((res)=>{
            //             setCartItem(null)
            //             navigate(PATH_DASHBOARD.pov.CustomerLookup)
            //         })
            //         .catch(e=>{
            //             console.log("Addition failed, Error ", e);
            //         })
            //     }
            //         else{
            //             enqueueSnackbar("Continue ordering!",{variant:'error'});
            //         }
            // })
        }
    }

    const handleCheckoutConfirm = () => {
        axios.post(`${REST_API_END_POINT}point-of-sale/checkout_customer`, { customer_id })
            .then((res) => {
                setCartItem(null)
                navigate(PATH_DASHBOARD.pov.CustomerLookup)
            })
            .catch(e => {
                console.log("Checkout failed, Error: ", e);
                enqueueSnackbar("Checkout failed", { variant: 'error' });
            });
        setConfirmDialogOpen(false);
    };

    const handleDialogCancel = () => {
        enqueueSnackbar("Continue ordering!", { variant: 'info' });
        setConfirmDialogOpen(false);
    };

const calculatecartTotal=(points)=>{
  setCartTotals((prev) => ({
    ...prev,
    subtotal: parseFloat(prev.subtotal),
    discount: parseFloat(prev.discount) + parseFloat(points.discount),
    total: parseFloat(prev.total) - parseFloat(points.discount),
  }))
}
    return (
      // <Page title={user?.facility_name || 'Get Go'}>
      <Page title="Adjust Order">
        <Grid
          container
          xs={12}
          md={12}
          spacing={1}
          mt={1}
          sx={{ pl: { xs: 1, sm: 1, md: 3 } }}
        >
          {/* <Grid container spacing={2} > */}
          <Grid item xs={12} md={1.8} spacing={2} borderRadius={'10px'}>
            <Card
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #000',
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
              }}
            >
              <Scrollbar sx={{ height: '595px' }}>
                <Stack
                  container
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100px',
                    p: 1.5,
                    justifyContent: 'center',
                  }}
                  onClick={() => handleCategoryFilter(null)}
                >
                  <Stack
                    direction={'column'}
                    sx={{
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: 0.8,
                      background:
                        selectedCategory_id === null 
                          ? 'linear-gradient(to left, #000,#000,#000,#000)'
                          : 'common.white',
                      color: selectedCategory_id === null ? 'common.white' : 'black',
                      '&:hover': {
                        background: 'linear-gradient(to left, #000,#000,#000,#000)',
                        color: 'white',
                      },
                    }}
                  >
                    <Stack
                      className="mdi--marijuanaa"
                      sx={{ fontSize: '1.5rem', mb: 0.6 }}
                    />
                    <Tooltip title="All Categories">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          maxWidth: 130,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        All Categories
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Divider sx={{ borderColor: '#000' }} />
                {categories.map((data, index) => (
                  <div key={index}>
                    <Stack
                      container
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100px',
                        p: 1.5,
                        justifyContent: 'center',
                      }}
                      onClick={() => handleCategoryFilter(data.category_id)}
                    >
                      <Stack
                        direction={'column'}
                        sx={{
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          borderRadius: 0.8,
                          background:
                            selectedCategory_id === data.category_id
                              ? 'linear-gradient(to left, #000,#000,#000,#000)'
                              : 'common.white',
                          color:
                            selectedCategory_id === data.category_id
                              ? 'common.white'
                              : 'black',
                          '&:hover': {
                            background: 'linear-gradient(to left, #000,#000,#000,#000)',
                            color: 'white',
                          },
                        }}
                      >
                        <Stack
                          className="mdi--marijuanaa"
                          sx={{ fontSize: '1.5rem', mb: 0.6 }}
                        />
                        <Tooltip title={data.name}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              maxWidth: 130,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {data.name}
                          </Typography>
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <Divider sx={{ borderColor: '#000' }} />
                  </div>
                ))}
              </Scrollbar>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} spacing={2}>
            <Grid
              sx={{
                display: 'flex',
                bgcolor: 'transparent',
                borderRadius: '15px',
                p: 1,
                mx: '8px',
                border: '1px solid #000',
              }}
            >
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Scan QR Code or Search…"
                disabled={blockingOrder}
                startAdornment={
                  <InputAdornment position="start">
                    <Search sx={{ color: 'black', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                sx={{
                  mr: 1,
                  fontWeight: 'fontWeightBold',
                  '&::placeholder': { color: 'black' },
                  input: {
                    color: 'black',
                    '&::placeholder': {
                      color: 'black',
                    },
                  },
                }}
                inputRef={inputRef}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                bgcolor: 'transparent',
                borderRadius: '15px',
                p: 1,
                mx: '8px',
              }}
            >
              <Typography>Displaying products with {customerData?.customer_type_name} variations.</Typography>
            </Grid>
            <Scrollbar sx={{ height: '534px', mt: 1, pl: 1, pr: 1 }}>
              <Grid container spacing={2} mt={0.3} sx={{ textAlign: 'center' }}>
                {loadingproducts ? (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ width: '100%', textAlign: 'center', mt: 5 }}
                    >
                      Please wait...
                    </Typography>
                    <Box sx={{ width: '100%', justifyContent: 'center' }}>
                      <Stack className="svg-spinners--6-dots-rotate"></Stack>
                    </Box>
                  </>
                ) : paginatedItems.length === 0 ? (
                  <Typography
                    variant="h6"
                    sx={{ width: '100%', textAlign: 'center', mt: 5 }}
                  >
                    No available products
                  </Typography>
                ) : (
                  paginatedItems?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={6}
                      md={3}
                      sm={3}
                      onClick={() => {
                        if (blockingOrder) return;
                        handleClickOpen(item);
                      }}
                    >
                      <Card
                        sx={{
                          cursor: 'pointer',
                          borderRadius: '0px',
                          backgroundColor: 'transparent',
                          border: '1px solid #000',
                          backdropFilter: 'blur(8px)',
                          WebkitBackdropFilter: 'blur(8px)',
                        }}
                      >                  
                        <CardMediaStyle1>
                          <CoverImgStyle
                            alt="cover"
                            src={
                              item.thumbnail && item.thumbnail != 'default.jpg'
                                ? item.thumbnail
                                : Logo
                            }
                          />
                        </CardMediaStyle1>
                        <Divider sx={{ borderColor: '#000' }} />
                        <Tooltip title={item.name}>
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{
                              mt: 1.5,
                              maxWidth: 180,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              pr: 2,
                              pl: 2,
                              textAlign: 'center',
                              color: 'text.primary',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="caption"
                          align="center"
                          sx={{
                            // mb: 1.2,
                            mt: 2,
                            maxWidth: 180,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            pr: 2,
                            pl: 2,
                            textAlign: 'center',
                            color: '#d50000',
                            '&:hover': { color: 'primary.main' },
                          }}
                        >
                          {`(${item.product_stock} Qty)`}
                        </Typography>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    disabled={
                      item.type === 'start-ellipsis' ||
                      item.type === 'end-ellipsis'
                    } // Disable interaction for ellipsis
                    style={
                      item.type === 'start-ellipsis' ||
                      item.type === 'end-ellipsis'
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    classes={{
                      selected: classes.active,
                    }}
                  />
                )}
              />
              <Modal open={!!selectedItem} handleClose={handleClose} onClose={handleClose}
                            modalTitle={'Choose Finished Product - '+selectedItem?.name}  >
                            <Divider />
                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Tabs
                                    value={activeFirstTab}
                                    onChange={handleTabChange}
                                    aria-label="product tabs"
                                >
                                    <Tab label="All Packages" />
                                    <Tab label="Finished Products" />
                                </Tabs>
                            </Box> */}
                            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                            {(filteredFinishedProducts && filteredFinishedProducts?.length > 0 && filteredFinishedProducts.filter(newItem => newItem.quantity > 0)?.length > 0) ?
                                <Table sx={{ my: 3, whiteSpace: 'nowrap' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Barcode</TableCell>
                                            <TableCell align="left">Available</TableCell>
                                            <TableCell align="left">Unit Cost</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {/* {selectedItem && activeFirstTab === 0 && (
                                        <TableBody>
                                            {filteredAllPackages?.map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {data.barcode}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip title={data.available}>
                                                            <span style={{
                                                                display: 'block',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '150px'
                                                            }}
                                                            >{data.available}</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    
                                                    <TableCell align="left">
                                                        
                                                        <Button variant='contained'
                                                            onClick={() => { handleAddToOrder(data) }} >Add <Add />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )} */}
                                    {selectedItem && (
                                        <TableBody>
                                            {filteredFinishedProducts?.filter((newItem)=>newItem?.quantity > 0)?.map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {data.barcode}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Tooltip title={data.available}>
                                                            <span style={{
                                                                display: 'block',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '160px'
                                                            }}
                                                            >{data.available}</span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {data.package_total_unit_cost || 0}
                                                    </TableCell>
                                                   
                                                    <TableCell align="left">
                                                        <Button variant='contained'
                                                         disabled={selectedItem ? false : true}
                                                            onClick={() => {
                                                              setSelectedItem(null);
                                                              handleAddToOrder(data)
                                                            }} >Add <Add />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  No more packages available
                                </div>
                              }
                            </TableContainer>
                            <Divider sx={{ my: 3 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={handleClose} variant='outlined'>Back</Button>
                            </Box>
                        </Modal>
            </Scrollbar>
            {blockingOrder && (
              <Box
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right:0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  zIndex: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h5" sx={{ color: 'black' }}>
                    Processing...
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack className="svg-spinners--6-dots-rotate"></Stack>
                  </Box>
                </Stack>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                height: 'fit-content',
                backgroundColor: 'transparent',
                border: '1px solid #000',
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
                width:'100%'
              }}
            >
              <CardHeader
                align={'center'}
                style={{width:'100%'}}
                title={
                  <Stack
                  direction={{xs:'column',md:'row'}}
                  alignContent={'center'}
                  alignItems={'center'}
                  spacing={1.5}
                  justifyContent={'space-between'}
                  sx={{width:'100%'}}
                  >
                    <Typography variant="h6">Order</Typography>
                    {!addedRedeem && (
                    <Badge
                      badgeContent={pricePoints?.length}
                      showZero
                      fullWidth
                      color="info"
                      overlap="circular"
                      sx={{
                        '.css-k6y4ub-MuiBadge-badge': {
                          height: 'fit-content',
                          width: 'fit-content',
                          padding: '6px 6px',
                          top: '10%',
                          right: { xs: '3%', sm: '6%', md: '6%' },
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={handleOpenCouponModal}
                        fullWidth
                        sx={{
                          mx: 1,
                          position: 'relative',
                        }}
                        startIcon={<CardGiftcard />}
                      >
                        Gift Cards
                      </Button>
                    </Badge>
                    )}
                    <Badge
                      badgeContent={cartItem?.length}
                      color="error"
                      overlap="circular"
                      sx={{
                        '.css-lbsn5-MuiBadge-badge': {
                          height: 'fit-content',
                          width: 'fit-content',
                          padding: '6px 6px',
                          top: '10%',
                          right: { xs: '3%', sm: '6%', md: '6%' },
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        fullWidth
                        sx={{
                          mx: 1,
                          position: 'relative',
                        }}
                        startIcon={<ShoppingCart />}
                      >
                        Items in cart
                      </Button>
                    </Badge>
                  </Stack>
                }
                sx={{ mb: '10px', mt: '-10px' }}
              />
               <Modal open={openCouponCode} 
              handleClose={handleCloseCouponModal} 
              modalTitle={'Gift Cards'}>
                <GiftCouponCardModal 
                calculatecartTotal={calculatecartTotal}
                pricePoints={pricePoints}
                setOpenCouponCode={setOpenCouponCode}
                setPaymentData={setPaymentData}
                setAddRedeem={setAddRedeem}
                />
                </Modal>
              <Divider sx={{ borderColor: '#000' }} />
              <Box sx={{ height: '428px', py: '5px' }}>
                {editOrderLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%', // Ensures it occupies the full height of the parent container
                      mt: 5, // Keeps some margin if necessary
                    }}
                  >
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                      Please wait...
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                      }}
                    >
                      <Stack className="svg-spinners--6-dots-rotate" />
                    </Box>
                  </Box>
                  ) : (
                cartItem?.length !== 0 ? (<Scrollbar>
                  {/* {cartItem?.map((orderItem, index) => ( */}
                  {[...cartItem].reverse().map((orderItem, index) => (
                    <>
                      <Stack spacing={3} sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }}>
                        <Stack
                          direction="row"
                          sx={{
                            // backgroundImage:
                            //   'linear-gradient(12deg, #000000 20%, #2B2B2B 40%, #434343 60%, #000 100%)',
                            ...getGradientStyles(customerData?.customer_type),
                            p: 2,
                            color: customerData?.customer_type ? 'common.black' :'common.white',
                            borderRadius: 1,
                            width: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            '&:before, &:after': {
                              content: '""',
                              position: 'absolute',
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#fff',
                              // ...getGradientStyles(customerData?.customer_type),
                              border: '1px solid #000',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              borderRadius: '50%',
                            },
                            '&:before': {
                              left: '-10px',
                            },
                            '&:after': {
                              right: '-10px',
                            },
                          }}
                          spacing={2}
                        >
                          {/* <Box component={'img'}
                                                        sx={{ height: 90, width: 60, borderRadius: '4px',objectFit:'cover', }}
                                                        src={(orderItem.thumbnail && orderItem.thumbnail!='default.jpg') ? orderItem.thumbnail : 'https://i.gifer.com/84fx.gif'}
                                                        alt="Image"
                                                    /> */}
                          <Box sx={{ flexGrow: 0.5, width: '100%' }}>
                            <Stack
                              direction="row"
                              justifyContent={'space-between'}
                            >
                              <Stack direction={'column'}>
                                <Stack direction={'row'} spacing={2}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ mb: 0.5 }}
                                  >
                                    {orderItem.name}
                                  </Typography>
                                  {/* {cartTotals.auto_apply_discount > 0 &&
                                      <Typography variant='caption' color={'success.main'} 
                                      sx={{fontSize:'12px',whiteSpace:'nowrap'}}>
                                      (Auto Applied Coupon Code)</Typography>
                                  } */}
                                </Stack>
                                <Grid container direction="row" justifyContent="space-around">
  <Grid item xs={12} md={12}>
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      {/* Price */}
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          Price:
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          type="number"
          size="small"
          sx={{ mb: '4px' }}
          variant="standard"
          value={orderItem.unit_price}
          onChange={(e) => handlePriceChange(orderItem.package_id, e.target.value, orderItem.tax_rate)}
          InputProps={{
            inputProps: { min: 1 },
            sx: {
              color: customerData?.customer_type ? 'common.black' :'common.white',
              width: '80px',
              paddingBottom: '4px',
              fontSize: '12px',
            },
          }}
        />
      </Grid>
    </Grid>
    
    
  </Grid>
  {/* Tax */}
  <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          Tax:
        </Typography>
      </Grid>
        
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          ${orderItem.unit_sales_tax || 0.0}
        {/* {orderItem.unit_price * 5 / 100} */}
        </Typography>
      </Grid>
    </Grid>

    {/* Amount */}
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          Amount:
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <Typography sx={{ fontSize: '12px' }} align="left">
          ${parseFloat((orderItem.unit_price * orderItem.qty ) +( Number(orderItem.unit_sales_tax) * orderItem.qty)).toFixed(2) || 0.0}
        </Typography>
      </Grid>
    </Grid>
</Grid>

                                {/* <Typography sx={{ mt: 0.6, }} variant='subtitle2' align='left'>Select Coupon: </Typography> */}
                                {/* <Autocomplete
                                                                sx={{
                                                                    mt: 0.7,
                                                                    mb: 1.1,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        color: 'common.white',
                                                                        width: '180px',
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#fff',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#fff',
                                                                        },
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        color: 'common.white',
                                                                    },
                                                                }}
                                                                fullWidth
                                                                size="small"
                                                                options={Coupons}
                                                                getOptionLabel={(option) => option.label}
                                                                value={selectedCoupon}
                                                                onChange={(event, newValue) => setSelectedCoupon(newValue)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        size="small"
                                                                        fullWidth
                                                                        {...params}
                                                                        placeholder="Coupon"
                                                                    />
                                                                )}
                                                            /> */}
                                {/* <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#d50000',
                                    fontWeight: 700,
                                  }}
                                >
                                  Only {orderItem.real_quantity - orderItem.qty}{' '}
                                  unit is Left in stock
                                </Typography> */}
                                {(!orderItem.price_add && orderItem.grade && orderItem.grade > 0 && orderItem?.price_group) ? <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: 'Green',
                                    fontWeight: 700,
                                  }}
                                >
                                  Pricing group "{orderItem?.price_group}" applied
                                </Typography> : ''}
                              </Stack>
                              <Stack direction="row" alignItems={'center'}>
                                
                                <Stack
                                  direction="row"
                                  justifyContent={'space-between'}
                                  sx={{ mt: 0.5 }}
                                >
                                  <Stack direction="row" alignItems={'center'}>
                                    <IconButton
                                      onClick={() =>
                                        handleDecrementItemCount(
                                          orderItem.package_id,
                                        )
                                      }
                                    >
                                      <Remove
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          color: customerData?.customer_type ? 'common.black' :'common.white',
                                        }}
                                      />
                                    </IconButton>
                                    {orderItem.qty}
                                    <IconButton
                                      onClick={() =>
                                        handleIncrementItemCount(
                                          orderItem.package_id,
                                          orderItem.real_quantity,
                                        )
                                      }
                                    >
                                      <Add
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          color: customerData?.customer_type ? 'common.black' :'common.white',
                                        }}
                                      />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                                <MIconButton
                                  onClick={() =>
                                    handleRemoveFromOrder(orderItem.package_id)
                                  }
                                >
                                  <Delete sx={{ color: '#d50000' }} />
                                </MIconButton>
                              </Stack>
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </>
                  ))}
                </Scrollbar>):<EmptyContent
                  title="Cart is empty"
                  description="Looks like you have no items in your shopping cart."
                  img="/static/illustrations/illustration_empty_cart.svg"
                />)}

                {/* } */}
              </Box>

              <Divider sx={{ borderColor: '#000', mb: -1 }} />
              <Box
                align="center"
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                {/* <Box align="left" sx={{ pl: 2, pt: 2, pr: 1 }}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Medical Marijuana : </Typography>
                                            <Typography sx={{ fontSize: '14px', }}> {parseFloat(cartTotals.thc_medical).toFixed(2)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Edibles : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{parseFloat(cartTotals.thc_edible).toFixed(2)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Non-Edibles : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{parseFloat(cartTotals.thc_non_edible).toFixed(2)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Total THC : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>{parseFloat(cartTotals.thc_medical + cartTotals.thc_edible + cartTotals.thc_non_edible).toFixed(2)}</Typography>
                                        </Box>
                                    </Box> */}
                <Box sx={{ px: 3, pt: 1.2,mb:-1 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>
                      Total Qty:{' '}
                    </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }}>${calculateSubtotal()}</Typography> */}
                    <Typography sx={{ fontSize: '13px' }}>
                      {totalQty || 0}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>
                      Sub total :{' '}
                    </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }}>${calculateSubtotal()}</Typography> */}
                    <Typography sx={{ fontSize: '13px' }}>
                      $
                      {parseFloat(cartTotals.subtotal * currencyRate).toFixed(
                        2,
                      )}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >                 
                    <Typography sx={{ fontSize: '13px' }}>
                      Discount :{' '}
                    </Typography>
                    {cartItem?.length> 0 ? (
                      <Typography sx={{ fontSize: '13px' }}>
                      ${' '}
                      {parseFloat(cartTotals.discount * currencyRate).toFixed(
                        2,
                      )}
                    </Typography>
                    ):(
                      <Typography sx={{ fontSize: '13px' }}>
                      ${' '}
                      0.00
                    </Typography>
                    )}
                    
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>Taxes : </Typography>
                    {/* <Typography sx={{ fontSize: '14px' }}>${calculateTaxes()}</Typography> */}
                    <Typography sx={{ fontSize: '13px' }}>
                      ${parseFloat(cartTotals.tax * currencyRate).toFixed(2)}
                    </Typography>
                  </Box>
                  {isShipping && <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderRadius: '10px', p: '2px' }}
                  >
                    <Typography sx={{ fontSize: '13px' }}>Shipping Charges : </Typography>
                    <Typography sx={{ fontSize: '13px' }}>
                      ${parseFloat(shippingCharges * currencyRate).toFixed(2) || 0.00}
                    </Typography>
                  </Box>}
                  {/* <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px', }}>General Fee : </Typography>
                                            <Typography sx={{ fontSize: '14px', }}>${calculateGeneralfee()}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#f2f2f2', borderRadius: '10px', p: '2px' }} my={'2px'}>
                                            <Typography sx={{ fontSize: '14px' }}>Metrc Fee : </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>${calculateMetricFee()}</Typography>
                                        </Box> */}
                </Box>
              </Box>
              <Divider sx={{ borderColor: '#000', mt: 1 }} />
                {/* <Box sx={{ width: '100%', px: 1.5,pt:1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Shipping Charges
                </Typography>

                <FormControlLabel
                  control={
                    <AntSwitch
                      checked={Boolean(isShipping)}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setIsShipping(isChecked);
                        const shippingCharge = shippingCharges[0]?.rate || 0;
                        const newShippingCharge = isChecked ? shippingCharge : 0;
                        setShippingRate(newShippingCharge);
                        console.log('carttotals-----',cartTotals)
                        console.log('shippingCharge-----',shippingCharge)
                        setCartTotals((prevCartTotals) => ({
                          ...prevCartTotals,
                          total: isChecked
                            ? (prevCartTotals.total + shippingCharge) * currencyRate
                            : (prevCartTotals.total - shippingCharge) * currencyRate,
                        }));
                      }}
                      sx={{ color: '#017c6b' }}
                    />
                  }
                  label=""
                />
              </Box> */}

              <Box sx={{ width: '100%', px: 1.5,pt:1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <RadioGroup column value={shipmentType} onChange={handleShipmentTypeChange}>
                  <FormControlLabel value={1} control={<Radio size="small" />} label="Shipping with insurance" />
                  <FormControlLabel value={2} control={<Radio size="small" />} label="Shipping without insurance" />
                  <FormControlLabel value={3} control={<Radio size="small" />} label="In town" />
                </RadioGroup>
              </Box>

              <Box sx={{ width: '100%', p: 1.5 }}> 
                {!addRedeemCoupon ? (     
                  <TextField 
                    size='small' 
                    fullWidth
                    placeholder='HUFjvIaT'
                    label='Discount Coupon' 
                    value={coupon || ''}
                    onChange={(event)=>{
                      setCoupon(event.target.value)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button size='small' onClick={()=>handleCouponApply(null)}>Apply Now</Button>
                        </InputAdornment>
                      )
                    }}
                  />
                ):(
                  <Typography variant='caption' color={'#016e07'} 
                    sx={{fontSize:'13px',fontWeight:'bold', whiteSpace:'nowrap', alignItems:'center', justifyContent:'center'}}>
                    {((cartTotals?.auto_apply_discount > 0 ? 'Auto ' : '')+'Applied Coupon Code : '+paymentData?.coupon_code)}
                  </Typography>
                )}
              </Box>
              
              <Divider sx={{ borderColor: '#000', }} />
              <Stack sx={{display:'flex',justifyContent:'space-between',flexDirection:{xs:'column',md:'row'},mt:1,mb:1}} alignContent={'center'}  alignItems="center" px={1}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={2}
                  sx={{width:'50%'}}
                >
                  <Typography sx={{ fontSize: '16px',color:'error.main',fontWeight:600 }}>Total : </Typography>
                  <Typography sx={{ fontSize: '16px',color:'error.main',fontWeight:600 }}>
                  $ {Math.max(0, parseFloat(cartTotals.total * currencyRate)).toFixed(2)}
                  </Typography>
               
                  {/* <Typography sx={{ fontSize: '20px' }}>${(
                                            parseFloat(calculateSubtotal()) +
                                            parseFloat(calculateTaxes()) +
                                            parseFloat(calculateGeneralfee()) +
                                            parseFloat(calculateMetricFee())
                                        ).toFixed(2)}</Typography> */}
                </Box>
                <Box sx={{pr:1,}}>
                <Button
                  sx={{
                    backgroundColor: 'success.dark',
                    color: 'common.white',
                   
                  }}
                  startIcon={
                    <Stack
                      className="solar--cart-3-bold-duotone"
                      sx={{ fontSize: '1.5rem' }}
                    />
                  }
                  variant="contained"
                  color="success"
                  disabled={
                  cartItem.length===0 ||  readyToPlaceOrder||!user?.permissions?.some(permission => ['Place Order'].includes(permission) )
                  }
                  // onClick={placeOrder}
                  onClick={() => {
                    placeOrder()
                    // navigate(PATH_DASHBOARD.pov.CustomerLookup)
                    // toggleFullScreen();
                  }}
                  // onClick={() => navigate(PATH_DASHBOARD.pov.PaymentProcess)}
                >
                  {`Place ${isPickUp ? 'Pick up' : ''} Orders`}
                </Button>
                </Box>
                <CheckoutConfirmation
                  open={confirmDialogOpen}
                  onClose={handleDialogCancel}
                  onConfirm={handleCheckoutConfirm}
                  title="Are you sure?"
                  message="There are no products in the cart. Do you still want to proceed with the checkout?"
                  confirmText="Proceed"
                  cancelText="Cancel"
                />

                {readyToPlaceOrder && (
                  <Button
                   size='small'
                    sx={{ mt: 2 }}
                    fullWidth
                    variant="contained"
                    onClick={placeOrder}
                  >
                    Ready For Sale & Notify
                  </Button>
                )}
              </Stack>
            </Card>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Page>
    )
}
