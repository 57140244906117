import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Box,
  Button,
  DialogActions,
  Checkbox,
  MenuItem
} from '@material-ui/core';

import { UploadAvatar, UploadMultiFile, UploadSingleFile } from 'src/components/upload';
import useAuth from 'src/hooks/useAuth';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import axios from 'axios';
//


// ----------------------------------------------------------------------


const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------

export default function VoidOrder({
    setOpenVoid,
    user_id,
    facility_id,
    order_id,
    refresh,
    setRefresh
}) {
    const {enqueueSnackbar} =useSnackbar()

  const NewProductSchema = Yup.object().shape({
  void_reason: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
     void_reason:'',
     
    },
    validationSchema: NewProductSchema,
    onSubmit:  (values, { setSubmitting, resetForm, setErrors }) => {
       
      try {
         axios.post(`${REST_API_END_POINT}point-of-sale/void-order`,{
            order_id,facility_id,user_id,void_reason:values?.void_reason 
        })
        .then((res)=>{
            if(res.data.status==1){
                enqueueSnackbar(res.data.message,{variant:'success'})
               setRefresh(!refresh)
                setOpenVoid(false)
            }else{
                enqueueSnackbar(res.data.message,{variant:'error'})
                setOpenVoid(false)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  console.log(values,'------------%%%')

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      multiline={4}
                      label="Reason"
                      type='name'
                      {...getFieldProps('void_reason')}
                      error={Boolean(touched.void_reason && errors.void_reason)}
                      helperText={touched.void_reason && errors.void_reason}
                    />
                  
                </Stack>
               
                <DialogActions>
                  <Box sx={{ flexGrow: 1,}} />                 
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting} 
                  loadingIndicator="Loading...">
                   Submit
                  </LoadingButton>
                  <Button type="button" variant="outlined" color="inherit"
                   onClick={()=>{setOpenVoid(false)}}>
                    Cancel
                  </Button>
                </DialogActions>                
              </Stack>
            
      </Form>
    </FormikProvider>
  );
}