import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { LoadingButton } from '@material-ui/lab'
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Box,
  Button,
  DialogActions,
  Checkbox,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Badge,
  Paper,
} from '@material-ui/core'

import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
} from 'src/components/upload'
import useAuth from 'src/hooks/useAuth'
import { OUNCE_CONSTANT, REST_API_END_POINT } from 'src/constants/DefaultValues'
import axios from 'axios'
import { zeroPad } from 'src/constants/Helper'
import { QuillEditor } from 'src/components/editor'
import moment from 'moment'
import { CUSTOMER_ORDER_STATUS } from './allOrders'
import Scrollbar from 'src/components/Scrollbar'
//

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

// ----------------------------------------------------------------------

const getData = (source, ids) => {
  console.log('source', source)
  console.log('ids', ids)
  if (!ids) return []
  return ids
    .split(',')
    .map((id) => source.find((item) => item.id == id))
    .filter((item) => item !== undefined)
}

export default function CompleteOrder({
    invoiceOrderData,
    setOpenComplete,
    order_id,
    facility_id,
    user_id,
    refresh,
    setRefresh,

}) {


//   const cancelOrder=()=>{
   
//       axios.post(`${REST_API_END_POINT}point-of-sale/cancel-order`,{
//         user_id:user.id,facility_id:user.facility_id,order_ids:[order_details.order_id]
//       })
//       .then((res)=>{
//         if(res.data.status===1){
//           fetchData()
//           setOpenview(false)
//           enqueueSnackbar(res.data.message,{variant:'success'})
//         }
//       })
//       .catch((err)=>{
//         console.log(err)
//         setOpenview(false)
//         enqueueSnackbar(err,{variant:'error'})
//       })
//   }
const {enqueueSnackbar} =useSnackbar()
console.log(invoiceOrderData)
const order_status = CUSTOMER_ORDER_STATUS.find(r=>{ return r.value==invoiceOrderData?.status });

console.log(order_status,'---------------order_status')
let status_text =''
let status_badge =''  
  if(order_status!=null){
    status_text = order_status.label;
    status_badge = <Badge
    //  color={order_status.badge}
      >{order_status.label}</Badge>;
  }
  const handleSubmit = () => {
    axios.post(`${REST_API_END_POINT}point-of-sale/complete_order`, {
      order_id,facility_id,user_id
    })
    .then((res) => {
      if (res.data.status === 1) {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        setRefresh(!refresh)
        setOpenComplete(false)
      } else {
        enqueueSnackbar(res.data.message, { variant: 'error' });
        setOpenComplete(false)
      }
    })
    .catch((error) => {
      enqueueSnackbar('An error occurred while completing the order', { variant: 'error' });
      console.error('Error completing the order:', error);
      setOpenComplete(false)
    });
  };
  


  
  return (
    
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 1 }}>
              <Stack spacing={3}>
               <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                      <Typography variant='h5'>#{invoiceOrderData?.order_number}</Typography>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: {xs:500,md:'100%'} ,my:5}}>
                          <Table>
                            <TableHead >
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Quantity</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {invoiceOrderData?.order_products?.map((row,index)=>{
                                const formattedProductQty = row.is_finished === 0
                                ? `${parseFloat(row.product_qty).toFixed(2)} g`
                                : parseInt(row.product_qty, 10);
                            
                                const variationName = row.variation_name != null
                                ? ` *${row.variation_name}`
                                : '';
                                return(
                                  <TableRow key={index}>
                                  <TableCell>
                                  {index+1}
                                  </TableCell>
                                  <TableCell>{row.product_name} {row.is_finished==1?row.variation_name!=null ? 
                                  "("+row.variation_name+")":"":""}</TableCell>
                                  <TableCell>
                                  {formattedProductQty}
                                  {variationName}
                                  </TableCell>
                                </TableRow>
                            )})}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                   </Grid>
               </Grid>   
               <DialogActions>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button type="button" variant="contained" onClick={handleSubmit}>
                      Complete
                      </Button>
                    <Button type="button" variant="outlined" color="inherit" onClick={() => { setOpenComplete(false) }}>
                      Cancel
                    </Button>
                  </DialogActions>         
              </Stack>
            </Paper>
          </Grid>
        </Grid>
  );
}