import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Box,
  Button,
  DialogActions,
  Checkbox,
  MenuItem
} from '@material-ui/core';

import { UploadAvatar, UploadMultiFile, UploadSingleFile } from 'src/components/upload';
import useAuth from 'src/hooks/useAuth';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import axios from 'axios';
//


// ----------------------------------------------------------------------


const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------


export default function AdjustInventory({
    setOpenPackage,product_id,
    setRefreshPackageData,
    refreshPackageData
}) {
    const {enqueueSnackbar} =useSnackbar()
  const location = useLocation()
  const { user } = useAuth();
 
  const NewProductSchema = Yup.object().shape({
  quantity: Yup.number().required('Quantity is required').typeError('Quantity must be a number'),
  price: Yup.number().required('Price is required').typeError('Price must be a number'),
  packages: Yup.number().required('Packages is required').typeError('Packages must be a number'),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
     quantity:'',
     price:'',
     packages:1,
    },
    validationSchema: NewProductSchema,
    onSubmit:  (values, { setSubmitting, resetForm, setErrors }) => {
      try {
         axios.post(`${REST_API_END_POINT}inventory/add-product-packages`,{
            package_type:1,product_id,
            facility_id:user.facility_id,user_id:user.id,values,        
        })
        .then((res)=>{
            if(res.data.status==1){
                enqueueSnackbar(res.data.message,{variant:'success'})
                setRefreshPackageData(!refreshPackageData)
                setOpenPackage(false)
            }else{
                enqueueSnackbar(res.data.message,{variant:'error'})
                setOpenPackage(false)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });
  

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;


  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Quantity (Gram)"
                      type='number'
                      inputProps={{ min: 0 }}
                      {...getFieldProps('quantity')}
                      error={Boolean(touched.quantity && errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                    />
                    <TextField
                      fullWidth
                      label="Price (1 Gram)"
                      type='number'
                      inputProps={{ min: 0 }}
                      {...getFieldProps('price')}             
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                    />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type='number'                 
                      defaultValue={1}
                      inputProps={{ min: 0 }}
                      label="No of Packages"
                      {...getFieldProps('packages')}
                      error={Boolean(touched.packages && errors.packages)}
                      helperText={touched.packages && errors.packages}
                    />
                    </Stack>
                <DialogActions>
                  <Box sx={{ flexGrow: 1,}} />                 
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting} 
                  loadingIndicator="Loading...">
                    Add
                  </LoadingButton>
                  <Button type="button" variant="outlined" color="inherit"
                   onClick={()=>{setOpenPackage(false)}}>
                    Cancel
                  </Button>
                </DialogActions>                
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}