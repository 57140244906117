import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { BlockOutlined, TaskAltOutlined, Visibility } from '@material-ui/icons';

import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

OrderMenu.propTypes = {

};

export default function OrderMenu({userPrevileges,
   handleApprove,orders,
   onStatusChange,
    id,
    handleVoid,
    status ,
    payment_mode,
    payment_status,
    fully_returned,
    refund,
    vendor_name,
    handleEditInvoice,
    handleInvoice,
    handleOpenView,
    handleAdjust,
    handleMakePayment,
    handlePrint,
    handleComplete,
    handleRefund,
    approveOrRejectOpt,
    setRejectDialog,
    handleOrderApprovement,
  }) {
  const {user} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
         <MenuItem onClick={()=>{handleOpenView();setIsOpen(false);}} >
          <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {
          approveOrRejectOpt && (
           [
              <MenuItem key={"Approve"} onClick={()=>{handleOrderApprovement();setIsOpen(false);}} >
          <ListItemText primary="Approve" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>,
        <MenuItem key={"Reject"} onClick={()=>{setRejectDialog({
          action:true,
          order_id:id
        })}} >
          <ListItemText primary="Reject" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
           ]
          )
        }
      
        { !approveOrRejectOpt && status !==5 && status !==8 && (
        <MenuItem onClick={()=>{handleInvoice();setIsOpen(false);}} >
          <ListItemText primary="Invoice" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        )}
      {( !approveOrRejectOpt && status!=0 && status !==8 && status !==5) && <MenuItem  onClick={()=>{handlePrint();setIsOpen(false);}}>
          <ListItemText primary="Print label" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        }
       {( !approveOrRejectOpt && status!=4 && status !==8 && status !==5 )&& <MenuItem onClick={()=>{handleEditInvoice();setIsOpen(false);}} >
          <ListItemText primary="Edit Invoice" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
       { !approveOrRejectOpt && status !==8 && payment_status==0 && status>=1 && status!=4 && status !==5 &&<MenuItem onClick={()=>{handleAdjust();setIsOpen(false);}}>
          <ListItemText primary="Adjust Order" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
       { (!approveOrRejectOpt &&  status !==8 && payment_status===0 ||payment_status === 2) && status !==5 && status>=1&& status!=4&&<MenuItem onClick={()=>{handleMakePayment();setIsOpen(false);}}>
          <ListItemText primary="Make Payment" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
       {(!approveOrRejectOpt && status !==8 && status==0 && status !==5) && <MenuItem onClick={()=>{handleApprove();setIsOpen(false);}}>
          <ListItemText primary="Approve" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        { (!approveOrRejectOpt && status !==8 && status === 1 && refund==0 && payment_status === 1 && status !==5) &&<MenuItem onClick={()=>{handleComplete();setIsOpen(false);}}>
          <ListItemText primary="Complete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {(!approveOrRejectOpt && status !==8 && payment_mode=='Hyper'&& refund==0 &&  status !==5) &&<MenuItem onClick={()=>{handleComplete();setIsOpen(false);}}>
          <ListItemText primary="Refund" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
        {(!approveOrRejectOpt && status !==8 && payment_status===1&& fully_returned==0&& status!=4 && status!==5 ) &&<MenuItem 
        onClick={()=>{
          user?.permissions?.some(permission => ['Refund Order'].includes(permission)) ? 
          (handleRefund(),setIsOpen(false)):
          enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
        }}>
          <ListItemText primary="Refund order" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
       { !approveOrRejectOpt && status !==8 && status!==5 && <MenuItem onClick={()=>{ 
        user?.permissions?.some(permission => ['Void Order'].includes(permission)) ? 
        (handleVoid(),setIsOpen(false))
        :
        enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
        }} >
          <ListItemText primary="Void" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>}
      </Menu>
    </>
  );
}
