import { filter } from 'lodash'
import { useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { useTheme } from '@material-ui/core/styles'
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Badge,
} from '@material-ui/core'


// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths'
// components
import Page from '../../../../../components/Page'
import Label from '../../../../../components/Label'
import Scrollbar from '../../../../../components/Scrollbar'
import SearchNotFound from '../../../../../components/SearchNotFound'
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs'
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from '../../../../../components/_dashboard/user/list'
import { MIconButton } from 'src/components/@material-extend'
import AdjustInventory from '../../Inventory/Inventory/adjustInventory'
import { REST_API_END_POINT } from 'src/constants/DefaultValues'
import axios from 'axios'
import useAuth from 'src/hooks/useAuth'
import editFill from '@iconify/icons-eva/edit-fill'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { CUSTOMER_ORDER_STATUS } from '../AllOrders/allOrders'
import Modal from 'src/components/modal/Modal'
import InvoiceView from '../AllOrders/InvoiceView'
// ----------------------------------------------------------------------

const staticUserList = [
  {
    name: 'Mochi Single',
    strain: 'Mochi Single',
    category: 'Flower',
    subcategory: '',
    available: 0,
  },
  {
    name: 'Super Runtz B',
    strain: 'Super Runtz B',
    category: 'Flower',
    subcategory: '',
    available: '0',
  },
  {
    name: 'Cherry Gelato',
    strain: 'Cherry Gelato',
    category: 'Flower',
    subcategory: '',
    available: 0,
  },
  {
    name: 'Melon Gelato',
    strain: 'Melon Gelato',
    category: 'Flower',
    subcategory: '',
    available: 0,
  },
  {
    name: 'White Truffle',
    strain: 'White Truffle',
    category: 'Flower',
    subcategory: '',
    available: 0,
  },
]

const TABLE_HEAD = [
  { id: 'order_id', label: 'Order Id', alignLeft: true },
  { id: 'amount', label: 'Amount', alignLeft: true },
  { id: 'order_date', label: 'Order Date', alignLeft: true },
  { id: 'status', label: 'Status', alignLeft: true },
  { id: '' },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_user) =>
        _user?.order_number?.toString().toLowerCase()?.indexOf(query?.toLowerCase()) !== -1,
    )
  }
  return stabilizedThis?.map((el) => el[0])
}

export default function PastOrders({
orders,
}) {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState('id')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [currencies, setCurrencies] = useState([])
  const [orderProduct, setOrderProducts] = useState([])
  const [facilityData, setFacilityData] = useState({})
  const [invoiceOrderData, setInvoiceOrderData] = useState(null)
  const [openInvoice, setOpenInvoice] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orders?.map((n) => n.order_id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
    setPage(0)
  }


  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0

  const filteredUsers = applySortFilter(
    orders,
    getComparator(order, orderBy),
    filterName,
  )

  const isUserNotFound = filteredUsers?.length === 0
  function groupOrderProducts(orderProducts) {
    const groupedProducts = {};
  
    // Loop through the products and group them by product_id
    orderProducts.forEach(product => {
      const key = `${product.product_id}-${product.product_unit_price}`;
      if (groupedProducts[key]) {
        // If the product already exists, add the qty to the existing one
        groupedProducts[key].product_qty += product.product_qty || 1;
      } else {
        // If the product does not exist, create a new entry
        groupedProducts[key] = {
          ...product,
          product_qty: product.product_qty || 1,
        };
      }
    });
  
    // Convert the grouped object back into an array
    return Object.values(groupedProducts);
  }

  const handleInvoice = (order_id) => {
    axios
      .post(`${REST_API_END_POINT}point-of-sale/invoice-post`, {
        facility_id: user.facility_id,
        order_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setCurrencies(res.data.currencies)
          setInvoiceOrderData(res.data.order_data)
          // setOrderProducts(res.data.orderProduct)
          const groupedProducts = groupOrderProducts(res.data.orderProduct);
          setOrderProducts(groupedProducts);
          setFacilityData(res.data.facilityData)
          setOpenInvoice(true)
        }
      })
  }

  return (
    <Card sx={{m:2}}>
      <HeaderBreadcrumbs sx={{m:2}} heading="Past Purchases" />
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 850, whiteSpace: 'nowrap' }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={orders?.length}
              onRequestSort={handleRequestSort}
              showCheckBox={true}
            />

            <TableBody>
              {filteredUsers
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => {                   
                  const {               
                    order_number,
                    cash,
                    timestamp,
                    status,
                    order_id,                  
                  } = row
                  let order_status = CUSTOMER_ORDER_STATUS?.find((r) => {
                    return r?.value == status
                  })
            
                  const validBadgeColors = [
                    'default',
                    'primary',
                    'secondary',
                    'error',
                    'info',
                    'success',
                    'warning',
                  ]
                  const badgeColor = validBadgeColors?.includes(
                    order_status?.badge,
                  )
                    ? order_status?.badge
                    : 'default'
                  let status_text = ''
                  let status_badge = ''
                  if (order_status != null) {
                    status_text = order_status?.label
                    status_badge = (
                      <Badge
                        color={badgeColor}
                        sx={{ whiteSpace: 'break-spaces' }}
                      >
                        {order_status?.label}
                      </Badge>
                    )
                  }

                  return (                 
                    <TableRow hover key={order_id} tabIndex={-1}>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          wordWrap: 'break-word',
                          width: 200,
                          whiteSpace: 'normal',
                        }}                   
                      >
                        {order_number}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          wordWrap: 'break-word',
                          width: 200,
                          whiteSpace: 'normal',
                        }}
                      >
                        {parseFloat(cash).toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        { moment(parseInt(timestamp) * 1000).format(
                            'MM-DD-YYYY hh:mm A',
                          )}
                          </TableCell>
                          <TableCell align="left">
                            <Label sx={{ p: '15px',width:'100%'}}
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} 
                            color={badgeColor}>{status_badge}</Label>
                          </TableCell>                                     
                      <TableCell
                        align="left"                    
                      >    
                     <Button variant='outlined' size='small'
                     onClick={()=>handleInvoice(order_id)}
                     >
                        Invoice
                    </Button>               
                      </TableCell>                   
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
       <Modal
            handleClose={() => {
              setOpenInvoice(false)
              setCurrencies([])
            }}
            open={openInvoice}
            modalTitle={'Invoice'}
          >
            <InvoiceView
              setOpenInvoice={setOpenInvoice}
              facility_data={facilityData}
              order_data={invoiceOrderData}
              order_products={orderProduct}
            />
          </Modal>
    </Card>
  )
}
