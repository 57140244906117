import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Card, TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// utils
import fakeRequest from '../../../../utils/fakeRequest';
import { useState } from 'react';
import Icon from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

export default function AccountChangePassword({ 
  changePasswordVendor,
  setChangePasswordVendor,
  changePasswordOpen,
  setChangePasswordOpen,
  passWordSubmittingCall
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { user } = useAuth()
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowPassword1 = () => {
    setShowPassword1((show) => !show);
  };
  const handleShowPassword2 = () => {
    setShowPassword2((show) => !show);
  };

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match').required('Confirm the password'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },   
    validationSchema: ChangePassWordSchema,
    onSubmit: async (values, { setSubmitting, setErrors,resetForm }) => {
      const res = await passWordSubmittingCall(changePasswordVendor, values, user.id);
        if(res.data.status == 1){
          enqueueSnackbar('Password changed successfully', { variant: 'success' });
          setChangePasswordOpen(false);
          setChangePasswordVendor(null);
          resetForm()
        }else if(res.data.status == 0){
          setErrors({ oldPassword: ('Wrong password') });
          resetForm()
        }else{
          enqueueSnackbar('Error changing password', { variant: 'error' });
        }
      setSubmitting(false);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card sx={{ p: 3 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} alignItems="flex-end">
            <TextField
              {...getFieldProps('oldPassword')}
              fullWidth
              autoComplete="on"
              type={showPassword ? 'text' : 'password'}
              label="Old Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={showPassword ? 'Hide password' : 'Show password'}>
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
            />

            <TextField
              {...getFieldProps('newPassword')}
              fullWidth
              autoComplete="on"
              type={showPassword1 ? 'text' : 'password'}
              label="New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={showPassword1 ? 'Hide password' : 'Show password'}>
                      <IconButton onClick={handleShowPassword1} edge="end">
                        <Icon icon={showPassword1 ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={(touched.newPassword && errors.newPassword) || 'Password must be minimum 6+'}
            />

            <TextField
              {...getFieldProps('confirmNewPassword')}
              fullWidth
              autoComplete="on"
              type={showPassword2 ? 'text' : 'password'}
              label="Confirm New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={showPassword2 ? 'Hide password' : 'Show password'}>
                      <IconButton onClick={handleShowPassword2} edge="end">
                        <Icon icon={showPassword2 ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
