// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import Logo from '../logo/image.png'

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={(user?.profile_pic && user?.profile_pic !='' && user?.profile_pic !='avatar.jpg' && user?.profile_pic !='default1.jpg') ? user?.profile_pic : Logo}
      // src='https://bagempire-api.gettridant.com/uploads/users/avatar.jpg'
      alt={user?.full_name}
      sx={{border:'1px solid #000'}}
      color={user?.photoURL ? 'default' : createAvatar(user?.full_name).color}
      {...other}
    >
      {createAvatar(user?.full_name).name}
    </MAvatar>
  );
}
