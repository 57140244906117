import { Card, CardHeader, Table, TableBody, TableCell, TableRow, TableContainer, Button, Box, Divider } from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import Scrollbar from '../../Scrollbar';
import { styled } from '@material-ui/styles';

// Styled table head
const StickyTableHead = styled('thead')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: '#F4F6F8',
  zIndex: 1,
  color: theme.palette.primary.main
}));

export default function AppNewInvoice({ bestSellers }) {
  return (
    <Card sx={{ px: 5, backgroundColor: 'rgba(255, 255, 255, 0.4)', border: '1px solid #000', backdropFilter: 'blur(18px)', WebkitBackdropFilter: 'blur(8px)' }}>
      <CardHeader title="Best Sellers" sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 520, maxHeight: 455, '&::-webkit-scrollbar': {
         width: '12px',
         height: '12px',
         zIndex:999,
         cursor:'pointer',
        },
        '&::-webkit-scrollbar-track': {
          background: '#FFF',
          opacity: '0.4'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#CCC',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#CCC',
        } }}>
          <Table>
            <StickyTableHead>
              <TableRow>
                <TableCell sx={{ color: 'primary.main' }}>Product</TableCell>
                <TableCell sx={{ color: 'primary.main' }}>Count</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {bestSellers.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: 'primary.main' }}>{row.name || 'Unnamed Product'}</TableCell>
                  <TableCell sx={{ color: 'primary.main' }}>{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        {/* <Button
          to="#"
          size="small"
          color="inherit"
          component="a"
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button> */}
      </Box>
    </Card>
  );
}
