import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { Button, Box, Divider, MenuItem, Typography, Avatar } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import useAuth from '../../hooks/useAuth';
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
import { AccountCircleOutlined, LockOpen, ShoppingBag } from '@material-ui/icons';

export default function AccountPopoverUser({ handleClickChangePassword, fetchClientDetails }) {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      enqueueSnackbar('Logout successful', { variant: 'success' });
      navigate('/');
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const MENU_OPTIONS = [
    {
      label: 'Profile',
      icon: <AccountCircleOutlined sx={{ mr: 2, width: 24, height: 24 }} />,
      linkTo: () => fetchClientDetails()
    },
    {
      label: 'Change Password',
      icon: <LockOpen sx={{ mr: 2, width: 24, height: 24 }} />,
      linkTo: () => handleClickChangePassword()
    },
    ...(user?.user_type === 3 ? [{
      label: 'My Orders',
      icon: <ShoppingBag sx={{ mr: 2, width: 24, height: 24 }} />,
      linkTo: () => navigate(PATH_PAGE?.myOrders)
    }] : [])
  ];

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          ml:{xs:1.3,sm:0},
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            },
          }),
        }}
      >
        {/* <Avatar src={user?.profile_pic || ''} alt={user?.full_name} sx={{ border: '1px solid #000', p: 0.8, objectFit: 'cover' }} /> */}
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.full_name || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.username || 'admin@bagempire.com'}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose();
              option.linkTo();
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
