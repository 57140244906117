// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
// import { Link, useNavigate } from 'react-router-dom';
// import { Form, FormikProvider, useFormik } from 'formik';
// // material
// import { LoadingButton, } from '@material-ui/lab';
// import {
//     Box,
//     Card,
//     Grid,
//     Stack,
//     TextField,
//     Typography,
//     Button,
//     Autocomplete,
//     Switch,
// } from '@material-ui/core';
// // utils
// import fakeRequest from '../../../../../utils/fakeRequest';
// // routes
// import { PATH_DASHBOARD } from '../../../../../routes/paths';
// //
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// import { styled } from '@material-ui/styles';
// import { REST_API_END_POINT } from 'src/constants/DefaultValues';
// import axios from 'axios';
// import useAuth from 'src/hooks/useAuth';
// // ----------------------------------------------------------------------

// AddTaxCategory.propTypes = {
//     isEdit: PropTypes.bool,
//     currentUser: PropTypes.object
// };

// const LabelStyle = styled(Typography)(({ theme }) => ({
//     ...theme.typography.subtitle2,
//     color: theme.palette.text.secondary,
//     marginBottom: theme.spacing(1)
// }));

// export default function AddTaxCategory({ isEdit, currentUser, onHandleCloses }) {
//     const navigate = useNavigate();
//     const {user} = useAuth();
//     const { enqueueSnackbar } = useSnackbar();
//     const NewUserSchema = Yup.object().shape({
//         name: Yup.string().required('Please enter the name'),
//         taxRate: Yup.string().required('Please enter the tax rate'),
//     });
//     const formik = useFormik({
//         enableReinitialize: true,
//         initialValues: {
//             name: currentUser?.name || '',
//             isTaxSplit: currentUser?.is_tax_split || false,
//             taxRate: currentUser?.tax_rate || '',
//         },
//         validationSchema: NewUserSchema,
//         onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
//             try {
//                 console.log('values--------',values);
//                 let response = '';
//                 // if (isEdit) {
//                 //     values.status = currentUser.status
//                 //     response = await axios.post(`${REST_API_END_POINT}inventory/edit-tax-category`,{values,id:currentUser?.id, facility_id: user?.facility_id, userId:user?.id})
//                 // }else{
//                 //     response = await axios.post(`${REST_API_END_POINT}inventory/add-tax-category`,{values, facility_id: user?.facility_id, userId:user?.id})
//                 // }
//                 if(response?.data?.status === 1){
//                     resetForm();
//                     setSubmitting(false);
//                     enqueueSnackbar(response?.data?.message, { variant: 'success' });
//                     onHandleCloses(true);
//                 }else{
//                     enqueueSnackbar(response?.data?.message, { variant: 'error' });
//                 }
//             } catch (error) {
//                 console.error(error);
//                 setSubmitting(false);
//                 setErrors(error);
//                 enqueueSnackbar('Server Error', { variant: 'error' });
//             }
//         }
//     });

//     const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

//     return (
//         <FormikProvider value={formik}>
//             <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
//                 <Grid container spacing={3}>
//                     <Grid item xs={12} md={12}>
//                         <Stack spacing={3}>
//                             <Stack>
//                                 <TextField
//                                     fullWidth
//                                     required
//                                     label="Name"
//                                     type='text'
//                                     {...getFieldProps('name')}
//                                     error={Boolean(touched.name && errors.name)}
//                                     helperText={touched.name && errors.name}
//                                 />
//                             </Stack>
//                             <Stack flexDirection={'row'}>
//                                 <Switch
//                                     {...getFieldProps('isTaxSplit')}
//                                     // onChange={status => {
//                                     //     let tax_rate = this.state.tax_rate;
//                                     //     if(!this.state.isTaxSplit){
//                                     //         tax_rate =  this.state.tax_split_up.map(r=>{ return r.rate }).reduce((total, num)=>{ return parseFloat(total)+parseFloat(num) });
//                                     //         tax_rate = parseFloat(tax_rate).toFixed(2);
//                                     //     }
//                                     //     this.setState({ isTaxSplit:!this.state.isTaxSplit, tax_rate }) 
//                                     // }}
//                                     error={Boolean(touched.isTaxSplit && errors.isTaxSplit)}
//                                     helperText={touched.isTaxSplit && errors.isTaxSplit}
//                                 />
//                                 <Typography variant='body1' sx={{mt:0.8}}>Enable Tax Split Up</Typography>
//                             </Stack>
//                             {values?.isTaxSplit == true ? <Stack flexDirection={'row'} gap={2}>
//                                 <TextField
//                                     fullWidth
//                                     required
//                                     label="Name"
//                                     type='text'
//                                     {...getFieldProps('split_Name')}
//                                     error={Boolean(touched.split_Name && errors.split_Name)}
//                                     helperText={touched.split_Name && errors.split_Name}
//                                 />
//                                 <TextField
//                                     fullWidth
//                                     required
//                                     label="Tax Rate (%)"
//                                     type='number'
//                                     onKeyPress={(event)=>{
//                                         if (event.key === '+' || event.key === '-' || event.key === 'e') {
//                                           event.preventDefault();
//                                         }
//                                     }}
//                                     {...getFieldProps('split_taxRate')}
//                                     error={Boolean(touched.split_taxRate && errors.split_taxRate)}
//                                     helperText={touched.split_taxRate && errors.split_taxRate}
//                                 />
//                             </Stack> : '' }
//                             <Stack>
//                                 <TextField
//                                     fullWidth
//                                     required
//                                     label="Tax Rate (%)"
//                                     type='number'
//                                     onKeyPress={(event)=>{
//                                         if (event.key === '+' || event.key === '-' || event.key === 'e') {
//                                           event.preventDefault();
//                                         }
//                                     }}
//                                     {...getFieldProps('taxRate')}
//                                     error={Boolean(touched.taxRate && errors.taxRate)}
//                                     helperText={touched.taxRate && errors.taxRate}
//                                 />
//                             </Stack>
//                             <Box gap={2} sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
//                                 <Button  variant="outlined" onClick={onHandleCloses}>
//                                     Cancel
//                                 </Button>
//                                 <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
//                                     {!isEdit ? 'Add Tax Category' : 'Save Changes'}
//                                 </LoadingButton>
//                             </Box>


//                         </Stack>
//                     </Grid>
//                 </Grid>
//             </Form>
//         </FormikProvider>
//     );
// }


import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import { useEffect } from 'react';
// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Grid,
    Stack,
    TextField,
    Typography,
    Button,
    Switch,
    IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
// utils
import fakeRequest from '../../../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
//
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { styled } from '@material-ui/styles';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import axios from 'axios';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

AddTaxCategory.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object
};

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
}));

export default function AddTaxCategory({ isEdit, currentUser, onHandleCloses }) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const NewUserSchema = Yup.object().shape({
        taxName: Yup.string().required('Please enter the name'),
        taxRate: Yup.number()
            .required('Please enter the tax rate')
            // .min(0.01, 'Please enter a value greater than 0'),
            .min(0, 'Please enter a value greater than or equal to 0'),
        isTaxSplit: Yup.boolean(),
        splitTaxes: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().when('$isTaxSplit', {
                    is: true,
                    then: Yup.string().required('Please enter the split name')
                }),
                rate: Yup.number().when('$isTaxSplit', {
                    is: true,
                    then: Yup.number()
                        .required('Please enter the split tax rate')
                        // .min(0.01, 'Please enter a value greater than 0')
                        .min(0, 'Please enter a value greater than or equal to 0')
                })
            })
        )
    });

    // Parse tax_split_up if it exists
    const parsedSplitTaxes = currentUser?.tax_split_up
        ? JSON.parse(currentUser.tax_split_up).map(split => ({
            name: split.name,
            rate: parseFloat(split.rate)
        }))
        : [{ name: '', rate: 0 }];

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            taxName: currentUser?.name || '',
            isTaxSplit: currentUser?.is_tax_split==1 ? true : false || false,
            taxRate: currentUser?.tax_rate || '',
            splitTaxes: currentUser?.is_tax_split ? parsedSplitTaxes : [{ name: '', rate: 0 }]
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                console.log('values--------', values);
                if(values.isTaxSplit){
                    values.splitTaxes = JSON.stringify(values.splitTaxes)
                    values.isTaxSplit = 1
                }else{
                    delete values.splitTaxes;
                    values.isTaxSplit = 0
                }
                let response = '';
                if (isEdit) {
                    values.status = currentUser.status
                    response = await axios.post(`${REST_API_END_POINT}inventory/edit-tax-category`,{values,id:currentUser?.id, facility_id: user?.facility_id, userId:user?.id})
                }else{
                    response = await axios.post(`${REST_API_END_POINT}inventory/add-tax-category`,{values, facility_id: user?.facility_id, userId:user?.id})
                }
                if (response?.data?.status === 1) {
                    resetForm();
                    setSubmitting(false);
                    enqueueSnackbar(response?.data?.message, { variant: 'success' });
                    onHandleCloses(true);
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error' });
                }
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                setErrors(error);
                enqueueSnackbar('Server Error', { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

    useEffect(() => {
        if (values.isTaxSplit) {
            const totalTaxRate = values.splitTaxes.reduce((sum, split) => sum + parseFloat(split.rate || 0), 0);
            setFieldValue('taxRate', totalTaxRate.toFixed(2));
        }
    }, [values.splitTaxes, values.isTaxSplit, setFieldValue]);

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Stack spacing={3}>
                            <Stack>
                                <TextField
                                    fullWidth
                                    required
                                    label="Name"
                                    type='text'
                                    {...getFieldProps('taxName')}
                                    error={Boolean(touched.taxName && errors.taxName)}
                                    helperText={touched.taxName && errors.taxName}
                                />
                            </Stack>
                            <Stack flexDirection={'row'}>
                                <Switch
                                    {...getFieldProps('isTaxSplit')}
                                    checked={values.isTaxSplit}
                                    onChange={() => setFieldValue('isTaxSplit', !values.isTaxSplit)}
                                    error={Boolean(touched.isTaxSplit && errors.isTaxSplit)}
                                    helperText={touched.isTaxSplit && errors.isTaxSplit}
                                />
                                <Typography variant='body1' sx={{ mt: 0.8 }}>Enable Tax Split Up</Typography>
                            </Stack>
                            {values.isTaxSplit && (
                                <FieldArray name="splitTaxes">
                                    {({ push, remove }) => (
                                        <>
                                            {values?.splitTaxes?.map((_, index) => (
                                                <Stack flexDirection={'row'} gap={2} key={index}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label="Split Name"
                                                        type='text'
                                                        {...getFieldProps(`splitTaxes.${index}.name`)}
                                                        error={Boolean(touched.splitTaxes?.[index]?.name && errors.splitTaxes?.[index]?.name)}
                                                        helperText={touched.splitTaxes?.[index]?.name && errors.splitTaxes?.[index]?.name}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        label="Tax Rate (%)"
                                                        type='number'
                                                        onKeyPress={(event) => {
                                                            if (event.key === '+' || event.key === '-' || event.key === 'e') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        {...getFieldProps(`splitTaxes.${index}.rate`)}
                                                        error={Boolean(touched.splitTaxes?.[index]?.rate && errors.splitTaxes?.[index]?.rate)}
                                                        helperText={touched.splitTaxes?.[index]?.rate && errors.splitTaxes?.[index]?.rate}
                                                    />
                                                    {index > 0 && (
                                                        <IconButton onClick={() => remove(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Stack>
                                            ))}
                                            <Button variant="contained" onClick={() => push({ name: '', rate: 0 })}>
                                                Add Split Tax
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            )}
                            <Stack>
                                <TextField
                                    fullWidth
                                    disabled = {values.isTaxSplit ? true :  false}
                                    required
                                    label="Tax Rate (%)"
                                    type='number'
                                    onKeyPress={(event) => {
                                        if (event.key === '+' || event.key === '-' || event.key === 'e') {
                                            event.preventDefault();
                                        }
                                    }}
                                    {...getFieldProps('taxRate')}
                                    value={values.taxRate}
                                    error={Boolean(touched.taxRate && errors.taxRate)}
                                    helperText={touched.taxRate && errors.taxRate}
                                />
                            </Stack>
                            <Box gap={2} sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="outlined" onClick={onHandleCloses}>
                                    Cancel
                                </Button>
                                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                    {!isEdit ? 'Add Tax Category' : 'Save Changes'}
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}


