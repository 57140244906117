// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import SvgIconStyle from '../../components/SvgIconStyle';
import { AccountBalance, Analytics, BarChartOutlined, BookmarkAdd, CalendarViewMonthOutlined, Category, CommentOutlined, ContactMail, CreditCard, DescriptionOutlined, DirectionsCarFilledOutlined, Inventory, Language, LanguageOutlined, LocalShipping, ParkOutlined, PeopleAlt, PeopleOutlined, PlaceOutlined, PointOfSale, Redeem, ShoppingCartOutlined, Spa, Store, Widgets, WorkOutlineOutlined } from '@material-ui/icons';


// ----------------------------------------------------------------------

// const getIcon = (name) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   kanban: getIcon('ic_kanban')
// };
const customerIds=[
  40,41,42
]
const InventoryIds=[
  0,7
]
const ventorIds=[
  0,23
]
const staffIds=[
  35,36,37,38
]
const PricingGroupIds=[
  9,10,11,12
]
const PointsIds=[
  31,32,33,34
]

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
  //   ]
  // },
  {

    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: <Store />,
      },
      { title: 'Point Of Sale', path: PATH_DASHBOARD.pov.root, 
        // icon:<Redeem /> 
        icon:<PointOfSale /> ,
        children:[
          { title: 'Customer Lookup', path: PATH_DASHBOARD.pov.CustomerLookup },
          { title: 'All Orders', path: PATH_DASHBOARD.pov.AllOrders },
          { title: 'Order Queue', path: PATH_DASHBOARD.pov.orderAhead },
          { title: 'Drawer Management', path:PATH_DASHBOARD.pov.DrawersManagements},
        ]
        },
      { title: 'Transportation', path: PATH_DASHBOARD.transportation.root,
        icon:<LocalShipping /> ,
        children:[
          { title: 'Transportation', path: PATH_DASHBOARD.transportation.transportation },
          { title: 'Drivers', path: PATH_DASHBOARD.transportation.drivers },
          { title: 'Shipper Information', path: PATH_DASHBOARD.transportation.shipperInformation },
          { title: 'Vehicles', path: PATH_DASHBOARD.transportation.vehicles },
        ]
        },
      { title: 'Website',path:PATH_DASHBOARD.websiteModule.root, icon:  <Language />,
        children:[
          { title: 'Pages', path:PATH_DASHBOARD.websiteModule.homePages},
          { title: 'Top Deals', path:PATH_DASHBOARD.websiteModule.homeDeals},
          { title: 'Testimonials', path:PATH_DASHBOARD.websiteModule.homeTestimonials},
          { title: 'Home Sliders', path:PATH_DASHBOARD.websiteModule.homeSlider},
          { title: 'Footer Management', path:PATH_DASHBOARD.websiteModule.footerManagement},
          { title: 'Settings', path:PATH_DASHBOARD.websiteModule.websiteSettings},
          { title: 'Customer Signup', path:PATH_DASHBOARD.websiteModule.customerSignUp},
          { title: 'Contact form enquiry', path:PATH_DASHBOARD.websiteModule.customerEnquiry},
          // { title: 'Order Enquiry', path:PATH_DASHBOARD.websiteModule.orderEnquiry},
        ]
      },
    ,
      {
        title: 'Customers Management',
        path: PATH_DASHBOARD.user.root,
        icon: <ContactMail />,
        children: [
            { title: 'Customers', path: PATH_DASHBOARD.user.allcustomers },
          // {
          //   title: 'Coupons',
          //   path: PATH_DASHBOARD.user.coupon,
          //   children: [
          //     { title: 'Coupons', path: PATH_DASHBOARD.user.cards },
          //     { title: 'Buy One Get One Offers', path: PATH_DASHBOARD.user.coupon },
          //   ]
          // },
          // { title: 'Coupons', path: PATH_DASHBOARD.user.coupon },
          { title: 'Settings', path: PATH_DASHBOARD.user.CustomerpointSetting },
          { title: 'Buy One Get One Offers', path: PATH_DASHBOARD.user.buyOneGetOne },
          { title: 'Coupons', path: PATH_DASHBOARD.user.coupon },
          

          { title: 'Customer Points', path: PATH_DASHBOARD.user.CustomerPoint },
          { title: 'Customer Referrals', path: PATH_DASHBOARD.user.CustomerRefferals },
          { title: 'Customer Types', path: PATH_DASHBOARD.user.CustomerTypes },
        ]
      },

      { title: 'Wholesale Order Management', path: PATH_DASHBOARD.vendor.root, icon: <ShoppingCartOutlined />,
      children:[
        { title: 'All Vendors', path: PATH_DASHBOARD.vendor.AllVentors },
        { title: 'All Brokers', path: PATH_DASHBOARD.vendor.allAccounts },
        { title: 'All Brokerage Account', path: PATH_DASHBOARD.vendor.AllBrokers },
        { title: 'Completed Orders', path: PATH_DASHBOARD.vendor.completedOrder },
        // { title: 'Brokers Split Up', path: PATH_DASHBOARD.vendor.allBrokersSplitUp },
        { title: 'All Vendor Types', path: PATH_DASHBOARD.vendor.AllvenTypes },      
          { title: 'Receive Inventory Orders', path: PATH_DASHBOARD.vendor.ReceiverInven },
        // { title: 'Lab Testing Calender', path:PATH_DASHBOARD.calendar },
      ] },
      { title: 'Staff Managements',path:PATH_DASHBOARD.staffManagement.root, icon:  <PeopleAlt />,
        children:[
            { title: 'Staff Directory', path:PATH_DASHBOARD.staffManagement.staffDirectory},
          { title: 'User Roles', path:PATH_DASHBOARD.staffManagement.userRoles},
          { title: 'Staff Scheduler', path:PATH_DASHBOARD.staffManagement.schedulerCalender},
        ]
       },
       
      // { title: 'Accounts Managements', path: PATH_DASHBOARD.general.analytics, icon: <CreditCard />, },
      
      // { title: 'Bank Management', icon: <AccountBalance />,
      //   children:[
      //     { title: 'Drawers Managements', path:PATH_DASHBOARD.bank.DrawersManagements},
      //   ]
      //  },
      // { title: 'Staff Managements', path: PATH_DASHBOARD.general.analytics, icon: <PeopleAlt />, },
      { title: 'Inventory', path: PATH_DASHBOARD.inventory.root, icon: <Widgets />,
    children:[
      { title: 'Rooms', path: PATH_DASHBOARD.inventory.Rooms},
      { title: 'Inventory Audit', path: PATH_DASHBOARD.inventory.inventoryAudit},
        { title: 'Inventory', path: PATH_DASHBOARD.inventory.Inventory},

      // { title: 'Inventory Audit', path: PATH_DASHBOARD.inventory.inventoryAudit},
      { title: 'Strains', path: PATH_DASHBOARD.inventory.Strain},
      // { title: 'Type', path: PATH_DASHBOARD.inventory.Type},
      { title: 'Product Category Type', path: PATH_DASHBOARD.inventory.Type},
      { title: 'Product Category', path: PATH_DASHBOARD.inventory.ProductCategory},
      { title: 'Product Sub Category', path: PATH_DASHBOARD.inventory.ProductSubCategory},
      { title: 'Brand', path: PATH_DASHBOARD.inventory.Brand},
      { title: 'Tax Category', path: PATH_DASHBOARD.inventory.TaxCategory},
        { title: 'Pricing Group', path: PATH_DASHBOARD.inventory.PricingGroup},
    
    ]
    },
    { title: 'Leaf Management', path: PATH_DASHBOARD.leafManagement.leaf, icon: <Spa />, },
    {
      title: 'Reports',
      icon: <Analytics />,
      path: PATH_DASHBOARD.reportInventory.root,
      children: [
          { title: 'Current Inventory', path: PATH_DASHBOARD.reportInventory.currentInventory,
            children:[
              { title: 'Current Inventory', path: PATH_DASHBOARD.reportInventory.currentInventory},
              { title: 'Historical Inventory', path: PATH_DASHBOARD.reportInventory.historicalInventory},
              { title: 'Inventory Adjustments', path: PATH_DASHBOARD.reportInventory.inventoryAdjustments},
              { title: 'Inventory Forensics', path: PATH_DASHBOARD.reportInventory.inventoryForensics},
              { title: 'Inventory Audit', path: PATH_DASHBOARD.reportInventory.inventoryAudit},
              { title: 'Destruction Events', path: PATH_DASHBOARD.reportInventory.destructionEvents},
              { title: 'Products', path: PATH_DASHBOARD.reportInventory.productsReport},
              { title: 'Product Pricing', path: PATH_DASHBOARD.reportInventory.productPricing},
              // { title: 'Kitchen', path: PATH_DASHBOARD.reportInventory.kitchenReport},
              
              { title: 'Receive Inventory', path: PATH_DASHBOARD.reportInventory.receiverInventory},
            ]
          },
          { title: 'Historical Inventory', path: PATH_DASHBOARD.reportInventory.historicalInventory},
          { title: 'Inventory Adjustments', path: PATH_DASHBOARD.reportInventory.inventoryAdjustments},
          { title: 'Inventory Forensics', path: PATH_DASHBOARD.reportInventory.inventoryForensics},
          { title: 'Inventory Audit Report', path: PATH_DASHBOARD.reportInventory.inventoryAudit},
          { title: 'Destruction Events', path: PATH_DASHBOARD.reportInventory.destructionEvents},
          { title: 'Products', path: PATH_DASHBOARD.reportInventory.productsReport},
          { title: 'Product Pricing', path: PATH_DASHBOARD.reportInventory.productPricing},
          { title: 'Receive Inventory Report', path: PATH_DASHBOARD.reportInventory.receiverInventory},
          
          { title: 'Consolidated Sale Report', path: PATH_DASHBOARD.reportInventory.consolidatedSalesReport},
          { title: 'Sales By Category', path: PATH_DASHBOARD.reportInventory.salesCategory},
          { title: 'Sales Counts', path: PATH_DASHBOARD.reportInventory.salesCount},
          { title: 'Sales Count By Hour', path: PATH_DASHBOARD.reportInventory.salesCountHour},
          { title: 'Sales Count By Day', path: PATH_DASHBOARD.reportInventory.salesCountDay},
          { title: 'Sales Count By Day And Hour', path: PATH_DASHBOARD.reportInventory.salesCountDayHour},
          { title: 'Best Sellers', path: PATH_DASHBOARD.reportInventory.bestSellers},
          { title: 'Profit and loss report', path: PATH_DASHBOARD.reportInventory.profitsalereport},
          { title: 'Best Sellers By Quantity', path: PATH_DASHBOARD.reportInventory.bestSellersQuantity},
          { title: 'Worst Sellers', path: PATH_DASHBOARD.reportInventory.worstSellers},
          { title: 'Worst Sellers By Quantity', path: PATH_DASHBOARD.reportInventory.worstSellersQuantity},
          { title: 'Discount Detail', path: PATH_DASHBOARD.reportInventory.salesDiscountDetail},
          { title: 'Discounts', path: PATH_DASHBOARD.reportInventory.salesDiscounts},
          { title: 'Discounts By Staff', path: PATH_DASHBOARD.reportInventory.salesDiscountsByStaff},
          { title: 'Discounts By Product', path: PATH_DASHBOARD.reportInventory.salesDiscountsByProduct},
          { title: 'Auto Apply Discounts', path: PATH_DASHBOARD.reportInventory.salesAutoApplyDiscounts},
          { title: 'Cash Close', path: PATH_DASHBOARD.reportInventory.salesCashClose},
          { title: 'Payments', path: PATH_DASHBOARD.reportInventory.payments},
          { title: 'Payouts', path: PATH_DASHBOARD.reportInventory.payouts},
          { title: 'Sales Trends Report', path: PATH_DASHBOARD.reportInventory.salesTrends},
          { title: 'Sales Demographics Report', path: PATH_DASHBOARD.reportInventory.salesDemographicsReport},
            {
              title: 'Sales Report',path: PATH_DASHBOARD.reportInventory.sales,
              children:[
                { title: 'Sales', path: PATH_DASHBOARD.reportInventory.sales},
              { title: 'Consolidated Sale Report', path: PATH_DASHBOARD.reportInventory.consolidatedSalesReport},
              { title: 'Sales By Category', path: PATH_DASHBOARD.reportInventory.salesCategory},
                { title: 'Sales Counts', path: PATH_DASHBOARD.reportInventory.salesCount},
              { title: 'Sales Count By Hour', path: PATH_DASHBOARD.reportInventory.salesCountHour},
              { title: 'Sales Count By Day', path: PATH_DASHBOARD.reportInventory.salesCountDay},
              { title: 'Sales Count By Day And Hour', path: PATH_DASHBOARD.reportInventory.salesCountDayHour},
              { title: 'Best Sellers', path: PATH_DASHBOARD.reportInventory.bestSellers},
              { title: 'Best Sellers By Quantity', path: PATH_DASHBOARD.reportInventory.bestSellersQuantity},
              { title: 'Worst Sellers', path: PATH_DASHBOARD.reportInventory.worstSellers},
              { title: 'Worst Sellers By Quantity', path: PATH_DASHBOARD.reportInventory.worstSellersQuantity},
              { title: 'Discount Detail', path: PATH_DASHBOARD.reportInventory.salesDiscountDetail},
              { title: 'Discounts', path: PATH_DASHBOARD.reportInventory.salesDiscounts},
              { title: 'Discounts By Staff', path: PATH_DASHBOARD.reportInventory.salesDiscountsByStaff},
              { title: 'Discounts By Product', path: PATH_DASHBOARD.reportInventory.salesDiscountsByProduct},
              { title: 'Auto Apply Discounts', path: PATH_DASHBOARD.reportInventory.salesAutoApplyDiscounts},
              { title: 'Cash Close', path: PATH_DASHBOARD.reportInventory.salesCashClose},
              { title: 'Payments', path: PATH_DASHBOARD.reportInventory.payments},
              { title: 'Payouts', path: PATH_DASHBOARD.reportInventory.payouts},
              { title: 'Voided Tickets', path: PATH_DASHBOARD.reportInventory.voidTickets},
              { title: 'Sales Trends Report', path: PATH_DASHBOARD.reportInventory.salesTrends},
              { title: 'Refund Sales Report', path: PATH_DASHBOARD.reportInventory.refundReport},
              { title: 'Sales Demographics Report', path: PATH_DASHBOARD.reportInventory.salesDemographicsReport},
              { title: 'Complex Sale Report', path: PATH_DASHBOARD.reportInventory.complexSalesReport},
            ]
            },
         
          { title: 'Production Process', path: PATH_DASHBOARD.reportInventory.productProcess },
          { title: 'Brokers Split Up', path: PATH_DASHBOARD.reportInventory.allBrokersSplitUp },
          { title: 'General Fund Report', path: PATH_DASHBOARD.reportInventory.generalFundReport},
          { title: 'Customers Report', path: PATH_DASHBOARD.reportInventory.customers},
          { title: 'THC Report', path: PATH_DASHBOARD.reportInventory.thcReport},
          { title: 'Waste', path: PATH_DASHBOARD.reportInventory.wasteReport},
        
      ]
    },
    
      // { title: 'Warehouse', path: PATH_DASHBOARD.general.analytics, icon: <DescriptionOutlined /> },
      // { title: 'Transportation', path: PATH_DASHBOARD.general.analytics, icon: <DirectionsCarFilledOutlined />, },
      // { title: 'My Calendar', path: PATH_DASHBOARD.calendar, icon: <CalendarViewMonthOutlined />, },
      // { title: 'Reports', path: PATH_DASHBOARD.general.analytics, icon: <BarChartOutlined />, },
      // { title: 'Metric Locations', path: PATH_DASHBOARD.general.analytics, icon: <PlaceOutlined />, },
      // { title: 'Visitors', path: PATH_DASHBOARD.general.analytics, icon: <PeopleOutlined />, },
      // { title: 'Complaints and Comments', path: PATH_DASHBOARD.general.analytics, icon: <CommentOutlined /> },
      // { title: 'Website', path: PATH_DASHBOARD.general.analytics, icon: <LanguageOutlined /> },
      // { title: 'My Jobs', path: PATH_DASHBOARD.general.analytics, icon: <WorkOutlineOutlined />, },
    ]
  },


  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: '',
  //   items: [
  //     // MANAGEMENT : USER
  //     // {
  //     //   title: 'user',
  //     //   path: PATH_DASHBOARD.user.root,
  //     //   icon: ICONS.user,
  //     //   children: [
  //     //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //     //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //     //     { title: 'list', path: PATH_DASHBOARD.user.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //     //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //     //     { title: 'account', path: PATH_DASHBOARD.user.account }
  //     //   ]
  //     // },

  //     // MANAGEMENT : E-COMMERCE
  //     // {
  //     //   title: 'e-commerce',
  //     //   path: PATH_DASHBOARD.eCommerce.root,
  //     //   icon: ICONS.cart,
  //     //   children: [
  //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //     //   ]
  //     // },

  //   //   // MANAGEMENT : BLOG
  //   //   {
  //   //     title: 'blog',
  //   //     path: PATH_DASHBOARD.blog.root,
  //   //     icon: ICONS.blog,
  //   //     children: [
  //   //       { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //   //       { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //   //       { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //   //     ]
  //   //   }
  //   ]
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
  //   ]
  // }
];

export default sidebarConfig;
