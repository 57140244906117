import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Typography } from '@material-ui/core';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import SettingFullscreen from 'src/components/settings/SettingFullscreen';
import useAuth from 'src/hooks/useAuth';
import { useLocation } from 'react-router';
import FacilityPopover from './FacilityPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 5),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  display:'flex',
  justifyContent:'space-between',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { pathname } = useLocation();
  const isInner = pathname === '/dashboard/pov/checkIn';
  const isEditCheckIn = pathname === '/dashboard/pov/checkIn/Edit';
  const isPayment = pathname === '/dashboard/pov/payment-process';
  const { user } = useAuth();
  return (
    <>
    {!isInner && !isPayment && !isEditCheckIn&&(<RootStyle>
      <ToolbarStyle>
      <Stack sx={{display:'flex',justifyContent:{xs:'flex-start',sm:'center'}}}>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        </Stack>
        {/* <Typography variant='h6' color={'text.primary'} sx={{mt:1,display:{xs:'none',md:'flex'},justifyContent:'center',alignContent:'center',}}>Welcome</Typography>  {user?.facility_name || 'Get Go'} */}
        <Stack direction="row" alignContent={'center'} alignItems={'center'} spacing={{ xs: 0.5, sm: 1.5, }} sx={{display:'flex',justifyContent:'flex-end'}}>

          {/* <LanguagePopover /> */}
          {/* <ContactsPopover /> */}

            {/* <FacilityPopover />  */}
            <SettingFullscreen />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>)}
    </>
  );
  
}
