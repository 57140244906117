import { forwardRef, useState } from 'react'
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

const Transition = forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
    timeout={{ enter: 600, exit: 600 }}
  />
))

export default function DeleteConfirmation({
  isOpen,
  onClickConfirm,
  onDelete,
  message,
}) {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDelete}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      sx={{ '& .MuiDialog-paper': { width: '350px' } }}
    >
      <DialogTitle id="alert-dialog-slide-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="error"
          variant="contained"
          onClick={onDelete}
          sx={{ cursor: 'pointer' }}
        >
          No
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'success.dark',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={() => {onClickConfirm(); window.location.reload();}}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
