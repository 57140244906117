import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { set, sub, formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  Stack
} from '@material-ui/core';
// utils
import { mockImgAvatar } from '../../utils/mockImages';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: 'answered to your comment on the Minimal',
    avatar: mockImgAvatar(2),
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false
  }
];

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {moment(notification.created_at).format('MM/DD/YY h:mm A')}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.operation)}
      </Typography>
    </Typography>
  );

}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification }) {
  console.log(notification, '---------notification');

  return (
    <ListItem
      button
      disableGutters
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        }),
        display: 'flex',
        flexDirection: 'column', // Align children side by side
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}
    >
      {/* Left section for operation */}
      <Box sx={{ flex: 1, }}>
        <Typography variant="subtitle2">
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {notification.operation}
          </Typography>
        </Typography>
      </Box>

      {/* Right section for time */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' ,mt:1}}>
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.disabled'
          }}
        >
          <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
          {moment(notification.created_at).format('MM/DD/YY h:mm A')}
        </Typography>
      </Box>
    </ListItem>
  );
}

const DotBadge = () => (
  <Box
    sx={{
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: 'red',
      position: 'absolute',
      top: 0,
      right: 4,
      border: '2px solid white', 
    }}
  />
);
export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const { user } = useAuth()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const [notificationsCount, setNotificationsCount] = useState(false);

console.log("hbdfbshdfsdhfbshdbfshfshdf",notificationsCount)
  const getAllLeafmanagementData=()=>{
    axios.post(`${REST_API_END_POINT}leaf-management/get-all`,{
        facility_id:user?.facility_id,
        selected_facility_id:-1,
        limit:5,
         search:'',
        module_id:null,
        start_date:null,
        end_date:null,
        from_bell:true,
    })
    .then((res)=>{
        if(res.data.status === 1){
            // setFacilites(res.data.facilities)
            setNotifications(res.data.leaf_management)
            if(res.data.leaf_management.length>=1){
            setNotificationsCount(true)
        }
            // setModules(res.data.modules.filter(row=>row.id !== 23 && row.id !== 12 ))
        }else{
          setNotifications([])
        }
    })
    .catch((err)=>{
        console.log(err,'Error ')
    })
}

useEffect(()=>{
getAllLeafmanagementData()
},[open])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMarkAllAsRead = () => {
    // Make an API call to update the status of notifications to 0
    axios
      .post(`${REST_API_END_POINT}leaf-management/update-status`, {
        notification_ids: notifications.map((notification) => notification.id),
        status: 0,
      })
      .then((res) => {
        if (res.data.status === 1) {
          console.log("Notification statuses updated successfully");
          // Optionally, update the local state
          setNotifications((prevNotifications) =>
            prevNotifications.map((notification) => ({
              ...notification,
              isUnRead: false,
            }))
          );
        } else {
          console.error("Failed to update notification statuses");
        }
      })
      .catch((err) => {
        console.error("Error updating notification statuses:", err);
      });
      setNotificationsCount(false)
    setOpen(false);
  };
  

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map((notification) => ({
  //       ...notification,
  //       isUnRead: false
  //     }))
  //   );
  // };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={(open || notificationsCount) ? 'primary' : 'default'}
        sx={{
          ...((open || notificationsCount) && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge overlap='circular'       
          badgeContent={
            (open || !notificationsCount) ? null : <DotBadge />
          }>
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have new messages
            </Typography>
          </Box>
          <Tooltip title="Mark all as read">
          <MIconButton
        onClick={handleMarkAllAsRead}
        sx={{
          color: '#4a4a4a', // Dark grey color
        }}
      >
       <span class="octicon--read-16" style={{fontSize:'1.1rem'}}/>
      </MIconButton>
    </Tooltip>
          <Tooltip title="Close">
          <MIconButton
        onClick={handleClose}
        sx={{
          color: '#4a4a4a', // Dark grey color
        }}
      >
        <span class="jam--close"></span>
      </MIconButton>
    </Tooltip>
          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 240, sm: 340 } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications?.map((notification,index) => (
              <NotificationItem key={index} notification={notification} />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={()=>navigate(PATH_DASHBOARD.leafManagement.leaf)}  >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
