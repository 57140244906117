import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Popover, Grid } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { BugFixes, Build, Features } from 'src/constants/DefaultValues';
import personFill from '@iconify/icons-eva/person-fill';

// MENU_OPTIONS remains the same
const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: PATH_DASHBOARD.general.app
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_DASHBOARD.user.account
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.settings
  },
];

export default function AccountPopover() {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  // Handle hover popover
  const [hover, setHover] = useState(null);
  const handleHoverOpen = (event) => {
    setHover(event.currentTarget);
  };

  const handleHoverClose = () => {
    setHover(null);
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ whiteSpace: 'nowrap', color: 'text.secondary', cursor: 'pointer',pl:{xs:1,md:0.1},pr:{xs:1,md:0.1} }}
        aria-owns={hover ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleHoverOpen}
        onMouseLeave={handleHoverClose}
      >
        Version {Build}
      </Typography>

      <Popover
        id="mouse-over-popover"
        open={Boolean(hover)}
        anchorEl={hover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleHoverClose}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none'
        }}
        PaperProps={{
          sx: { width: 250, pl: 2, pr: 2, pt: 2, pb: 1 },
        }}
      >
        {Features?.length > 0 && <Typography variant="h6" gutterBottom>
          Features
        </Typography>}
        <Grid container direction="column">
          {Features?.map((feature,index) => (
             <Grid item  spacing={2} sx={{display:'flex',flexDirection:'row',alignContent:'center',alignItems:'center'}} key={index}>
              <span className='radix-icons--dot-filled'></span>
              <Typography variant="subtitle2" sx={{fontWeight:400}}>{feature.label}</Typography>
            </Grid>
          ))}
        </Grid>

        {BugFixes?.length > 0 && <Typography variant="h6" gutterBottom sx={{mt:1.5}}>
          Bug Fixes
        </Typography>}
        <Grid container direction="column" sx={{mb:0.5}}>
          {BugFixes?.map((bug,index) => (
            <Grid item  spacing={2} sx={{display:'flex',flexDirection:'row',alignContent:'center',alignItems:'center'}} key={index}>
              <span className='radix-icons--dot-filled'></span>
              <Typography variant="subtitle2" sx={{fontWeight:400,}}>{bug.label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Popover>

      <Typography
        variant="subtitle2"
        sx={{ whiteSpace: 'nowrap', color: 'primary.main', pr: 1,display:{xs:'none',md:'flex'} }}
      >
        {user?.first_name + ' ' + user?.last_name}
      </Typography>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.full_name ? user?.full_name : ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email || 'admin@bagempire.com'}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS
          .filter((option) => {
            // Show all options except "Profile" if user_type is not 2
            if (option.label === 'Profile' && user?.user_type === 3) {
              return false;
            }
            return true;
          })
          .map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />
              {option.label}
            </MenuItem>
          ))
        }
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}