import { useNavigate } from 'react-router-dom';
import { Badge, Paper, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import useAuth from 'src/hooks/useAuth';

// SVG Icon for the cart with updated styling
const CartIcon = () => (
  <svg
    width="28"  // Adjust width to match your image
    height="24" // Adjust height
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 22C7.10457 22 8 21.1046 8 20C8 18.8954 7.10457 18 6 18C4.89543 18 4 18.8954 4 20C4 21.1046 4.89543 22 6 22Z"
      fill="#000"
    />
    <path
      d="M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z"
      fill="#000"
    />
    <path
      d="M1 1H4L6 16H19L23 5H7"
      stroke="#000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Styling for the cart button
const CartButton = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  minWidth: 'auto',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  marginLeft:'8px',
  cursor:'pointer',
  marginTop:'2px',
  '&:hover': { opacity: 0.72 }
});

export default function CartWidget({ cartItems }) {
  const navigate = useNavigate();
  const { user } = useAuth()
  const storedCart = JSON.parse(localStorage.getItem('shop_cart')) || {};
  const customerCart = storedCart[user?.customer_id];
  console.log('cartItems-1--',cartItems);
  cartItems = cartItems || customerCart;
  const totalItems = cartItems?.length || 0;
  console.log('cartItems---',cartItems);
  const handleNavigate = () => {
    navigate('/checkout', { state: { cartItems } });
  };

  return (
    <CartButton onClick={handleNavigate}>
      <Badge
        badgeContent={totalItems}
        color="primary"
        showZero
        max={99}
        sx={{
          '& .MuiBadge-badge': {
            top: -5,
            right: -2, 
            width: 20, 
            height: 20, 
            borderRadius: '50%',
            backgroundColor: 'red', 
            color: '#FFF',
            fontSize: '0.8rem',
          },
        }}
      >
        <CartIcon />
      </Badge>
      <Typography variant='subtitle2' sx={{ml:1,display:{xs:'none',sm:'flex'}}}>Cart</Typography>
    </CartButton>
  );
}
