import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  Link,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  useTheme
} from '@material-ui/core';
// utils
import { fData } from '../../utils/formatNumber';
//
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadIllustration } from '../../assets';
import { useCallback, useState } from 'react';
import { storage } from 'src/firebase/firebase';

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  documentURLs: PropTypes.string,
  setDocumentURLs: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files = [],
  documentURLs,
  setDocumentURLs,
  onRemove,
  onRemoveAll,
  sx,
  ...other
}) {
  const [uploadedURLs, setUploadedURLs] = useState([]);
  const hasFile = files?.length > 0;
  const theme = useTheme();
  const [isUploadingMulti, setIsUploadingMulti] = useState(false);
  const [progressMulti, setProgressMulti] = useState(0);

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        files.push(file);
      });
      setUploadedURLs([]);
    },
    ...other
  });

  const uploadFiles = useCallback(
    () => {
      setIsUploadingMulti(true);
      const uploadPromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          if (file) {
            const filename = new Date().getTime() + file.name;
            const storageRef = storage.ref(filename);
            const uploadTask = storageRef.put(file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressMulti((prevProgress) => Math.max(prevProgress, progress));
              },
              (error) => {
                console.error(error.message);
                reject(error);
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  const fileInfo = {
                    url: downloadURL,
                    size: file?.size,
                    type: file?.type,
                    extension: file?.name?.split('.')?.pop()
                  };
                  resolve(fileInfo);
                }).catch((error) => {
                  reject(error);
                });
              }
            );
          } else {
            resolve(null);
          }
        });
      });

      Promise.all(uploadPromises)
        .then((fileInfos) => {
          const filteredInfos = fileInfos.filter((info) => info !== null);
          setDocumentURLs(filteredInfos);
          setUploadedURLs(filteredInfos);
        })
        .catch((error) => {
          console.error('Error uploading files:', error);
        })
        .finally(() => {
          setIsUploadingMulti(false);
        });
    },
    [files, setDocumentURLs]
  );

  const handleRemove = (file) => {
    onRemove(file);
    setUploadedURLs([]);
    // setUploadedURLs((prev) => prev.filter((url) => !url.includes(file.name)));
  };

  const handleRemoveAll = () => {
    onRemoveAll();
    setUploadedURLs([]);
  };

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections?.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    isUploadingMulti ? 
      <>
        <Typography sx={{color: theme.palette.primary.main, mt:2, mb:2}}>Uploading...</Typography>
        <LinearProgress variant="determinate" value={progressMulti} />
      </> :
      <Box sx={{ width: '100%', ...sx }}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            })
          }}
        >
          <input {...getInputProps()} />

          <UploadIllustration sx={{ width: 220 }} />

          <Box sx={{ p: 3, ml: { md: 2 } }}>
            <Typography gutterBottom variant="h5">
              Drop or Select file
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Drop files here or click&nbsp;
              <Link underline="always">browse</Link>&nbsp;through your machine
            </Typography>
          </Box>
        </DropZoneStyle>

        {fileRejections.length > 0 && <ShowRejectionItems />}

        <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
          <AnimatePresence>
            {files?.map((file) => {
              const { name, size, preview } = file;
              const key = isString(file) ? file : name;
              const isUploaded = hasFile && uploadedURLs.length === files.length;

              if (showPreview) {
                return (
                  <ListItem
                    key={key}
                    component={motion.div}
                    {...varFadeInRight}
                    sx={{
                      p: 0,
                      m: 0.5,
                      width: 80,
                      height: 80,
                      borderRadius: 1.5,
                      overflow: 'hidden',
                      position: 'relative',
                      display: 'inline-flex'
                    }}
                  >
                    <Paper
                      variant="outlined"
                      component="img"
                      src={isString(file) ? file : preview}
                      sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                    />
                    {isUploaded && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'end',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: 'white',
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          opacity: 1,
                          transition: 'opacity 0.3s',
                        }}
                      >
                        Uploaded
                      </Box>
                    )}
                    <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                      <MIconButton
                        size="small"
                        onClick={() => handleRemove(file)}
                        sx={{
                          p: '2px',
                          color: 'common.white',
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                          '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                          }
                        }}
                      >
                        <Icon icon={closeFill} />
                      </MIconButton>
                    </Box>
                  </ListItem>
                );
              }

              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    <MIconButton edge="end" size="small" onClick={() => handleRemove(file)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </AnimatePresence>
        </List>
        {(hasFile && uploadedURLs.length !== files.length) && (
          <Stack direction="row" justifyContent="flex-end">
            <Button onClick={handleRemoveAll} sx={{ mr: 1.5 }}>Remove all</Button>
            <Button variant="contained" onClick={uploadFiles}>Upload files</Button>
          </Stack>
        )}
      </Box>
  );
}
