import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { paramCase } from 'change-case'
import { useRef, useState } from 'react'
import editFill from '@iconify/icons-eva/edit-fill'
import { Link as RouterLink } from 'react-router-dom'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
// routes

import { AccountBalanceWallet, BlockOutlined, Payment, TaskAltOutlined, Visibility } from '@material-ui/icons'
import { PATH_DASHBOARD } from 'src/routes/paths'


// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string,
}

export default function UserMoreMenu({
  onDelete,
  onEdit,
  status,
  onActivation,
  viewTrue,
  onView,
  addToAcc,
  account,
  viewInvoice,
  editPermission,
  deletePermission,
  activePermission,
  onPasswordChange,
}) {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {onEdit && (
        <MenuItem onClick={() =>{setIsOpen(false);onEdit();}}         
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        )}
        {onDelete && (
        <MenuItem onClick={() =>{setIsOpen(false);onDelete();}}           
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        )}
        {viewTrue && (
          <MenuItem onClick={() =>{setIsOpen(false);onView();}} 
          // sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Visibility width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {viewInvoice && (
         <MenuItem onClick={() =>{setIsOpen(false);viewInvoice();}} 
        //  sx={{ color: 'text.secondary' }}
         >
          <ListItemIcon>
            <Payment width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Invoice"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        )}
        {addToAcc && (
        <MenuItem onClick={() =>{setIsOpen(false);addToAcc();}} 
        // sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            {account === 1 ?
              <Visibility />
            :
              <AccountBalanceWallet width={24} height={24} />
            }
            
          </ListItemIcon>
          <ListItemText
            primary={account === 1 ? "View Details" : "Add To Account"}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        )}
        { onActivation && (
        <MenuItem
          sx={{
            color: status === 1 ? 'error.main' : 'success.dark',
            cursor: 'pointer',
          }}
          onClick={() =>{setIsOpen(false);onActivation();}}       
        >
          <ListItemIcon>
            {status === 1 ? (
              <BlockOutlined sx={{ width: 25, height: 25 }} />
            ) : (
              <TaskAltOutlined sx={{ width: 25, height: 25 }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={`${status === 1 ? 'Inactive' : 'Active'}`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        )}
        { onPasswordChange && (
        <MenuItem onClick={() =>{ setIsOpen(false); onPasswordChange(); }} >
          <ListItemIcon>
            <TaskAltOutlined sx={{ width: 25, height: 25 }} />
          </ListItemIcon>
          <ListItemText
            primary={`Change Password`}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        )}
        
      </Menu>
    </>
  )
}
