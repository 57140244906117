import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import BaseOptionChart from './BaseOptionChart';

// ----------------------------------------------------------------------

export default function ChartColumnSingle({ graphData }) {
  const earningGraph = graphData.earning_graph || {};

  const Earnings = [
    earningGraph.JAN || 0,  
    earningGraph.FEB || 0,  
    earningGraph.MAR || 0,  
    earningGraph.APR || 0, 
    earningGraph.MAY || 0, 
    earningGraph.JUN || 0, 
    earningGraph.JUL || 0,  
    earningGraph.AUG || 0, 
    earningGraph.SEP || 0,  
    earningGraph.OCT || 0,  
    earningGraph.NOV || 0, 
    earningGraph.DEC || 0   
  ];

  const CHART_DATA = [
    {
      name: 'Earning',
      data: Earnings,  
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '14%', borderRadius: 4 } },
    stroke: { show: false },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: {
        style: {
          colors: '#212B36', 
        },
      },
      axisBorder: {
        show: true,
        color: '#212B36',
      },
      axisTicks: {
        show: true,
        color: '#212B36', 
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#212B36',
        },
      },
      axisBorder: {
        show: true,
        color: '#212B36',
      },
      axisTicks: {
        show: true,
        color: '#212B36', 
      },
    },
    grid: {
      borderColor: '#212B36',
      strokeDashArray: 4,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `$ ${val}`; 
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: { show: false },
      background: 'transparent',
    },
  });

  return <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={250} />;
}