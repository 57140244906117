import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '',item='', ...other }) {
  return (
    <Paper {...other}>
      {searchQuery != '' ?
      <>
        <Typography gutterBottom align="center" variant="subtitle1">
          Not found
        </Typography>
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
        </Typography>
      </> : 
      (item ? 
      <Typography gutterBottom align="center" variant="subtitle1">
        No {item} found
      </Typography> : null)}
    </Paper>
  );
}
