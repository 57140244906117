import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DocsLayout from '../layouts/docs';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import Brand from 'src/pages/dashboard/dashbordbeta/Inventory/Brand/Brand';
import AddeditBrand from 'src/pages/dashboard/dashbordbeta/Inventory/Brand/AddeditBrand';
import TaxCategory from 'src/pages/dashboard/dashbordbeta/Inventory/Tax Category/TaxCategory';
import AddeditTaxCategory from 'src/pages/dashboard/dashbordbeta/Inventory/Tax Category/AddeditTaxCategory';
import CheckInEdit from 'src/pages/dashboard/dashbordbeta/PointofSale/CustomerLookup/CheckInEdit';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'dashboard-settings', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'settings', element: <BatmanSettings /> },
        { path: 'printer-settings', element: <PrintSettingsMain /> },
        { path: 'contact-form-builder', element: <ContactFormBuilder /> },
        {
          path: 'coming-soon',
          element: <GeneralAnalytics />
        },
        {
          path: 'e-commerce',
          children: [
            { path: '/', element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> }
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'coupon', element: <CouponList /> },
            { path: 'customerpoint', element: <CustomerPoint /> },
            { path: 'buy-one-get-one-offer', element: <BuyOneGetOneOffer /> },
            { path: 'buy-one-get-one-offer', element: <BuyOneGetOneOffer /> },
            { path: 'customer-point-setting', element: <CustomerSettings /> },
            { path: 'customer-type', element: <CustomerTypes /> },
            { path: 'customer-referrals', element: <CustomerRefferals /> },
            { path: 'allcustomers', element: <AllcList /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/:name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },

        {
          path: 'vendor',
          children: [
            { path: 'allventors', element: <Allventors /> },
            { path: 'all-brokerage-account', element: <AllServices /> },
            { path: 'brokers-split-up', element: <AllBrokersSplitup /> },
            { path: 'completed-order', element: <CompletedOrders /> },
            { path: 'all-brokerage-account/add-new-broker', element: <AddNewBroker /> },
            // { path: 'allservices', element: <AllServices /> },
            { path: 'allventypes', element: <AllvenTypes /> },
            { path: 'all-brokers', element: <AllAccounts /> },
            { path: 'all-brokers/view-account', element: <ViewAccount /> },
            { path: 'receiveInventory', element: <ReceiverInven /> },
            // { path: 'add-receiverInven', element: <AddInventory /> },
            { path: 'add-vendor', element: <AddVentor /> },
          ]
        },
        {
          path: 'add',
          children: [
            { path: 'newcustomer', element: <NewCustomer /> },
            { path: 'addeditcustomer', element: <AddeditCustomer /> },
            { path: 'viewcustomer', element: <ViewCustomer /> },
            { path: 'addnewcoupon', element: <AddnewCoupon /> },
            { path: 'addeditcoupon', element: <AddeditCoupon /> },
            { path: 'menubuttons', element: <MenuButtons /> },
            { path: 'view-coupon', element: <ViewCoupon /> },
            { path: 'add-edit-point ', element: <AddeditPoint /> },
            { path: 'add-customer-point', element: <AddcustomerPoint /> },
            { path: 'point-menu', element: <PointMenu /> },
            { path: 'view-c-point', element: <ViewcPoint /> },
          ]
        },
        {
          path: 'pov',
           children: [
            { path: 'customerLookup', element: <CustomerLookup /> },
            { path: 'checkIn', element: <CheckIn /> },
            { path: 'checkIn/edit', element: <CheckInEdit /> },
            { path: 'shopCard', element: <ShopCard /> },
            { path: 'payment-process', element: <PaymentProcess /> },
            { path: 'allOrders', element: <AllOrdersPOS /> },
            { path: 'order-ahead', element: <OrderHead /> },
            { path: 'drawers-managements', element: <DrawersManagements /> },
          ]
        },
        {
          path: 'bank',
           children: [
            { path: 'drawers-managements', element: <DrawersManagements/> },
            { path: 'drawer-details', element: <DrawersDetails/> },
          ]
        },
        {
          path: 'staff-management',
           children: [
            { path: 'staff-directory', element: <StaffDirectory/> },
            { path: 'user-roles', element: <UserRoles/> },
            { path: 'scheduler-calendar', element: <SchedulerCalendar/> },
          ]
        },
        {
          path: 'transporation',
           children: [
            { path: 'transporation', element: <TransportationTable/> },
            { path: 'drivers', element: <TransportationDriversTable/> },
            { path: 'shipper-information', element: <ShipperInformationForm/> },
            { path: 'vehicles', element: <TransportationVehicleTable/> },
          ]
        },
        { path: 'leaf-management', element: <LeafManagement /> },
        {
          path: 'website',
           children: [
            { path: 'home-sliders', element: <WebsiteModuleHomeSlider/> },
            { path: 'testimonials', element: <WebsiteModuleTestimonial/> },
            { path: 'pages', element: <WebsiteModulePages/> },
            { path: 'settings', element: <WebsiteModuleSettingsForm/> },
            { path: 'deals', element: <WebsiteModuleDeals/> },
            { path: 'footer-management', element: <FooterManagementForm/> },
            { path: 'customer-signup', element: <CustomerSignUp/> },
            { path: 'customer-enquiry', element: <EnquiryUserTable/> },
            { path: 'order-enquiry', element: <OrderEnquiry/> },
          ]
        },
        {
          path:'inventory',children:[
            {path:'rooms',element:<Rooms/>},
            {path:'addroom',element:<AddRoom/>},
            {path:'addeditroom',element:<AddeditRoom/>},
            {path:'ViewRoom',element:<RoomOpen/>},
            {path:'inventory',element:<Inventory/>},
            {path:'addeditinventory',element:<AddeditInventory/>},
            {path:'audit-inventory',element:<InventoryAudit/>},
            {path:'pricing-group',element:<PricingGroup/>},
            {path:'add-edit-price',element:<AddeditPrice/>},
            {path:'strain',element:<Strains/>},
            {path:'add-edit-strain',element:<AddeditStrain/>},
            {path:'type',element:<Type/>},
            {path:'add-edit-type',element:<AddeditType />},
            {path:'brand',element:<Brand/>},
            {path:'add-edit-brand',element:<AddeditBrand />},
            {path:'taxCategory',element:<TaxCategory />},
            {path:'add-edit-taxCategory',element:<AddeditTaxCategory />},
            {path:'product-category',element:<ProductCategory />},
            {path:'product-sub-category',element:<ProductSubCategory />},
          ]
        },
        {
          path:'report-inventory',
          children:[
            { path: 'current-inventory', element: <ReportInventory/> },
            { path: 'historical-inventory', element: <HistoricalInventory/> },
            { path: 'inventory-adjustments', element: <InventoryAdjustments/> },
            { path: 'inventory-forensics', element: <InventoryForensics/> },
            { path: 'inventory-audit', element: <InventoryAuditReport/> },
            { path: 'destruction-events', element: <DestructionEvents/> },
            { path: 'products', element: <ProductsReport/> },
            { path: 'product-pricing', element: <ProductPricing/> },
            { path: 'kitchen', element: <KitchenReport/> },
            { path: 'receive-inventory', element: <ReceiveInventory/> },
            {path:'sales',element:<SalesReportModule/>},
            {path:'sales-report-count',element:<SalesCountReportModule/>},
            {path:'sales-report-count-by-day',element:<SalesCountDayReportModule/>},
            {path:'report-sales-count-by-hour',element:<SalesCountHourReportModule/>},
            {path:'report-sales-count-by-day-hour',element:<SalesDayHourReportModule/>},
            {path:'best-sellers',element:<BestSellersSaleReport/>},
            {path:'best-sellers-by-quantity',element:<BestSellersQuantitySalesReport/>},
            {path:'worst-sellers',element:<WorstSellersSaleReport/>},
            {path:'worst-sellers-by-quantity',element:<WorstSellersQuantitySalesReport/>},
            {path:'sales-by-category',element:<SalesReportCategory/>},
            {path:'production-process',element:<ProductProcess/>},
            {path:'payments',element:<PaymentsSalesReport/>},
            {path:'payouts',element:<PayoutsSalesReport/>},
            {path:'void-tickets',element:<VoidTicketsSalesReport/>},
            {path:'discount-detail',element:<DiscountDetailsSalesReport/>},
            {path:'discounts',element:<DiscountsReportSales/>},
            {path:'sales-discounts-by-staff',element:<DiscountsByStaffReportSales/>},
            {path:'sales-trends-report',element:<SalesTrendsReport/>},
            {path:'profit-loss-report',element:<ProfitLossReport/>},
            {path:'sales-discounts-by-products',element:<DiscountsByProductReportSales/>},
            {path:'auto-apply-discounts',element:<AutoApplyDiscountsSalesReport/>},
            {path:'cash-close',element:<CashCloseSales/>},
            {path:'brokers-split-up',element:<AllBrokersSplitup/>},
            { path: 'waste', element: <WasteReport/> },
            { path: 'thc-report', element: <THCReport/> },
            { path: 'customers', element: <CustomersReport/> },
            { path: 'general-fund-report', element: <GeneralFundReport/> },
            { path: 'refund-report', element: <RefundReportSales/> },
            { path: 'sales-demographics-report', element: <SalesDemographicsReport/> },
            { path: 'complex-sales-report', element: <ComplexSalesReport/> },
            { path: 'consolidated-sales-report', element: <ConsolidatedSalesReport/> },
          ]
        },
        {
          path: 'blog',
          children: [
            { path: '/', element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '/', element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '/', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> }
        // { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Docs Routes
    {
      path: 'docs',
      element: <DocsLayout />,
      children: [
        { path: '/', element: <Navigate to="/docs/introduction" replace /> },
        { path: '*', element: <Docs /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/" replace /> }
      ]
    },
    {
      path: '/',
      // element: <Login />,
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingBatman /> },
        { path: 'bagempire-login', element: <Login /> },
        // { path: '/', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'shop', element: <BatmanProductsMain /> },
        { path: 'product-details/:id', element: <BatmanProductsDetailsPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'customer-signup', element:<Register/> },
        { path: 'receive-inventory-qr-code/:order_id', element: <ReceiveInventoryQRCode /> },
        { path: 'inventory-qr-code/:product_id', element: <InventoryQRCode /> },
        { path: 'finished-products-qr-code/:package_id', element: <FinishedProductsQRCode /> },
        { path: 'add-organization', element: <AddFacilityLogin /> },
        { path: 'facility-listing', element: <FacilityLisitingTableMain /> },
        { path: 'live-inventory-view', element: <LiveInventoryPage /> },
        { path: 'checkout', element: <EcommerceCheckout /> },
        { path: 'my-orders', element: <OrderedProductList /> },
        {
          path: 'components',
          children: [
            { path: '/', element: <ComponentsOverview /> },
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'transfer-list', element: <TransferList /> },
            { path: 'tree-view', element: <TreeView /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'mega-menu', element: <MegaMenu /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const BatmanProductsDetailsPage = Loadable(lazy(() => import('src/pages/dashboard/BatmanProductsDetailPage')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const CouponList = Loadable(lazy(() => import('../pages/dashboard/CouponList')));
const AllcList = Loadable(lazy(() => import('../pages/dashboard/AllcList')));
const AllvenTypes = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/AllvenType')));
const AllAccounts = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/AllBrokers')));
const ViewAccount = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/ViewBrokers')));
const AllServices = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/AllBrokerageAccount')));
const Allventors = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/AllVentors')));
const ReceiveInventoryQRCode = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/ReceiveInventoryQRcodePage')));
const InventoryQRCode = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/InventoryQRCode')));
const FinishedProductsQRCode = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/FinishedProductsQRCode')));
const CompletedOrders = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/CompletedOrders')));
const AddVentor = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/Add/AddVentor')));
const AddInventory = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddRecieveInventory')));
const ReceiverInven = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/ReceiverInven')));
const NewCustomer = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/NewCustomer')));
const AddNewBroker = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddNewBrokerageAccountForm')));
const AddeditCustomer = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddeditCustomer')));
const ViewCustomer = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/ViewCustomer')));
const MenuButtons = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/MenuButtons')));
const AddcustomerPoint = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddcustomerPoint')));
const AddnewCoupon = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddnewCoupon')));
const AddeditPoint = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddeditPoint')));
const PointMenu = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointMenu')));
const ViewCoupon = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/ViewCoupon')));
const AddeditCoupon = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/AddeditCoupon')));
const ViewcPoint = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Add/ViewcPoint')));
const CustomerLookup = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointofSale/CustomerLookup/CustomerLookup')));
const CustomerPoint = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/CustomerPoint')));
const BuyOneGetOneOffer = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/BuyOneGetOne.js')));
const CustomerTypes = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/CustomerTypes')));
const CustomerRefferals = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/CustomerRefferals')));
const CheckIn = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointofSale/CustomerLookup/CheckIn')));
const ShopCard = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointofSale/CustomerLookup/ShopCard')));
const PaymentProcess = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointofSale/CustomerLookup/PaymentProcess')));
const AllOrdersPOS = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointofSale/AllOrders/allOrders')));
const OrderAhead = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/PointofSale/AllOrders/order-ahead')));
const Rooms = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Room/rooms')));
const ReportInventory = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Add/Report-Inventory')));
const HistoricalInventory = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/HistoricalInventory')));
const LeafManagement = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/LeafManagement')));
const InventoryAdjustments = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/InventoryAdjustments')));
const InventoryForensics = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/InventoryForensics')));
const InventoryAuditReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/InventoryAuditReport')));
const DestructionEvents = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/DestructionEvents')));
const PayoutsSalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/PayoutsSalesReport')));
const SalesReportModule = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/SalesReportModule')));
const SalesCountReportModule = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/SalesCountReportModule')));
const DiscountDetailsSalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/DiscountDetailsSalesReport')));
const SalesCountDayReportModule = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/SalesCountDayReportModule')));
const SalesCountHourReportModule = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/SalesHourReportModule')));
const SalesDayHourReportModule = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/SalesDayHourReportModule')));
const ProductsReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/ProductsReport')));
const ProductPricing = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/ProductPricing')));
const KitchenReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/KitchenReport')));
const ReceiveInventory = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/ReceiveInventory')));
const WasteReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WasteReport')));
const THCReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/THCReport')));
const CustomersReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/CustomersReport')));
const GeneralFundReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/GeneralFundReport')));
const TransportationTable = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/TransportationTable')));
const RefundReportSales = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/RefundReportSales')));
const SalesDemographicsReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/SalesDemographicsReport')));
const ComplexSalesReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/ComplexSalesReport')));
const ConsolidatedSalesReport = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/ConsolidatedSalesReport')));
const AllBrokersSplitup = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Add/AllBrokersSplitUp')));
const SalesReportCategory = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Add/Sales-Report-Category')));
const ProductProcess = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Add/ProductProcess')));
const PaymentsSalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Add/PaymentsSalesReport')));
const VoidTicketsSalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Add/VoidTicketsSalesReport')));
const DiscountsReportSales = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/DiscountsReportSales')));
const BestSellersSaleReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/BestSellersSaleReport')));
const WorstSellersSaleReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/WorstSellersSaleReport')));
const BestSellersQuantitySalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/BestSellersQuantitySalesReport')));
const WorstSellersQuantitySalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/WorstSellersQuantitySalesReport')));
const DiscountsByStaffReportSales = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/DiscountsByStaffReportSales')));
const SalesTrendsReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/SalesTrendsReport')));
const ProfitLossReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/ProfitLossReport')));
const DiscountsByProductReportSales = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/DiscountsByProductReportSales')));
const AutoApplyDiscountsSalesReport = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/AutoApplyDiscountsSalesReport')));
const CashCloseSales = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/CashCloseSaleReport')));
const PricingGroup = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Pricing Groups/PricingGroups')));
const AddRoom = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Room/AddRoom')));
const AddeditRoom = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Room/AddeditRoom')));
const RoomOpen = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Room/RoomOpen')));
const Inventory = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Inventory/Inventory')));
const AddeditInventory = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Inventory/AddeditInventory')));
const AddeditPrice = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Pricing Groups/AddeditPrice')));
const InventoryAudit = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Inventory Audit/InventoryAudit')));
const AddeditStrain = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Strains/AddeditStrain')));
const Strains = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Strains/Strains')));
const Type = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Type/Type')));
const ProductCategory = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Product Category/ProductCategory')));
const ProductSubCategory = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Product Sub Category/ProductSubCategory')));
const AddeditType = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Inventory/Type/AddeditType')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Docs
const Docs = Loadable(lazy(() => import('../pages/Docs')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const PrintSettingsMain = Loadable(lazy(() => import('../pages/PrintSettingsMainPage')));
const BatmanProductsMain = Loadable(lazy(() => import('../pages/batman-products-main')));
const LandingBatman = Loadable(lazy(() => import('../pages/LandingBatman')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColor')));
const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
const DrawersManagements = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Bank/drawers-managements')));
const DrawersDetails = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/Bank/drawer-details')));
const OrderHead = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/orderhead/OrderHead')));
const StaffDirectory = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/Add/StaffDirectory')));
const UserRoles = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/Add/UserRoles')));
const WebsiteModuleHomeSlider = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/WebsiteModuleHomeSlider')));
const WebsiteModuleTestimonial = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/WebsiteModuleTestimonial')));
const WebsiteModulePages = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/WholesaleManagment/WebsiteModulePages')));
const WebsiteModuleSettingsForm = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/WebsiteModuleSettingsForm')));
const WebsiteModuleDeals = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/WebsiteModuleDeals')));
const FooterManagementForm = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/FooterManagementForm')));
const ShipperInformationForm = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/ShipperInformation')));
const CustomerSettings = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/CustomerSetting')));
const BatmanSettings = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/BatmanSettings')));
const ContactFormBuilder = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/ContactFormBuilder')));
const AddFacilityLogin = Loadable(lazy(() => import('../pages/dashboard/dashbordbeta/WholesaleManagment/AddFacilityLogin')));
const FacilityLisitingTableMain = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/WholesaleManagment/FacilityListingTableMain')));
const LiveInventoryPage = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/Inventory/Inventory/LiveInventoryPage')));
const OrderedProductList = Loadable(lazy(() => import('src/components/_dashboard/e-commerce/checkout/OrdersProductsTable')));
const CustomerSignUp = Loadable(lazy(() => import('src/pages/dashboard/CustomerSignUp')));
const OrderEnquiry = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/OrderEnquiry')));
const SimpleCustomerSignUp = Loadable(lazy(() => import('src/pages/dashboard/SimpleCustomerSignUp')));
const EnquiryUserTable = Loadable(lazy(() => import('src/pages/dashboard/CustomerEnquiryTable')));
const TransportationDriversTable = Loadable(lazy(() => import('src/pages/dashboard/TransportationDriverTable')));
const TransportationVehicleTable = Loadable(lazy(() => import('src/pages/dashboard/TransportationVehiclesTable')));
const SchedulerCalendar = Loadable(lazy(() => import('src/pages/dashboard/dashbordbeta/staff-scheduler/SchedulerCalendar')));