import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import arrowLeftFill from '@iconify/icons-eva/arrow-left-fill';
import arrowRightFill from '@iconify/icons-eva/arrow-right-fill';
// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
//
import { MIconButton } from '../../@material-extend';
import { Stack } from '@material-ui/core';

// ----------------------------------------------------------------------

const SIZE = 40;

const RootStyle = styled('div')(({ theme }) => ({
  top: 4, // Adjusts the top position of the arrows
  right: 10, // Ensures the arrows are on the right side
  zIndex: 9,
  height: SIZE,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row', // Changed to 'row' to align both arrows in the same line
  justifyContent: 'space-between'
}));

const ArrowStyle = styled(MIconButton)(({ theme }) => ({
  width: SIZE,
  height: SIZE,
  opacity: 0.7,
  marginLeft: theme.spacing(1), // Adds space between the two arrows in the row
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  border: '1px solid #ccc',
  boxShadow: (theme) => `4px 4px 8px 0 ${theme.palette.grey[500_24]}`,
  borderRadius:'50%',
  transition: theme.transitions.create('opacity'),
  '&:hover': {
    opacity: 1,
    border: '1px solid #868686',
  }
}));

// ----------------------------------------------------------------------

CarouselControlsArrowsBasic2.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func
};

export default function CarouselControlsArrowsBasic2({ onNext, onPrevious, ...other }) {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  return (
    <RootStyle {...other}>
      <ArrowStyle size="small" onClick={onPrevious}>
      <Stack className={isRTL ? 'iconamoon--arrow-right-2-thin' : 'iconamoon--arrow-left-2-thin'} sx={{fontSize:'1.6rem'}} />
      </ArrowStyle>

      <ArrowStyle size="small" onClick={onNext}>
        <Stack className={isRTL ? 'iconamoon--arrow-left-2-thin' : 'iconamoon--arrow-right-2-thin'} sx={{fontSize:'1.6rem'}} />
      </ArrowStyle>
    </RootStyle>
  );
}
