import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack, Typography } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden, MIconButton } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { AccountCircleOutlined, LockOpen, Logout, ShoppingBag, ShoppingCart } from '@material-ui/icons';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { PATH_PAGE } from 'src/routes/paths';
import { AccountChangePassword, AccountGeneral } from 'src/components/_dashboard/user/account';
import Modal from 'src/components/modal/Modal';
import { useState } from 'react';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import AccountPopoverUser from '../dashboard/AccountPopoverUser';
import CartWidget from 'src/components/_dashboard/e-commerce/CartWidget';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 88;
// const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar({cart}) {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const isCart = pathname === '/checkout';
  const isDetailsPage = pathname === '/product-details';
  const navigate=useNavigate()
  const { user,logout } = useAuth(); 
  const { enqueueSnackbar } = useSnackbar();
  const [clientDetails,setClientDetails] = useState({})
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  const [openProfile,setOpenProfile] = useState(false)
  const [changePasswordVendor, setChangePasswordVendor] = useState(null)

  const handleLogout = async () => {
   
    try {
      await logout();
      enqueueSnackbar('Logout successful', { variant: 'success' });
      navigate('/');      
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  
  // const updatedNavConfig = [
  //   ...navConfig,
  //   ...(user?.user_type === 3 ? [{
  //     title: 'My Orders',
  //     icon: <ShoppingBag />,
  //     path: PATH_PAGE?.myOrders
  //   }] : []),
  // ].map((item) => {
  //   // Update 'My Account' to 'Logout' if user_type is 3
  //   if (item.title === 'My Account' && user?.user_type === 3) {
  //     return null;
  //   }
  //   if (item.title === 'My Account' &&user !==null&&  user?.user_type !== 3) {
  //     return {
  //       ...item,
  //       title: 'Dashboard',        
  //       path: '/dashboard/app',
  //     };
  //   }
  //   return item;
  // });
  const updatedNavConfig = [
    ...navConfig,
    // ...(user?.user_type === 3 ? [{
    //   title: 'My Orders',
    //   icon: <ShoppingBag />,
    //   path: PATH_PAGE?.myOrders
    // }] : []),
  ]
  .filter(item => !(item.title === 'My Account' && user?.user_type === 3)) // Filter out 'My Account' if user_type is 3
  .map((item) => {
    if (item.title === 'My Account' && user !== null && user?.user_type !== 3) {
      return {
        ...item,
        title: 'Dashboard',
        path: '/dashboard/app',
      };
    }
    return item;
  });
  
  
  const handleClickChangePassword = () => {
    setChangePasswordOpen(true);
    setChangePasswordVendor(user.customer_id);
  }

  const handleClickChangePasswordClose = () => {
    setChangePasswordOpen(false);
    setChangePasswordVendor(null);
  }
 const handleCloseProfile=()=>{
  setOpenProfile(false)
 }

 const passWordSubmittingCall = async (changePasswordVendor, values, user_id) => {
  try {
    const response = await axios.post(`${REST_API_END_POINT}customer/change-password`, {
      customer_id: changePasswordVendor,
      values,
      user_id: user_id,
      facility_id:user?.facility_id,
    });
    return response;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};
const fetchClientDetails=()=>{
  axios.post(`${REST_API_END_POINT}profile/get-client-details`,{
    user_id:user.id
  })
  .then((res)=>{
    if(res.data.status === 1 ){
      setClientDetails(res.data.data)
      setOpenProfile(true)
    }
  })
  .catch((err)=>{
    console.log(err,"error in getting client details") 
  })
}

  return (
    <AppBar color={isHome ? 'default' : 'default'} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            // height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={updatedNavConfig} />
          </MHidden>
          

          
          {/* {user?.customer_id && (
  <Box sx={{ mr: 1 }}>
    <Stack
      component="a"
      onClick={() => handleClickChangePassword()}
      direction="row"
      sx={{
        backgroundColor: { xs: 'transparent', md: 'transparent' },
        padding: 1.5,
        border: { xs: 'none', md: '1px solid #000' },
        gap: 1,
        alignItems: 'center',
        borderRadius: 0.6,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'transparent' },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '0', sm: '14px' },
          textTransform: 'uppercase',
          fontWeight: 600,
        }}
        variant="subtitle2"
      >
        Change Password
      </Typography>

      <LockOpen 
      sx={{ fontSize: { xs: '1rem', sm: '1.3rem' },
       display: { xs: 'block', md: 'none' } }}
        />
    </Stack>
  </Box>
)}

{user?.customer_id && (
  <Box>
    <Stack
      component="a"
      onClick={() =>{fetchClientDetails()}}
      direction="row"
      sx={{
        padding: 1.5,
        gap: 1,
        alignItems: 'center',
        borderRadius: 0.6,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'transparent' },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '0', sm: '14px' },
          textTransform: 'uppercase',
          fontWeight: 600,
        }}
        variant="subtitle2"
      >
        Profile
      </Typography>
      <AccountCircleOutlined 
      sx={{ fontSize: { xs: '1rem', sm: '1.3rem' },
       display: { xs: 'block', md: 'none' } 
       }} />
    </Stack>
  </Box>
)} */}

          <Box>
            <Stack
              component={'a'}
              onClick={() => navigate('/contact-us',)}
              direction={'row'}
              sx={{
                backgroundColor: {xs:'transparent',md:'#FDDD01'},
                padding: {xs:0,sm:1.5},
                border: {xs:'none',md:'2px solid #FDDD01'},
                gap: 1,
                alignItems: 'center',
                borderRadius: 0.6,
                cursor: 'pointer',
                '&:hover': { bgcolor: 'transparent' },
              }}
            >
              <Typography sx={{fontSize:{xs:'10px',sm:'14px'},textTransform:'uppercase',fontWeight:600,}} variant='subtitle2'>Contact Us</Typography>
              <Stack className='iconoir--headset-help' sx={{fontSize:{xs:'1rem',sm:'1.3rem'}}}/>
            </Stack>
          </Box>
          {user?.user_type === 1 &&(<Box>
            <Stack
              component="a"
              onClick={handleLogout}
              direction="row"
              sx={{
                padding: {xs:0,sm:1.5},
                gap: 1,
                alignItems: 'center',
                backgroundColor: {xs:'transparent',md:'#000'},
                borderRadius: 0.6,
                border: {xs:'none',md:'2px solid #000'},
                ml:{xs:1,md:1},
                cursor: 'pointer',
                '&:hover': { bgcolor: 'transparent','.logout':{color:'#000'} },
              }}
            >
              <Typography
              className='logout'
                sx={{
                  fontSize: {xs:'10px',sm:'14px'},
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  color:{xs:'#000',md:'#fff'},
                }}
                variant="subtitle2"
              >
                Logout
              </Typography>
              <Logout className='logout'
              sx={{ fontSize: { xs: '1rem', sm: '1.3rem' },
               display: { xs: 'block', md: 'block' } , color:{xs:'#000',md:'#fff'},
               }} />
            </Stack>
          </Box>)}
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} menuConfig={updatedNavConfig} />
          </MHidden>
          {user?.customer_id && !isCart && (<CartWidget cartItems={cart} />)}
          {user?.customer_id &&(<Box sx={{ml:1}}><AccountPopoverUser handleClickChangePassword={handleClickChangePassword} fetchClientDetails={fetchClientDetails}/></Box>)}
        </Container>
      </ToolbarStyle>

      <Modal open={changePasswordOpen} handleClose={handleClickChangePasswordClose} modalTitle={'Change Password'}>
        <AccountChangePassword
          changePasswordVendor={changePasswordVendor}
          setChangePasswordVendor={setChangePasswordVendor}
          changePasswordOpen={changePasswordOpen}
          setChangePasswordOpen={setChangePasswordOpen}
          passWordSubmittingCall={passWordSubmittingCall}
        />
      </Modal>
      <Modal open={openProfile} handleClose={handleCloseProfile} modalTitle={'Profile'}>
                 <AccountGeneral
                  fromClient={true}
                  userData={clientDetails}
                  handleCloseProfile={handleCloseProfile}
                  />
      </Modal>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}