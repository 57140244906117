import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

const DeleteConfirmationPopup = ({ open, onClose, onConfirm, itemContent, itemTitle }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{itemTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{itemContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined' color="error">
                    Cancel
                </Button>
                <Button variant='contained' onClick={onConfirm} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationPopup;
