import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';

const CheckoutConfirmation = ({ open, onClose, onConfirm, title, message, confirmText = 'Proceed', cancelText = 'Cancel' }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
          {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined'>{cancelText}</Button>
        <Button onClick={onConfirm} variant='contained' color="primary">{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutConfirmation;
