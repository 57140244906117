

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Card,
  Grid,
  Stack,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  DialogActions,
  Paper,
  Box,
  Autocomplete,
} from '@material-ui/core';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import { CUSTOMER_ORDER_STATUS } from './allOrders';
import { LoadingButton } from '@material-ui/lab';
import Scrollbar from 'src/components/Scrollbar';

export default function RefundOrder({
  invoiceOrderData,
  user_id,
  facility_id,
  order_id,
  setOpenRefund,
  refresh,
  setRefresh,
  currencies,
  drawers,
  drawerId,
  setDrawerId,
  drawersData,
  currencyCode,
  orderProduct,
  setOrderProducts,
  cashTotal,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [commentErrors, setCommentErrors] = useState({});
  const [pinEntered, setPinEntered] = useState(null);
  const [pinValidate, setPinValidate] = useState(false);
  const [config, setConfig] = useState([]);
  // let total = 0;
  console.log(config,'--------------config')
  // if (cashTotal != null) {
  //   total = parseInt(cashTotal['1_s']) + parseInt(cashTotal['2_s']) * 2 + parseInt(cashTotal['5_s']) * 5 + parseInt(cashTotal['10_s']) * 10 + parseInt(cashTotal['20_s']) * 20 + parseInt(cashTotal['50_s']) * 50 + parseInt(cashTotal['100_s']) * 100 + parseInt(cashTotal['cents']) * 0.01 + parseInt(cashTotal['nickles']) * 0.05 + parseInt(cashTotal['dimes']) * 0.1 + parseInt(cashTotal['quarters']) * 0.25 + cashTotal['50_cents'] * 0.5 + parseInt(cashTotal['1_d'])
  // }

  const totalOrderPrice = parseFloat(invoiceOrderData?.total || 0);
  const totalDiscount = parseFloat((invoiceOrderData?.point_discount || 0) + (invoiceOrderData?.coupon_discount || 0) + (invoiceOrderData?.auto_apply_discount || 0));
  // if(invoiceOrderData){
  //   total -= parseFloat(invoiceOrderData?.point_discount).toFixed(2)
  // }
  const handleSelect = async (drawer_id) => {
		let pin = pinEntered;
    setDrawerId(drawer_id);
		let user_pins = drawer_id?.user_pins
		let userPinsArray = user_pins?.split(',');
		let isPinValid = userPinsArray?.includes(pin);
		if (isPinValid) {
			setPinValidate(false);
		}else {
      setPinValidate(true);
		}
	}

	const handleInputChange = async (event) => {
		var user_pins = drawerId ? drawerId?.user_pins : ""
		let userPinsArray = user_pins?.split(',');
		let isPinValid = userPinsArray?.includes(event.target.value);
		if (isPinValid) {
      setPinValidate(false);
		} else {
      setPinValidate(true);
		}
    setPinEntered(event.target.value);
	}



  // const returnAmount = (totalPrize, id,unitprice,qty) => {
  //   setConfig((prevConfig) => {
  //     let configData = [...prevConfig];
  //     let foundConfig = configData?.find((obj) => obj?.id === id);
  //     // let toatalAmount = unitprice *qty
  //     if (foundConfig) {
  //       foundConfig.amount = parseFloat(totalPrize)?.toFixed(2);
  //       // foundConfig.amount = parseFloat(toatalAmount)?.toFixed(2);
  //     } else {
  //       configData.push({ id, amount: parseFloat(totalPrize)?.toFixed(2) });
  //       // configData.push({ id, amount: parseFloat(toatalAmount)?.toFixed(2) });
  //     }
  
  //     return configData;
  //   });
  // };

  // Function to calculate the refund amount for each item
  const returnAmount = (id, quantity) => {
    setConfig((prevConfig) => {
      const configData = [...prevConfig];
      const foundConfig = configData.find((obj) => obj.id === id);
      const productRow = orderProduct.find((row) => row.id === id);
      const productUnitPrice = parseFloat(productRow.product_price || 0);
      const totalQuantity = parseFloat(productRow.product_qty || 0);

      // Calculate discount share
      const discountShare = (productUnitPrice / totalOrderPrice) * totalDiscount;
      const discountedPrice = productUnitPrice - discountShare;

      // Calculate total amount for the returned quantity
      const amount = (parseFloat(quantity || 0) * discountedPrice).toFixed(2);

      if (foundConfig) {
        foundConfig.amount = amount;
      } else {
        configData.push({ id, amount: amount || "0.00" });
      }

      return configData;
    });
  };

  // Calculate discounts and update config for each product
  useEffect(() => {
    if (orderProduct.length > 0) {
      orderProduct.forEach((row) => {
        const productUnitPrice = parseFloat(row.product_price || 0);
        
        // Calculate each item's discount share
        const discountShare = (productUnitPrice / totalOrderPrice) * totalDiscount;

        // Calculate the discounted price
        const discountedPrice = (productUnitPrice - discountShare).toFixed(2);

        // Initialize the return amount with 0 quantity
        returnAmount(row.id, 0, discountedPrice);
      });
    }
  }, [orderProduct, totalOrderPrice, totalDiscount]);
  
  // Formik setup
  const formik = useFormik({
    initialValues: {
      orderProducts: orderProduct.map((row) => ({
        id: row.id,
        return_product_qty_value: row.return_product_qty_value || '0',
        return_product_comment_value: row.return_product_comment_value || '',
      })),
      // drawer: null,
      // drawer_pin:'',
      // total:0
    },
    // validationSchema: Yup.object().shape({
    //   orderProducts: Yup.array().of(
    //     Yup.object().shape({
    //       // return_product_qty_value: Yup.number()
    //       //   .min(0, 'Quantity cannot be less than 0')
    //       //   .required('Return quantity is required'),
    //       return_product_comment_value: Yup.string().when('return_product_qty_value', {
    //         is: (val) => val && val > 0,
    //         then: Yup.string().required('Comment is required when returning products'),
    //       })
    //       // return_product_comment_value: Yup.string().required('Comment is required when returning products'),
    //     })
    //   ),
    // }),
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        // const refundAmount =
        //   parseFloat(invoiceOrderData?.cryptoTotal || 0) +
        //   parseFloat(invoiceOrderData?.zelleTotal || 0) +
        //   parseFloat(invoiceOrderData?.cash || 0);
          
        // const total = refundAmount?.toFixed(2);
        const errors = {};
        values.orderProducts.forEach((product, index) => {
          if (product.return_product_qty_value > 0 && !product.return_product_comment_value) {
            errors[index] = 'Required for return'; // Add error if the comment is empty
          }
        });
        const total = parseFloat(config.reduce((acc, item) => acc + parseFloat(Number(item.amount) || 0), 0)).toFixed(2);
        console.log(total,'-------------vreturnedValuesTotal')
      
        if (Object.keys(errors).length) {
          setCommentErrors(errors); // Display errors
          enqueueSnackbar('Please fill in required fields.', { variant: 'error' });
          return; // Stop submission
        }
        if( !drawerId || !drawerId?.drawer_id ){
          enqueueSnackbar('Please choose drawer', { variant: 'error' });
          return;
        }
        else if(!pinEntered){
          enqueueSnackbar('Please enter drawer pin', { variant: 'error' });
          return;
        }
        else if(pinValidate){
          enqueueSnackbar('Please enter correct pin', { variant: 'error' });
          return;
        }
        else if(!total || total <= 0){
          enqueueSnackbar('The total amount must be greater than or equal to 0.01', { variant: 'error' });
          return;
        }
        else{
          const res = await axios.post(`${REST_API_END_POINT}drawer/check-drawer-stock`, {
            facility_id,
            user_id,
            drawer_id: drawerId.drawer_id,
            currency_code: currencyCode,
            values,
            return_amount: total,
            pinEntered,
            cashTotal,
            orderProduct,
          });
          if (res.data) {
            var data = res.data;
            if (data.proceed) {

              const ids = orderProduct?.map((item)=>{
                return item.id
              })
              const deal_ids = orderProduct?.map((item)=>{
                return item.deal_id
              })
              const deal_products = orderProduct?.map((item)=>{
                return item.deal_products
              })
              
              const return_product_qtys = values?.orderProducts?.map((item) => {
                return item.return_product_qty_value === '' || item.return_product_qty_value === '0' 
                  ? 0 
                  : Number(item.return_product_qty_value);
              });
              
              const return_product_comments = values?.orderProducts?.map((item)=>{
                return item.return_product_comment_value
              })

              const response = await axios.post(`${REST_API_END_POINT}point-of-sale/refund-order`, {
                facility_id,
                user_id,
                order_id,
                currency_code: currencyCode,
                drawer_id: drawerId?.drawer_id,
                refundDetails: values,
                total,
                pinEntered,
                cashTotal,
                orderProduct,
                ids, deal_ids, deal_products, return_product_qtys, return_product_comments
              });
              if (response.data.status === 1) {
                enqueueSnackbar('Refund has been made successfully', { variant: 'success' });
              } else {
                enqueueSnackbar('Failed to add refund', { variant: 'error' });
              }
              setRefresh(!refresh);
              setOpenRefund(false);
            } else {
              enqueueSnackbar('There is no enough money in the drawer', { variant: 'error' });
              setOpenRefund(false);
            }
          }
        }
      } catch (error) {
        enqueueSnackbar('An error occurred while refunding the order', { variant: 'error' });
        console.error('Error completing the order:', error);
        setOpenRefund(false);
      }
    },
  });

  const { values, errors, touched, handleChange, handleSubmit, getFieldProps, setFieldValue, isSubmitting  } = formik;
  
  const handleCommentChange = (e, index) => {
    const { value } = e.target;
    setFieldValue(`orderProducts[${index}].return_product_comment_value`, value);

    // Clear error when the user starts typing
    setCommentErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index]; // Remove error for the specific field
      return newErrors;
    });
  };

  // useEffect(() => {
  //   orderProduct.forEach((row, index) => {
  //     setFieldValue(`orderProducts[${index}].return_product_qty_value`, parseFloat(row.product_qty).toFixed(2));
  //   });
  // }, [orderProduct, setFieldValue]);

    // Calculate discounts and update config for each product
    useEffect(() => {
      if (orderProduct.length > 0) {
        orderProduct.forEach((row) => {
          // Initialize the return amount with 0 quantity
          returnAmount(row.id, 0);
        });
      }
    }, [orderProduct, totalOrderPrice, totalDiscount]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 1 }}>
              <Stack spacing={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h5" sx={{ mb: 2, pl: 1.3 }}>
                      #{invoiceOrderData?.order_number}
                    </Typography>
                    <Stack direction="column" spacing={3}>
                      <Scrollbar>
                        <TableContainer
                          sx={{ minWidth: 850, whiteSpace: 'nowrap' }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell
                                  sx={{
                                    wordWrap: 'break-word',
                                    width: 200,
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  Product
                                </TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell
                                  sx={{
                                    wordWrap: 'break-word',
                                    width: 150,
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  Returned Quantity
                                </TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Return Quantity</TableCell>
                                <TableCell>Total Amount</TableCell>
                                <TableCell
                                  sx={{
                                    wordWrap: 'break-word',
                                    width: 200,
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  Comment
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {orderProduct.map((row, index) => {
                                const quantity = parseFloat(
                                  (
                                    parseFloat(row['product_qty']) -
                                    parseFloat(row['return_product_qty'])
                                  ).toFixed(2),
                                )
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell
                                      sx={{
                                        wordWrap: 'break-word',
                                        width: 200,
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      {row.product_name}{' '}
                                      {row.variation_name != null
                                        ? `(${row.variation_name})`
                                        : ''}
                                    </TableCell>
                                    <TableCell>
                                      {parseFloat(row.product_qty)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        wordWrap: 'break-word',
                                        width: 150,
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      {parseFloat(
                                        row.return_product_qty,
                                      )?.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                      {parseFloat(row.product_price)?.toFixed(
                                        2,
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {/* <TextField
                                        name={`orderProducts[${index}].return_product_qty_value`}
                                        variant="outlined"                                      
                                        label={`Return Qty`}
                                        type="number"
                                        sx={{minWidth:100}}
                                        value={values.orderProducts?.[index]?.return_product_qty_value || ''}
                                        onChange={(e) => {
                                          handleChange(e);
                                          const updatedQty = Number(e.target.value);
                                        
                                          // Call the returnAmount function to perform your custom logic
                                          const productPrice = parseFloat(row.product_price).toFixed(2) || 0;
                                          const totalPrize = parseFloat(updatedQty).toFixed(2) * productPrice - parseFloat(row.totalOff).toFixed(2);
                                          returnAmount(totalPrize, row.id); // Make sure this function is defined and works as expected
                                        }}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            max: ((row.product_qty || 0) - (row.return_product_qty || 0)),
                                          },
                                        }}
                                        error={
                                          touched.orderProducts?.[index]?.return_product_qty_value &&
                                          !!errors.orderProducts?.[index]?.return_product_qty_value
                                        }
                                        helperText={
                                          touched.orderProducts?.[index]?.return_product_qty_value &&
                                          errors.orderProducts?.[index]?.return_product_qty_value
                                        }
                                      /> */}
                                      <TextField
                                        name={`orderProducts[${index}].return_product_qty_value`}
                                        variant="outlined"
                                        label="Return Qty"
                                        type="number"
                                        sx={{ minWidth: 100 }}
                                        value={
                                          values.orderProducts?.[index]
                                            ?.return_product_qty_value || ""
                                        }
                                        onChange={(e) => {
                                          handleChange(e)
                                          const updatedQty = e.target.value

                                          // Call returnAmount with the quantity and product price to calculate refund
                                          returnAmount(row.id, updatedQty)
                                        }}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            max:
                                              (row.product_qty || 0) -
                                              (row.return_product_qty || 0),
                                          },
                                        }}
                                        error={
                                          touched.orderProducts?.[index]
                                            ?.return_product_qty_value &&
                                          !!errors.orderProducts?.[index]
                                            ?.return_product_qty_value
                                        }
                                        helperText={
                                          touched.orderProducts?.[index]
                                            ?.return_product_qty_value &&
                                          errors.orderProducts?.[index]
                                            ?.return_product_qty_value
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                    {config?.find((r) => r.id === row.id)?.amount || "0.00"}
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        name={`orderProducts[${index}].return_product_comment_value`}
                                        variant="outlined"
                                        label="Comment"
                                        value={
                                          values?.orderProducts[index]
                                            ?.return_product_comment_value || ''
                                        }
                                        onChange={(e) =>
                                          handleCommentChange(e, index)
                                        }
                                        sx={{ width: '80%' }}
                                        // onChange={(e) => {
                                        //   // handleChange(e);
                                        //   let order_products = [...orderProduct];
                                        //   order_products[index].return_product_comment_value = e.target.value;
                                        //   setOrderProducts(order_products);
                                        // }}
                                        error={!!commentErrors[index]}
                                        helperText={commentErrors[index]}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                    </Stack>
                    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                      >
                        <Autocomplete
                          fullWidth
                          options={drawersData}
                          getOptionLabel={(option) => option.drawer_name || ''}
                          value={drawersData?.find(
                            (drawer) =>
                              drawer.drawer_id === drawerId?.drawer_id || null,
                          )}
                          onChange={(event, newValue) => {
                            handleSelect(newValue)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose Drawer"
                              autoComplete="new-password"
                              // error={Boolean( touched.drawer && errors.drawer )}
                              // helperText={touched.drawer && errors.drawer}
                            />
                          )}
                        />
                        <TextField
                          fullWidth
                          label="PIN"
                          type="password"
                          autoComplete="new-password"
                          onChange={handleInputChange}
                          value={pinEntered}
                          error={Boolean(pinValidate && pinValidate)}
                          helperText={pinValidate && 'Please enter correct pin'}
                        />
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        sx={{ mt: 3 }}
                        gap={1}
                      >
                        {/* <TextField
                          fullWidth
                          label="Total"
                          disabled
                          value={parseFloat(total).toFixed(2)}
                          // {...getFieldProps('total')}
                          // error={Boolean( touched.total && errors.total )}
                          // helperText={ touched.total && errors.total}
                        /> */}
                        <TextField
                          fullWidth
                          label="Total"
                          disabled
                          value={parseFloat(config.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0) || 0).toFixed(2)}
                        />

                        {/* <TextField
                          fullWidth
                          label="Crypto Total"
                          disabled
                          value={parseFloat(invoiceOrderData?.cryptoTotal)?.toFixed(2)}
                          // {...getFieldProps('total')}
                          // error={Boolean( touched.total && errors.total )}
                          // helperText={ touched.total && errors.total}
                        />
                        <TextField
                          fullWidth
                          label="Zelle Total"
                          disabled
                          value={parseFloat(invoiceOrderData?.zelleTotal)?.toFixed(2)}
                          // {...getFieldProps('total')}
                          // error={Boolean( touched.total && errors.total )}
                          // helperText={ touched.total && errors.total}
                        />
                        <TextField
                          fullWidth
                          label="Cash Total"
                          disabled
                          value={parseFloat(invoiceOrderData?.cash)?.toFixed(2)}
                          // {...getFieldProps('total')}
                          // error={Boolean( touched.total && errors.total )}
                          // helperText={ touched.total && errors.total}
                        /> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Box sx={{ flexGrow: 1 }} />
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    type="button"
                    variant="outlined"
                    color="inherit"
                    onClick={() => setOpenRefund(false)}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
