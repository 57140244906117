export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:5000/webservice/";
// export const REST_API = "http://localhost:5000/";
// export const BASE_URL = "http://localhost:3000/"; 

// export const REST_API_END_POINT = "http://192.168.1.16:5000/webservice/";
// export const REST_API = "http://192.168.1.16:5000/";
// export const BASE_URL = "http://192.168.1.16:3000/";

// export const REST_API_END_POINT = "http://batman-api.skyniche.website/webservice/";
// export const REST_API = "http://batman-api.skyniche.website/"; 
// export const BASE_URL = "http://batman.skyniche.website/";

export const REST_API_END_POINT = "https://bagempirela.com/backend/webservice/";
export const REST_API = "https://bagempirela.com/backend/";
export const BASE_URL = "https://bagempirela.com/";

export const LBS_CONSTANT= 453.59237;
export const Build = '2.09';
export const Features = [
  //  { id: 1, label: `Total Cost Split-up in Profit & Loss Report (Total Unit Cost, Total Metrc Fund, Total General Fund)` },
  //  { id: 1, label: `Left & Right slider in reports table view` },
  //  { id: 1, label: `Removed Maximum, Minimum, Average Qty from sales reports` },
  //  { id: 1, label: `Removed unnecessary reports` },
]
export const BugFixes = [
  { id: 1, label: `Shop product default quantity set to zero` },
  { id: 2, label: `Show available quantity shop product card` },
  { id: 3, label: `The price and available quantity on the shop and home pages are now only visible to logged-in users.` },
  { id: 4, label: `Fixed product price and available quantity bug in product detail page` },
  // { id: 1, label: `Emoji removed in pdf of leaf management` },
  // { id: 1, label: `Pricing group active and inactive status` },
  // { id: 1, label: `Report Bug fixes` },
  // { id: 1, label: `Multidelete (Strain, Completed orders) Bug fixes` },
  // { id: 1, label: `Add product category notifiction` },
  // { id: 1, label: `Leaf Management` },
  // { id: 1, label: `Shop Bug fixes` },
  // { id: 1, label: `Add & Edit Inventory Bug fixes` },
  // { id: 1, label: `My order Refund Status Bug fixes` },
]

export const OUNCE_CONSTANT = 28;
export const FACILITY_ID = 7;
export const INVENTORY_SOURCES = { '0': 'Inventory', '1': 'Grow House', '2': 'Vendor', '3': 'Extractor', '4': 'Rotovap', '5': 'Distillator', '6': 'Kitchen', '7': 'Oven', '8': 'MCP' };


